import { useCallback, useEffect, useRef, useState } from 'react';
import { useComboboxContext } from '../context';

export const useComboboxValues = (defaultValues) => {
  const { isMultiple } = useComboboxContext();

  const [values, setValues] = useState([]);
  const valuesMap = useRef({});

  useEffect(() => {
    if (!defaultValues) return;

    const isArray = Array.isArray(defaultValues);

    const vals = isArray ? defaultValues : [defaultValues];

    setValues(vals);
    valuesMap.current = vals.reduce((acc, it) => {
      acc[it.value] = true;
      return acc;
    }, {});
  }, [defaultValues]);

  const hasValue = useCallback((value) => valuesMap.current[value] !== undefined, []);

  const handleValueChange = useCallback(
    (value, callback) => {
      const isSelected = hasValue(value.value);

      if (isMultiple && isSelected) {
        const newValues = values.filter((it) => it.value !== value.value)
        setValues(newValues);
        delete valuesMap.current[value.value];
        callback?.(newValues);
        return;
      }

      if (isMultiple) {
        const newValues = [...values, value]
        setValues(newValues);
        valuesMap.current[value.value] = true;
        callback?.(newValues);
        return;
      }

      valuesMap.current = {};

      if (isSelected) {
        setValues([]);
        callback?.([]);
        return;
      }

      setValues([value]);
      valuesMap.current[value.value] = true;
      callback?.([value]);
    },
    [hasValue, isMultiple, values],
  );

  return {
    isEmpty: values.length === 0,
    values,
    handleValueChange,
    hasValue,
  };
};
