export const LineInfo = ({ title, text }) => (
  <div
    className="container"
    style={{
      width: '100%',
      borderBottom: '1px solid #efefef',
      padding: 5,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    }}
  >
    <p className="col-6" style={{ fontWeight: '600' }}>
      {title}
    </p>

    <p className="col-6" style={{ textAlign: 'right' }}>
      {text}
    </p>
  </div>
);
