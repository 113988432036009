import React, { Component } from 'react';
import phoneFormatter from 'phone-formatter';
import Spinner from 'react-bootstrap/Spinner';
import { connect } from 'react-redux';
import dateFormat from 'dateformat';

import ProfileAddressInfo from './ProfileAddressInfo';

import { updateCurrentUser } from '../../../actions/userActions';
import NotificationCard from './NotificationCard';
import { createLoadingSelector, createErrorMessageSelector } from '../../../apis/selectors';

import greenCheckIcon from '../../../images/green-check.png';
import { LoyaltyCardGroup } from './LoyaltyCardGroup';

class ProfileHygienistGeneralInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editProfile: false,
    };
  }

  updateValueInDict(event, key) {
    this.setState({ [key]: event.target.value });
  }

  lineInfo(title, info, key, isNotEditField, showIcon) {
    return (
      <div
        style={{
          width: '100%',
          borderBottom: '1px solid #efefef',
          padding: '5px 10px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <p className="card-text" style={{ fontWeight: '600' }}>
          {title}
        </p>
        {this.state.editProfile && !isNotEditField ? (
          <input
            className="form-controll"
            value={this.state[key]}
            type="text"
            onChange={(e) => this.updateValueInDict(e, key)}
          />
        ) : (
          <p className="card-text">
            {info}
            {showIcon && (
              <img
                style={{ marginLeft: 5, width: 20, height: 20 }}
                src={greenCheckIcon}
                alt={`${title}-check`}
              />
            )}
          </p>
        )}
      </div>
    );
  }

  booleanLineInfo(title, info, key) {
    return (
      <div
        style={{
          width: '100%',
          borderBottom: '1px solid #efefef',
          padding: '5px 10px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <p className="card-text" style={{ fontWeight: '600' }}>
          {title}
        </p>
        {this.state.editProfile ? (
          <select
            className="form-controll"
            value={this.state[key]}
            type="text"
            onChange={(e) => this.updateValueInDict(e, key)}
          >
            <option value>Yes</option>
            <option value={false}> No </option>
          </select>
        ) : (
          <p className="card-text">{info}</p>
        )}
      </div>
    );
  }

  renderProfileInfo() {
    const { user_profile, currentUserStripeId } = this.props;
    if (!user_profile) {
      return <div />;
    }
    return (
      <div>
        {this.lineInfo('First Name', user_profile.first_name, 'first_name')}
        {this.lineInfo('Last Name', user_profile.last_name, 'last_name')}
        {this.lineInfo(
          'Email',
          user_profile.email_id,
          'email_id',
          undefined,
          user_profile?.emailStatus === 'verified',
        )}
        {this.lineInfo(
          'Contact',
          user_profile.contact_number
            ? phoneFormatter.format(user_profile.contact_number, '(NNN) NNN-NNNN')
            : '',
          'contact_number',
        )}
        {this.lineInfo('LinkedIn URL', user_profile.linkedin_url, 'linkedin_url')}
        {this.lineInfo('Current Employer', user_profile.current_employer, 'current_employer')}
        {this.lineInfo(
          'State I.D',
          user_profile?.professional_info?.government_id?.identification,
          'hyg_ident_state_issued_id',
        )}
        {this.lineInfo(
          'Covid T&C',
          user_profile.covid_tc_acceptance
            ? dateFormat(user_profile.covid_tc_acceptance, 'ddd, mmmm dS, yyyy')
            : '-',
          'covid_tc_acceptance',
          true,
        )}
        {this.lineInfo(
          'Last time sign in',
          user_profile.last_time_sign_in
            ? dateFormat(user_profile.last_time_sign_in, 'ddd, mmmm dS, yyyy')
            : '-',
          'last_time_sign_in',
          true,
        )}

        <div
          style={{
            width: '100%',
            borderBottom: '1px solid #efefef',
            padding: '5px 10px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <p className="card-text" style={{ fontWeight: '600' }}>
            Stripe account
          </p>
          <div style={{ display: 'flex', justifyContent: 'flex-end', width: '70%' }}>
            {currentUserStripeId ? (
              <a
                href={`https://dashboard.stripe.com/connect/accounts/${currentUserStripeId}/activity`}
                className="card-text btn btn-success"
                target="_blank"
                rel="noreferrer"
              >
                Open Stripe
              </a>
            ) : (
              <p> - </p>
            )}
          </div>
        </div>
        {this.lineInfo('App version', user_profile.client_version || '-', 'client_version', true)}
        {this.lineInfo('Platform', user_profile.device_type || '-', 'platform', true)}
        {this.booleanLineInfo(
          'Opted in to insurance?',
          user_profile.isInsuranceAvailable ? 'Yes' : 'No',
          'isInsuranceAvailable',
        )}
        {this.booleanLineInfo(
          'Is Allowed To See Office Name?',
          user_profile?.isAllowedToSeeOfficeName ? 'Yes' : 'No',
          'isAllowedToSeeOfficeName',
        )}
      </div>
    );
  }

  enableEdit = () => {
    const {
      first_name,
      last_name,
      email_id,
      contact_number,
      hyg_ident_state_issued_id,
      hyg_ident_state_issued,
      linkedin_url,
      current_employer,
      isInsuranceAvailable,
      isAllowedToSeeOfficeName,
      // address,
    } = this.props.user_profile;

    this.setState({
      editProfile: true,
      first_name,
      last_name,
      email_id,
      contact_number,
      linkedin_url,
      current_employer,
      hyg_ident_state_issued_id,
      hyg_ident_state_issued,
      isInsuranceAvailable,
      isAllowedToSeeOfficeName,
      // address,
    });
  };

  saveEditProfile = () => {
    const {
      first_name,
      last_name,
      email_id,
      contact_number,
      hyg_ident_state_issued_id,
      hyg_ident_state_issued,
      linkedin_url,
      current_employer,
      isInsuranceAvailable,
      isAllowedToSeeOfficeName,
      // address,
    } = this.state;
    this.props.updateCurrentUser({
      first_name,
      last_name,
      email_id,
      contact_number,
      hyg_ident_state_issued_id,
      hyg_ident_state_issued,
      linkedin_url,
      current_employer,
      isInsuranceAvailable,
      isAllowedToSeeOfficeName,
      // address,
    });
    this.setState({ editProfile: false });
  };

  render() {
    const { editProfile } = this.state;
    const { user_profile, isLoading, surroundingUserList, openVerify } = this.props;
    if (!user_profile) {
      return <div />;
    }

    if (isLoading) {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spinner
            animation="border"
            role="status"
            variant="info"
            style={{
              height: 30,
              width: 30,
              marginLeft: 20,
              marginTop: 10,
              alignSelf: 'center',
            }}
          />
        </div>
      );
    }

    return (
      <div
        style={{
          width: '90%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div className="card-deck mt-5 mb-5" style={{ width: '90%' }}>
          <div className="card" style={{ width: '45vw' }}>
            <div className="card-body" style={{ padding: '20px 50px' }}>
              <h3 className="card-title" style={{ textAlign: 'center' }}>
                General Info
              </h3>

              {this.renderProfileInfo()}

              <div
                style={{
                  width: '100%',
                  padding: '10px 0px',
                  display: 'flex',
                  justifyContent: 'space-evenly',
                }}
              >
                {editProfile ? null : (
                  <button
                    className="btn btn-info"
                    style={{ width: '100px' }}
                    onClick={() => this.enableEdit()}
                    type="button"
                  >
                    Edit
                  </button>
                )}
                {editProfile ? (
                  <button
                    className="btn btn-success"
                    style={{
                      width: '100px',
                    }}
                    onClick={() => this.saveEditProfile()}
                    type="button"
                  >
                    Save
                  </button>
                ) : null}
                {editProfile ? (
                  <button
                    className="btn btn-secondary"
                    style={{
                      width: '100px',
                    }}
                    onClick={() => this.setState({ editProfile: false })}
                    type="button"
                  >
                    Cancel
                  </button>
                ) : null}
              </div>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-evenly',
                }}
              >
                <div
                  style={{
                    flexDirection: 'row',
                    display: 'flex',
                  }}
                >
                  <button
                    className="btn btn-success mr-2"
                    style={{
                      width: '110px',
                      height: '34px',
                    }}
                    onClick={() => openVerify('email')}
                  >
                    Verify by email
                  </button>
                  <button
                    className="btn btn-success"
                    style={{
                      width: '110px',
                      margin: '0',
                    }}
                    onClick={() => openVerify('phone')}
                  >
                    Send SMS
                  </button>
                </div>
              </div>

              <ProfileAddressInfo />
            </div>
          </div>

          <div style={{
            width: '40%',
            gap: 50,
            display: 'flex',
            flexDirection: 'column'
          }}>
            <div className="card" style={{ width: '100%', flex: 0 }}>
              <NotificationCard />
            </div>

            <div className="card" style={{ width: '100%', flex: 0 }}>
              <LoyaltyCardGroup />
            </div>
          </div>
        </div>

        <div style={{ height: '100px' }} />
      </div>
    );
  }
}

const actions = [];
const loadingSelector = createLoadingSelector(actions);
const errorSelector = createErrorMessageSelector(actions);

const mapStateToProps = (state) => ({
  isLoading: loadingSelector(state),
  error: errorSelector(state),
  user_profile: state.users.currentUser,
  currentUserStripeId: state.users.currentUserStripeId,
  surroundingUserList: state.users.surroundingUserList,
});

export default connect(mapStateToProps, {
  updateCurrentUser,
})(ProfileHygienistGeneralInfo);
