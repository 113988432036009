import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUsersJobs } from '../../../actions/userActions';
import { createLoadingSelector } from '../../../apis/selectors';
import { Table } from '../Table/Table';
import { jobListConditionalRowStyles, userJobListColumns } from '../jobs-column';
import { CustomHeader } from './CustomHeader';
import { TabViewContainer } from '../TabViewContainer';

const ROWS_PER_PAGE = 15;

const actions = ['FETCH_USER_JOBS', 'BULK_CANCEL_JOBS'];
const loadingSelector = createLoadingSelector(actions);

export const UserJobList = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => loadingSelector(state));
  const jobs = useSelector((state) => state.users.userJobs);
  const totalFiltered = useSelector((state) => state.users.totalJobsCount);
  const searchParams = useSelector((state) => state.users.searchParams);

  const [filter, setFilter] = useState({
    profession: searchParams?.profession || '',
    jobStatus: searchParams?.jobStatus || 'all',
    page: searchParams?.page || 1,
    sortField: searchParams?.sortField || 'job_datetime',
    order: searchParams?.order || 'desc',
    specialty: searchParams?.specialty || '',
  });

  const totalPages = Math.ceil(totalFiltered / ROWS_PER_PAGE);

  const [selectedJobs, setSelectedJobs] = useState([]);
  const [resetPage, setResetPage] = useState(false);

  const user = useSelector((state) => state.users.currentUser);

  const handleFilterChange = (type, value) => {
    setSelectedJobs([]);
    setFilter((prevState) => ({ ...prevState, page: 1, [type]: value }));
    setResetPage(!resetPage);
  };

  const handleOnSort = (selectedColumn, sortDirection) => {
    const sortObj = {
      page: filter.page,
      sortField: selectedColumn.sortField,
      sortOrder: sortDirection,
    };

    setFilter((prevState) => ({
      ...prevState,
      ...sortObj,
    }));
  };

  const handleTableChange = (page) => {
    setFilter((prevState) => ({ ...prevState, page }));
  };

  useEffect(() => {
    dispatch(
      getUsersJobs({
        ...filter,
      }),
    );
  }, [filter, dispatch, user?.id]);

  const getJobUrl = (job) => `/job/${job.id}`;

  const getUserUrl = (userId, userType) => `/user/${userType.toLowerCase()}/${userId}`;

  const rowDisabledCriteria = (row) => !['open', 'pending', 'counter'].includes(row.status);

  const getColumn = () =>
    userJobListColumns({
      getUserUrl,
      getJobUrl,
    });

  return (
    <TabViewContainer
      style={{
        alignItems: 'center',
        width: '90%',
      }}
      title="Temp Requests"
      variant="card"
    >
      <CustomHeader
        filter={filter}
        handleFilterChange={handleFilterChange}
        selectedJobs={selectedJobs}
        setSelectedJobs={setSelectedJobs}
      />
      <Table
        keyField="userJobList.id"
        columns={getColumn()}
        data={jobs}
        initialPage={filter.page}
        noDataComponent="There are no jobs"
        isLoading={isLoading}
        totalPages={totalPages}
        selectableRows
        onSelectedRowsChange={({ selectedRows }) => {
          setSelectedJobs(selectedRows.map((row) => row.id));
        }}
        selectableRowDisabled={rowDisabledCriteria}
        onSort={handleOnSort}
        paginationPerPage={ROWS_PER_PAGE}
        paginationTotalRows={!isLoading ? totalFiltered : undefined}
        onPageChange={handleTableChange}
        resetPage={resetPage}
        forceLoading
        conditionalRowStyles={jobListConditionalRowStyles}
      />
    </TabViewContainer>
  );
};
