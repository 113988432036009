import Spinner from 'react-bootstrap/Spinner';

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import JobDetailsBox from './JobDetailBox';
import UserList from './UserList';
import history from '../../../../history';

import {
  fetchPPJob,
  editPPJob,
  assignOfficePPJob,
  getAllPPApplications,
} from '../../../../actions/ppJobsAction';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../../../../themes/verification.css';
import { createLoadingSelector, createErrorMessageSelector } from '../../../../apis/selectors';
import EditBox from './EditBox';
import OfficeCard from './OfficeCard';
import ApplicationList from './ApplicationList';
import { fetchCurrentUser } from '../../../../actions/userActions';

export default ({ match }) => {
  const actions = ['FETCH_PP_JOB', 'EDIT_PP_JOB', 'ASSIGN_OFFICE_PP_JOB'];
  const loadingSelector = createLoadingSelector(actions);
  const errorSelector = createErrorMessageSelector(actions);

  const isLoading = useSelector((state) => loadingSelector(state));

  const currentJob = useSelector((state) => state.pp.curentJob);

  const [editJob, setEditJob] = useState(false);

  const dispatch = useDispatch();

  const jobId = history.location.state ? history.location.state.job_id : match.params.id;

  useEffect(() => {
    dispatch(fetchPPJob(jobId));
    dispatch(getAllPPApplications(jobId));
  }, []);

  useEffect(() => {
    if (currentJob?.office?.id) {
      dispatch(fetchCurrentUser(currentJob?.office?.id));
    }
  }, [currentJob]);

  const assignOffice = (officeInfo) => {
    dispatch(assignOfficePPJob({ jobId, officeId: officeInfo._id }));
  };

  const redirectToProfile = (id) =>
    history.push(`/user/${'hygienist'}/${id}`, {
      user_id: id,
      profile: 'HYGIENIST',
    });

  const buildPhoneAndNameList = (users) => {
    let result = '';
    users.forEach((user) => {
      if (user.contact_number) {
        result = result.concat(`${user.contact_number}, ${user.first_name} ${user.last_name}\n`);
      }
    });
    return result;
  };

  const redirectToSms = (users) => () =>
    history.push('/sms', {
      PhonesAndNamesParam: buildPhoneAndNameList(users),
    });

  // edit job
  const onEditJobDetail = (val) => {
    dispatch(editPPJob(jobId, val));
  };

  const renderJobDetail = () => {
    if (editJob) {
      return (
        <EditBox
          jobDetail={currentJob}
          onSave={onEditJobDetail}
          onCancel={(_) => setEditJob(false)}
        />
      );
    }

    return <JobDetailsBox job_detail={currentJob} onEdit={(_) => setEditJob(true)} />;
  };

  if (isLoading || !currentJob) {
    return (
      <div
        style={{
          display: 'flex',
          marginTop: 30,
          justifyContent: 'center',
        }}
      >
        <Spinner
          animation="border"
          role="status"
          variant="info"
          style={{
            height: 30,
            width: 30,
            marginLeft: 20,
            marginTop: 10,
          }}
        />
      </div>
    );
  }
  return (
    <div
      className="card border-0"
      style={{
        width: '100vw',
        height: '100vh',
        overflowY: 'auto',
        flexGrow: 1,
        minWidth: 750,
        minHeight: 750,
        marginTop: 20,
      }}
    >
      <div
        style={{
          width: '100vw',
          display: 'flex',
          justifyContent: 'center',
          marginTop: 10,
        }}
      >
        <OfficeCard office={currentJob.office} assignOffice={(office) => assignOffice(office)} />
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {renderJobDetail()}
      </div>
      <ApplicationList />
      <div
        style={{
          display: 'flex',
          width: '100vw',
          justifyContent: 'space-evenly',
          alignItems: 'flex-start',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100vw',
            justifyContent: 'space-evenly',
            alignItems: 'center',
          }}
        >
          <UserList
            currentJob={currentJob}
            title="Applied"
            array={currentJob.moreDetails}
            redirectToProfile={(id) => redirectToProfile(id)}
            redirectToSms={redirectToSms(currentJob.moreDetails)}
          />
          <UserList
            currentJob={currentJob}
            title="Interested"
            array={currentJob.interested}
            redirectToProfile={(id) => redirectToProfile(id)}
            redirectToSms={redirectToSms(currentJob.interested)}
          />
          <UserList
            array={currentJob.declined}
            title="Declined"
            redirectToProfile={(id) => redirectToProfile(id)}
            redirectToSms={redirectToSms(currentJob.declined)}
            currentJob={currentJob}
          />
          <UserList
            array={currentJob.ignored}
            title="Ignored"
            redirectToProfile={(id) => redirectToProfile(id)}
            redirectToSms={redirectToSms(currentJob.ignored)}
            currentJob={currentJob}
          />
          <div style={{ height: 150 }} />
        </div>
      </div>
    </div>
  );
};
