import { combineReducers } from 'redux';
import userMapReducer from './userMapReducer';
import userReducer from './userReducer';
import googleReducer from './googleReducer';
import jobsReducer from './jobsReducer';
import cmsReducer from './cmsReducer';
import loadingReducer from './loadingReducer';
import errorReducer from './errorReducer';
import messageReducer from './messageReducer';
import authReducer from './authReducer';
import paymentsReducer from './paymentsReducer';
import smsReducer from './smsReducer';
import licensesReducer from './licensesReducer';
import statsReducer from './statsReducer';
import ppReducer from './ppReducer';
import conversationsReducer from './conversationsReducer';
import multidayReducer from './multidayReducer';
import referralReducer from './referralReducer';
import metricsReducer from './metricsReducer';
import dsosReducer from './dsosReducer';
import staffReducer from './staffReducer';

export default combineReducers({
  userMap: userMapReducer,
  users: userReducer,
  google: googleReducer,
  jobs: jobsReducer,
  cms: cmsReducer,
  loading: loadingReducer,
  message: messageReducer,
  error: errorReducer,
  auth: authReducer,
  payments: paymentsReducer,
  sms: smsReducer,
  license: licensesReducer,
  stats: statsReducer,
  pp: ppReducer,
  conversations: conversationsReducer,
  multiday: multidayReducer,
  referral: referralReducer,
  metrics: metricsReducer,
  dsos: dsosReducer,
  staff: staffReducer,
});
