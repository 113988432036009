import { Dropdown } from 'react-bootstrap';
import { Colors } from '../../../../themes/colors';
import '../../../../themes/Button.scss';

const tabs = [
  {
    default: true,
    label: 'Account Info',
    value: 'general',
  },
  {
    label: 'Worker Type',
    value: 'workerClassification',
  },
  {
    label: 'Child Offices',
    value: 'childOffices',
  },
  {
    label: 'Payment',
    value: '#',
    linkedItems: [
      {
        label: 'Payment Methods',
        value: 'payment',
      },
      {
        label: 'Invoices',
        value: 'invoices',
      },
      {
        label: 'Receipts',
        value: 'officeReciptsTab',
      },
    ],
  },
  {
    label: 'Jobs',
    value: '#',
    linkedItems: [
      {
        label: 'Temp Requests',
        value: 'jobs',
      },
      {
        label: 'Permanent Hires',
        value: 'ppJobs',
      },
    ],
  },
  {
    label: 'Professionals',
    value: '#',
    linkedItems: [
      {
        label: 'Map',
        value: 'mapTab',
      },
      {
        label: 'Favorites',
        value: 'favorites',
      },
      {
        label: 'Highly-Rated',
        value: 'highlyRated',
      },
      {
        label: 'Blocked',
        value: 'blocked',
      },
    ],
  },
  {
    label: 'Notifications',
    value: 'notifications',
  },
  {
    label: 'Feedback',
    value: '#',
    linkedItems: [
      {
        label: 'Reviews',
        value: 'reviewTab',
      },
      {
        label: 'NPS',
        value: 'nps',
      },
    ],
  },
  {
    label: 'Edits',
    value: 'edits',
  },
  {
    label: 'Notes',
    value: 'notes',
  },
];

export const DNTTabs = ({ value, onValueChanged }) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'row',
      gap: '0.5rem',
      marginBottom: '4px',
      marginTop: '10px',
      position: 'relative',
    }}
  >
    {tabs.map((tab, i) => {
      let isActive = value === tab.value || (!value && tab.default);
      const hasLinked = tab.linkedItems && tab.linkedItems.length > 0;
      const key = `${tab.value}-${i}`;

      if (hasLinked) {
        isActive = tab.linkedItems.some((item) => item.value === value);

        return (
          <Dropdown key={key}>
            <Dropdown.Toggle
              style={{
                alignItems: 'center',
                backgroundColor: isActive ? Colors.secondary_500 : Colors.primary_500,
                border: 'none',
                display: 'flex',
              }}
            >
              {tab.label}
            </Dropdown.Toggle>
            <Dropdown.Menu
              style={{
                border: 'none',
                borderRadius: 0,
                marginTop: 4,
                paddingBlock: 12,
              }}
            >
              {tab.linkedItems.map((linkedItem) => (
                <Dropdown.Item
                  key={linkedItem.value}
                  style={{
                    fontSize: 14,
                  }}
                  onClick={() => onValueChanged(linkedItem.value)}
                >
                  {linkedItem.label}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        );
      }

      return (
        <button
          className="btn btn-info"
          key={key}
          type="button"
          style={{
            backgroundColor: isActive ? Colors.secondary_500 : Colors.primary_500,
            border: 'none',
          }}
          onClick={() => onValueChanged(tab.value)}
        >
          {tab.label}
        </button>
      );
    })}
  </div>
);
