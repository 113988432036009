import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useCallback, useEffect } from 'react';
import { createLoadingSelector } from '../../../../../../../apis/selectors';
import { fetchDsoOffices } from '../../../../../../../actions/dsosActions';
import history from '../../../../../../../history';

export const useOfficeList = () => {
  const dispatch = useDispatch();

  const { id: dsoId } = useParams();
  const isLoading = useSelector(createLoadingSelector(['FETCH_DSO_OFFICE_LIST']));
  const {
    data,
    dsoId: currentDsoId,
    isActiveRequest,
    isFinalPage,
    filters,
  } = useSelector((state) => state.dsos.dsoOfficeList);

  const handleScrollList = useCallback(
    (event) => {
      const tableScrollHeight = event?.target?.scrollHeight - event?.target?.scrollTop - 20;
      const clientHeight = event?.target?.clientHeight;

      if (tableScrollHeight <= clientHeight && !isActiveRequest && !isFinalPage) {
        dispatch(fetchDsoOffices('dso', dsoId, { limit: filters.limit, page: filters.page + 1 }));
      }
    },
    [dispatch, dsoId, filters.limit, filters.page, isActiveRequest, isFinalPage],
  );

  const handleViewDetailClick = useCallback((officeId) => {
    history.push(`/user/dentist/${officeId}`);
  }, []);

  useEffect(() => {
    dispatch(fetchDsoOffices('dso', dsoId));
  }, [dispatch, dsoId]);

  return {
    data: currentDsoId === dsoId ? data : [],
    isInitialLoading: !data.length && isLoading,
    isLoading: isActiveRequest,
    handleScrollList,
    handleViewDetailClick,
  };
};
