import React from 'react';
import Modal from 'react-modal';

export default function ResetPasswordPopup({ state, setState, resetUserPassword, customStyles }) {
  return (
    <Modal
      isOpen={state.showResetPasswordPopup}
      onRequestClose={() => setState({ showResetPasswordPopup: false })}
      style={{ content: { ...customStyles.content, width: '30%' } }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '100%',
        }}
      >
        <div style={{ width: '100%', marginBottom: 30 }}>
          <p>Enter The SMS Code</p>

          <input
            className="form-control mb-1"
            style={{ width: '100%' }}
            value={state.passwordResetCode}
            type="text"
            onChange={(e) => {
              const inputText = e.target.value;

              const numericText = inputText.replace(/\D/g, '').slice(0, 6);
              setState({ passwordResetCode: numericText });
            }}
          />
        </div>

        <div style={{ width: '100%' }}>
          <p>Enter New Password</p>

          <input
            className="form-control mb-1"
            style={{ width: '100%' }}
            value={state.newPassword}
            type="text"
            onChange={(e) => setState({ newPassword: e.target.value })}
          />
        </div>

        <div style={{ display: 'flex', columnGap: 20, margin: '30px 0px' }}>
          <button
            style={{ width: '130px', height: 44 }}
            className="btn btn-danger"
            onClick={() => setState({ showResetPasswordPopup: false })}
          >
            Cancel
          </button>

          <button
            style={{ width: '130px', height: 44 }}
            className="btn btn-info"
            onClick={() => {
              resetUserPassword({
                code: state.passwordResetCode,
                password: state.newPassword,
              });

              setState({ showResetPasswordPopup: false });
            }}
          >
            Confirm
          </button>
        </div>
      </div>
    </Modal>
  );
}
