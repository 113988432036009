import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import CustomPortal from '../../../../commonComponents/CustomPortal';
import { FormControl } from '../FormControl';
import { addNewAdminToDso } from '../../../../../actions/dsosActions';

const initialState = {
  email: '',
  firstName: '',
  lastName: '',
}

export const CreateNewButton = () => {
  const dispatch = useDispatch();
  const { id: dsoId } = useParams();
  const [showModal, setShowModal] = useState(false);

  const [formValues, setFormValues] = useState(initialState);
  const isFormValid = formValues.email && formValues.firstName && formValues.lastName;

  const handleFormValueChanged = (key, value) => {
    setFormValues({
      ...formValues,
      [key]: value,
    });
  };

  const handleResetForm = () => {
    setFormValues(initialState);
  };

  const handleSubmit = () => {
    dispatch(addNewAdminToDso(dsoId, formValues));
    setShowModal(false);
  };

  return (
    <>
      <button className="btn btn-info" onClick={() => setShowModal(true)} type="button">
        Create new Admin
      </button>

      {showModal && (
        <CustomPortal
          title="Create new Admin"
          textStyle={{ textAlign: 'center' }}
          cancelText="Close"
          submitText="Submit"
          submitPressed={handleSubmit}
          cancelPressed={() => {
            setShowModal(false);
            handleResetForm();
          }}
          rightButtonDisabled={!isFormValid}
        >
          <FormControl
            label="First Name"
            value={formValues.firstName}
            onChange={(e) => handleFormValueChanged('firstName', e.target.value)}
            style={{
              width: '100%',
            }}
            labelStyle={{
                width: 100,
            }}
          />
          <FormControl
            label="Last Name"
            value={formValues.lastName}
            onChange={(e) => handleFormValueChanged('lastName', e.target.value)}
            style={{
              width: '100%',
            }}
            labelStyle={{
                width: 100,
            }}
          />
          <FormControl
            label="Email"
            value={formValues.email}
            onChange={(e) => handleFormValueChanged('email', e.target.value)}
            style={{
              width: '100%',
            }}
            labelStyle={{
                width: 100,
            }}
          />
        </CustomPortal>
      )}
    </>
  );
};
