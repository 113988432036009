import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDsoById } from '../../../../actions/dsosActions';
import { createLoadingSelector } from '../../../../apis/selectors';
import history from '../../../../history';
import { getUrlByTab } from '../utils';

const actions = ['FETCH_DSO_BY_ID'];
const loadingSelector = createLoadingSelector(actions);

export const useDsoGroupDetails = (dsoId) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.dsos.dsoGroupDetails);
  const isLoading = useSelector((state) => loadingSelector(state));

  const handleTabSelection = (tab) => {
    history.push(getUrlByTab(dsoId, tab));
  };

  useEffect(() => {
    dispatch(fetchDsoById(dsoId));
  }, [dispatch, dsoId]);

  return {
    data,
    isLoading,
    handleTabSelection,
  };
};
