import { useSelector } from 'react-redux';
import { createLoadingSelector } from '../../../../../../../../../apis/selectors';
import { fetchDsosList } from '../../../../../../../../../actions/dsosActions';

const loadingSelector = createLoadingSelector(['FETCH_DSOS_LIST']);

export const useDsoList = () => {
  const isLoading = useSelector((state) => loadingSelector(state));

  const getDsoList = (page = 1) => fetchDsosList({ page }, true);

  return {
    getDsoList,
    isLoading,
  };
};
