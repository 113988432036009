import tempMee from '../apis/tempMee';
import { MAP_TYPES } from './actionTypes';
import { getAuthHeaders } from './authActions';
import { getShouldUseCredentialServiceProfessionCount } from '../growthbook';

// eslint-disable-line import/prefer-default-export
export const fetchUsersForMap = () => async (dispatch) => {
  try {
    dispatch({ type: MAP_TYPES.FETCH_USER_LIST_MAP_REQUEST });

    const headers = await getAuthHeaders();

    // professionResponse can be undefined since it will be
    // included when `getShouldUseCredentialServiceProfessionCount` is true
    const [response, professionResponse] = await Promise.all([
      tempMee.get('/admin/mapUsers', { headers }),
      ...(getShouldUseCredentialServiceProfessionCount() ? [tempMee.get('/credential-service/users/professions/count', { headers })] : [])
    ]);

    dispatch({
      type: MAP_TYPES.FETCH_USER_LIST_MAP_SUCCESS,
      payload: {
        ...response.data,
        associateDentistCount: professionResponse?.data?.DN ?? response.data.associateDentistCount,
        dentalAssistantCount: professionResponse?.data?.DA ?? response.data.dentalAssistantCount,
        hygienistCount: professionResponse?.data?.RDH ?? response.data.hygienistCount,
      }
    });
  } catch (err) {
    dispatch({ type: MAP_TYPES.FETCH_USER_LIST_MAP_FAILURE, payload: err });
  }
};
