import dateFormat from 'dateformat';
import moment from 'moment';
import React, { Component } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import Countdown from 'react-countdown-now';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import AdjustmentPopup from './AdjustmentPopup';
import AdjustmentList from './Adjustments/AdjustmentList';
import CustomHygienistList from './CustomHygienistList';
import EditTab from './Edits/EditTab';
import JobDetailsEditBox from './Edits/JobDetailsEditBox';
import JobDetailsBox from './JobDetailsBox';
import JobDetailsPopups from './JobDetailsPopups';
import JobDetailsRefundPopup from './JobDetailsRefundPopup';
import JobDetailsUserBox from './JobDetailsUserBox';
import JobFindUserPlaceholder from './JobFindUserPlaceholder';

import {
  acceptJob,
  addShiftNote,
  adjustmentChange,
  cancelJob,
  confirmJob,
  declineCounterOffer,
  disablePayout,
  editJob,
  enableAutoRefund,
  fetchJob,
  fetchJobNotes,
  fetchJobViews,
  fetchJobViewsFromJobViewService,
  payoutJob,
  refundJob,
  removeJobUnavailableUsers,
  sendPushForUsersInRadius,
  stopAdditionalCharges,
  stopInvoices,
  submitAdjustment,
} from '../../../actions/jobsAction';
import { getUserEducation } from '../../../actions/licensesAction';

import 'bootstrap/dist/css/bootstrap.min.css';
import {
  deleteInstantTransaction,
  deleteInvoice,
  instantPayout,
  markInvoiceAsPaid,
  markJobAsPaidOut,
  waivePendingInstantPayout,
} from '../../../actions/paymnetsAction';
import { createErrorMessageSelector, createLoadingSelector } from '../../../apis/selectors';
import { jobDetailScreenTab } from '../../../constants';
import { ExpressPayStatus } from '../../../enums/ExpressPay';
import { UserType } from '../../../enums/UserType';
import '../../../themes/verification.css';
import CustomPortal from '../../commonComponents/CustomPortal';
import TabNavigator from '../../commonComponents/Tabs/TabNavigator';
import TabScreen from '../../commonComponents/Tabs/TabScreen';
import TransactionListPaymentService from '../TransactionListPaymentService';
import NotesTab from '../User/NotesCard';
import ClockInOutDetails from './ClockInOut/ClockInOutDetails';
import RatingDetails from './Rating/RatingDetails';

class JobDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pushTemplate: '',
      radiusForPush: '',
      showAuthorize: true,
      job_editing: false,

      maxRadiusValue: '',
      minRadiusValue: '',
      selectedCounter: {},
      cancelBy: 'dnt',

      notesTab: false,
      generalTab: true,

      transactionComponents: [],
      selectedTransaction: {},

      showRemoveUnavailablePopup: false,
      unavailableId: null,

      modalIsOpen: false,
      isVisibleModal: false,
      cancelJobShowPopup: false,
      deleteHYGShowPopup: false,
      showRefundPopUp: false,
      confirmHYGShowPopup: false,
      showConfirmInvoicePopup: false,
      showInstantPayoutPopup: false,
      showMarkAsPaidOutPopup: false,
      showWaiveFeePopup: false,
      showDeteleTransactionPopup: false,
      showDeleteInvoicePopup: false,
      showAdjustmentPopup: false,
    };
  }

  visibleModal = () => {
    this.setState({ isVisibleModal: !this.state.isVisibleModal });
  };

  changeTab(tab) {
    this.setState({
      generalTab: tab === 'general',
      notesTab: tab === 'notes',
    });

    const jobId = this?.props?.history?.location?.state?.job_id || this?.props?.match?.params?.id;

    if (tab === 'general') {
      const {
        fetchJob,
        fetchJobViews,
        fetchJobViewsFromJobViewService,
      } = this.props;

      fetchJob(jobId);
      fetchJobViews(jobId);
      fetchJobViewsFromJobViewService(jobId);
    }

    if (tab === 'notes') {
      const { fetchJobNotes } = this.props;

      fetchJobNotes(jobId);
    }
  }

  tabSelection() {
    if (this.state.generalTab) {
      return this.generalTab();
    }

    if (this.state.notesTab) {
      return this.noteTab();
    }
  }

  validateTab() {
    const { match, history } = this.props;
    const { currentTab } = match.params;

    if (currentTab && !Object.values(jobDetailScreenTab).includes(currentTab)) {
      history.replace(`/job/${match.params.id}`);
    }
  }

  componentDidMount() {
    const {
      match,
      history,
      fetchJob,
      fetchJobViews,
      fetchJobViewsFromJobViewService,
    } = this.props;

    this.validateTab();

    const jobId = history.location.state ? history.location.state.job_id : match.params.id;

    fetchJob(jobId);
    fetchJobViews(jobId);
    fetchJobViewsFromJobViewService(jobId);
  }

  componentDidUpdate(prevProps, prevState) {
    this.validateTab();

    if (prevProps.currentJob?.id !== this.props.currentJob?.id) {
      this.props.getUserEducation({
        userType: UserType.HYG,
        profession: this.props.currentJob?.profession,
      });
    }
  }

  declineCounterOffer = () => {
    const { selectedCounter } = this.state;
    const { currentJob, declineCounterOffer } = this.props;

    declineCounterOffer(currentJob.id, selectedCounter?.id);

    this.setState({
      deleteHYGShowPopup: false,
      selectedCounter: {},
    });
  };

  confirmCounterOffer = () => {
    const { selectedCounter } = this.state;
    const { currentJob, confirmJob } = this.props;

    confirmJob(currentJob.id, selectedCounter?.id);

    this.setState({
      confirmHYGShowPopup: false,
      selectedCounter: {},
    });
  };

  manuallyConfirmJob() {
    const { currentJob } = this.props;

    const profileType = currentJob.is_cancelled_by_user_type === 'DNT' ? 'dentist' : 'hygienist';

    return (
      <div
        className="card"
        style={{
          width: '53%',
          borderRight: '1px solid #efefef',
          borderLeft: '1px solid #efefef',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <h3 className="card-title">Job Status</h3>
        <h4 className="card-text" style={{ textTransform: 'capitalize' }}>
          {currentJob?.is_cancelled ? 'Cancelled' : currentJob.status}
        </h4>

        <div style={{ height: '20px' }} />

        {currentJob?.is_cancelled && (
          <>
            <div
              style={{
                width: '100%',
                borderBottom: '1px solid #efefef',
                padding: '5px 10px',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <p className="card-text" style={{ fontWeight: '600' }}>
                Cancelled
              </p>
              <p className="card-text">
                {currentJob?.is_cancelled
                  ? dateFormat(
                    currentJob?.job_cancelled_datetime,
                    'dddd, mmmm dS, yyyy, h:MM:ss TT',
                  )
                  : 'No' || ''}
              </p>
            </div>

            <div
              style={{
                width: '100%',
                borderBottom: '1px solid #efefef',
                padding: '5px 10px',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <p className="card-text" style={{ fontWeight: '600' }}>
                Cancelled by
              </p>
              <p className="card-text">
                {`${currentJob?.cancelled_by?.first_name || ''} ${
                  currentJob?.cancelled_by?.last_name || ''
                }`}
                {currentJob?.is_cancelled_by_user_type === 'DNT' ? ' (Office)' : ' (Professional)'}
              </p>
            </div>
            {currentJob?.job_filled_datetime && currentJob?.job_cancelled_datetime && (
              <div
                style={{
                  width: '100%',
                  borderBottom: '1px solid #efefef',
                  padding: '5px 10px',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <p className="card-text" style={{ fontWeight: '600' }}>
                  Grace Period
                </p>
                <p className="card-text">
                  {moment(currentJob?.job_cancelled_datetime).diff(
                    moment(currentJob?.job_filled_datetime),
                    'minutes',
                  ) > 20
                    ? 'No'
                    : 'Yes'}
                </p>
              </div>
            )}
            <div
              style={{
                width: '100%',
                borderBottom: '1px solid #efefef',
                padding: '5px 10px',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <p className="card-text" style={{ fontWeight: '600' }}>
                Hours to the shift
              </p>
              <p className="card-text">
                {moment(currentJob?.job_datetime).diff(
                  moment(currentJob?.job_cancelled_datetime),
                  'hours',
                )}
              </p>
            </div>

            <div
              style={{
                width: '100%',
                borderBottom: '1px solid #efefef',
                padding: '5px 10px',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <p className="card-text" style={{ fontWeight: '600' }}>
                Cancellation Reason
              </p>
              <p className="card-text">{currentJob?.cancellationReason || '-'}</p>
            </div>

            <div
              style={{
                width: '100%',
                borderBottom: '1px solid #efefef',
                padding: '5px 10px',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <p className="card-text" style={{ fontWeight: '600' }}>
                Cancel Performed By
              </p>
              <Link
                className="card-text"
                to={{
                  pathname: `/user/${profileType}/${currentJob?.cancelPerformedBy?.id}`,
                }}
              >
                {currentJob?.cancelPerformedBy
                  ? `${currentJob?.cancelPerformedBy?.first_name || '-'} ${
                    currentJob?.cancelPerformedBy?.last_name || '-'
                  }`
                  : '-'}
              </Link>
            </div>

            <div
              style={{
                width: '100%',
                borderBottom: '1px solid #efefef',
                padding: '5px 10px',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <p className="card-text" style={{ fontWeight: '600' }}>
                Cancellation Policy
              </p>
              <p className="card-text">{currentJob?.cancellationPolicy?.title || '-'}</p>
            </div>

            <div
              style={{
                width: '100%',
                borderBottom: '1px solid #efefef',
                padding: '5px 10px',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <p className="card-text" style={{ fontWeight: '600', width: '40%' }}>
                Description
              </p>
              <p className="card-text" style={{ textAlign: 'right' }}>
                {currentJob?.cancellationPolicy?.description || '-'}
              </p>
            </div>
          </>
        )}
      </div>
    );
  }

  paymentSectionPay() {
    const {
      currentJob,
      disablePayout,
      enableAutoRefund,
      stopAdditionalCharges,
      stopInvoices,
      isLoading,
    } = this.props;

    const openAdjustments =
      currentJob.adjustments.filter((adjustment) => adjustment.status === 'none').length > 0;

    const shouldShowPayoutButton =
      !currentJob.paid_out &&
      !currentJob.disabled_payout &&
      !openAdjustments &&
      currentJob.job_datetime_end < new Date().toISOString();

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-around',
        }}
      >
        <h4 className="card-title">Payout total</h4>
        <p className="card-text" style={{ fontWeight: '700' }}>
          ${currentJob.total?.toFixed(2) || '-'}
        </p>

        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <input
            className="react-switch-checkbox mr-2"
            style={{ margin: 0, cursor: 'pointer' }}
            type="checkbox"
            onChange={() => disablePayout(!currentJob.disabled_payout)}
            checked={!currentJob.disabled_payout}
            disabled={isLoading}
          />
          <button
            type="button"
            style={{ all: 'unset', cursor: 'pointer', marginBottom: 0 }}
            disabled={isLoading}
            onClick={() => disablePayout(!currentJob.disabled_payout)}
          >
            {!currentJob.disabled_payout
              ? 'Payout is enabled for this shift (use to control professional payout for completed shifts)'
              : 'Payout is disabled for this shift (use to control professional payout for completed shifts)'}
          </button>
        </div>

        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <input
            className="react-switch-checkbox mr-2"
            style={{ margin: 0 }}
            type="checkbox"
            onChange={() => enableAutoRefund(!currentJob.isAutoRefundEnabled)}
            checked={currentJob.isAutoRefundEnabled}
          />
          <p
            style={{ cursor: 'pointer', marginBottom: 0 }}
            onClick={() => enableAutoRefund(!currentJob.isAutoRefundEnabled)}
          >
            {currentJob.isAutoRefundEnabled
              ? 'Refunds are enabled for this shift (use to control automated office refunds & professional payout for cancelled shifts)'
              : 'Refunds are disabled for this shift (use to control automated office refunds & professional payout for cancelled shifts)'}
          </p>
        </div>

        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <input
            className="react-switch-checkbox mr-2"
            style={{ margin: 0 }}
            type="checkbox"
            onChange={() => stopAdditionalCharges(!currentJob.stopCharges)}
            checked={!currentJob.stopCharges}
          />
          <p
            style={{ cursor: 'pointer', marginBottom: 0 }}
            onClick={() => stopAdditionalCharges(!currentJob.stopCharges)}
          >
            Charges are {`${!currentJob.stopCharges ? 'enabled' : 'disabled'}`} for this shift (use
            to control future charges for this shift)
          </p>
        </div>

        {currentJob?.offer_owner?.is_pre_approved && (
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <input
              className="react-switch-checkbox mr-2"
              style={{ margin: 0 }}
              type="checkbox"
              onChange={() => stopInvoices(!currentJob.stopInvoices)}
              checked={!currentJob.stopInvoices}
            />
            <p
              style={{ cursor: 'pointer', marginBottom: 0 }}
              onClick={() => stopInvoices(!currentJob.stopInvoices)}
            >
              An invoice will{' '}
              {`${
                !currentJob.stopInvoices
                  ? 'be sent for this shift if charges apply'
                  : 'NOT be sent for this shift'
              }`}
            </p>
          </div>
        )}

        {shouldShowPayoutButton ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <button
              className="btn btn-success mr-2"
              style={{ width: 150, marginBottom: '10px' }}
              onClick={() => this.setState({ showAuthorize: true, modalIsOpen: true })}
              type="submit"
            >
              Pay
            </button>

            <button
              onClick={() => this.setState({ showInstantPayoutPopup: true })}
              className="btn btn-info mr-2"
              type="button"
              style={{ width: 200, marginBottom: '10px' }}
            >
              Request Express Pay-out
            </button>

            <button
              className="btn btn-success"
              style={{ width: 180, marginBottom: '10px' }}
              onClick={() => this.setState({ showMarkAsPaidOutPopup: true })}
              type="submit"
            >
              Mark as Paid out
            </button>
          </div>
        ) : (
          <div />
        )}
      </div>
    );
  }

  preApproveSection() {
    const { currentJob } = this.props;
    const chargeDate = moment(currentJob.job_datetime)
      .subtract(28, 'days')
      .format('ddd, MMM DD, YYYY');
    const startDiff = moment(currentJob.job_datetime).diff(moment(), 'days');

    return (
      <div
        className="card"
        style={{
          width: '50%',
          display: 'flex',
          flexDirection: 'row',
          borderRight: '1px solid #efefef',
          borderLeft: '1px solid #efefef',
        }}
      >
        <div
          className="card-body"
          style={{
            display: 'flex',
            flexDirection: 'column',
            margin: '10px 0px',
          }}
        >
          {currentJob.offer_owner.is_pre_approved ? (
            <div>
              <h4>
                <strong className="card-title">Pre Approved dentist</strong>
              </h4>
              <h5 className="card-title">This dentist is member of pre approved group</h5>
            </div>
          ) : (
            <div />
          )}

          {!currentJob.offer_final_reciever?.has_w9 ? (
            <h4 className="card-title" style={{ color: '#f4511e', marginTop: '10px' }}>
              W9 required{' '}
            </h4>
          ) : (
            <div />
          )}

          {currentJob?.multi_day_booking &&
          startDiff > 28 &&
          !currentJob?.offer_owner?.is_pre_approved ? (
            <h4 className="card-title" style={{ marginTop: '10px' }}>
                Professional pay will be billed on {chargeDate}{' '}
              </h4>
            ) : (
              <div />
            )}

          {this.paymentSectionPay()}
        </div>

        {currentJob?.expressPay ? (
          <div
            style={{
              width: '30%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'center',
            }}
          >
            <h4 className="card-title" style={{ marginTop: '10px' }}>
              Express Payout is <b>{ExpressPayStatus[currentJob?.expressPay]}</b>
            </h4>
            <div style={{ display: 'flex', marginTop: 20 }}>
              <button
                onClick={() => this.setState({ showWaiveFeePopup: true })}
                type="button"
                className="btn btn-info mr-1 ml-1"
              >
                Waive Express Pay
              </button>

              <button
                onClick={() => this.setState({ showDeteleTransactionPopup: true })}
                type="button"
                className="btn btn-danger mr-1 ml-1"
              >
                Delete Express Pay
              </button>
            </div>
          </div>
        ) : (
          <div />
        )}
      </div>
    );
  }

  paymentSection() {
    const { currentJob } = this.props;
    if (!currentJob.offer_owner) {
      return <div />;
    }

    return (
      <div
        style={{
          width: '80%',
        }}
        className="card-deck"
      >
        {this.preApproveSection()}
      </div>
    );
  }

  redirectToProfile = (id) => {
    this.props.history.push(`/user/hygienist/${id}`, {
      user_id: id,
      profile: 'HYGIENIST',
    });
  };

  buildPhoneAndNameList = (users) => {
    let result = '';
    users.forEach((user) => {
      if (user.contact_number) {
        result = result.concat(`${user.contact_number}, ${user.first_name} ${user.last_name}\n`);
      }
    });
    return result;
  };

  redirectToSms = (users) => () => {
    const { history } = this.props;
    history.push('/sms', { PhonesAndNamesParam: this.buildPhoneAndNameList(users) });
  };

  openModal = () => {
    this.setState({ modalIsOpen: true });
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  payoutProcessingContent() {
    if (!this.state.modalIsOpen) {
      return <div />;
    }

    const { showAuthorize } = this.state;
    return (
      <div
        style={{
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {showAuthorize ? this.authorizeSection() : null}
      </div>
    );
  }

  renderInstantPayoutPopup = () => {
    const { showInstantPayoutPopup } = this.state;
    const { currentJob } = this.props;
    return (
      <JobDetailsPopups
        isShow={showInstantPayoutPopup}
        title={`Are you sure you want to payout
        ${
          currentJob.offer_final_reciever
            ? ` ${currentJob?.offer_final_reciever?.first_name} ${currentJob?.offer_final_reciever?.first_name}`
            : 'invalid'
        }?`}
        description=""
        leftButtonTitle="Cancel"
        rightButtonTitle="Confirm"
        leftButtonAction={() => this.setState({ showInstantPayoutPopup: false })}
        rightButtonAction={() => {
          this.setState({ showInstantPayoutPopup: false });
          this.props.instantPayout();
        }}
      />
    );
  };

  renderMarkAsPaidOutPopup = () => {
    const { showMarkAsPaidOutPopup } = this.state;
    const { currentJob } = this.props;
    return (
      <JobDetailsPopups
        isShow={showMarkAsPaidOutPopup}
        title={`Are you sure you want to mark
        ${
          currentJob.offer_final_reciever
            ? ` ${currentJob?.offer_final_reciever?.first_name} ${currentJob?.offer_final_reciever?.last_name}`
            : 'invalid'
        } as paid out?`}
        description=""
        leftButtonTitle="Cancel"
        rightButtonTitle="Confirm"
        leftButtonAction={() => this.setState({ showMarkAsPaidOutPopup: false })}
        rightButtonAction={() => {
          this.setState({ showMarkAsPaidOutPopup: false });
          this.props.markJobAsPaidOut();
        }}
      />
    );
  };

  renderDeleteHYGPopup = () => {
    const { selectedCounter, deleteHYGShowPopup } = this.state;

    return (
      <JobDetailsPopups
        isShow={deleteHYGShowPopup}
        title={`Are you sure you want to delete ${selectedCounter?.requestor?.first_name} ${selectedCounter?.requestor?.last_name} hygienist from offer?`}
        description=""
        leftButtonTitle="Cancel"
        rightButtonTitle="Delete"
        leftButtonAction={() => this.setState({ deleteHYGShowPopup: false })}
        rightButtonAction={() => this.declineCounterOffer()}
      />
    );
  };

  renderConfirmHYGPopup = () => {
    const { selectedCounter, confirmHYGShowPopup } = this.state;
    return (
      <JobDetailsPopups
        isShow={confirmHYGShowPopup}
        title={`Are you sure you want to confirm ${selectedCounter?.requestor?.first_name} ${selectedCounter?.requestor?.last_name} hygienist from offer?`}
        description=""
        leftButtonTitle="Cancel"
        rightButtonTitle="Confirm"
        leftButtonAction={() => this.setState({ confirmHYGShowPopup: false })}
        rightButtonAction={() => this.confirmCounterOffer()}
      />
    );
  };

  handleRefundClick = (refundObject, description) => {
    const { transactionId } = this.state;
    const { currentJob, refundJob } = this.props;
    const refundTransactions = refundObject.map((r) => {
      delete r._id;
      return r;
    });
    refundJob(currentJob.id, refundTransactions, transactionId, description);
    this.setState({ showRefundPopUp: false });
  };

  handleConfirmIncoivePaidClick = () => {
    const { selectedTransaction } = this.state;
    const { markInvoiceAsPaid } = this.props;
    markInvoiceAsPaid(selectedTransaction.transaction_id);
    this.setState({ showConfirmInvoicePopup: false, selectedTransaction: {} });
  };

  renderRefundPopup = () => {
    const { showRefundPopUp, transactionComponents } = this.state;

    return (
      <JobDetailsRefundPopup
        transactionComponents={transactionComponents}
        isShow={showRefundPopUp}
        description=""
        leftButtonAction={() => this.setState({ showRefundPopUp: false })}
        confirmRefund={(refundObject, description) =>
          this.handleRefundClick(refundObject, description)
        }
      />
    );
  };

  renderPaidInvoicePopup = () => {
    const { showConfirmInvoicePopup, selectedTransaction } = this.state;
    return (
      <JobDetailsPopups
        isShow={showConfirmInvoicePopup}
        description=""
        leftButtonAction={() => this.setState({ showConfirmInvoicePopup: false })}
        rightButtonAction={() => this.handleConfirmIncoivePaidClick()}
        title={`Are you sure you want to mark invoice with amount $${
          selectedTransaction?.amount?.toFixed(2) || ''
        } as paid?`}
        leftButtonTitle="Cancel"
        rightButtonTitle="Confirm"
      />
    );
  };

  renderCancelJobPopup = () => {
    const { currentJob } = this.props;
    if (!this.state.cancelJobShowPopup) {
      return <div />;
    }

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <h2>Are you sure you want to cancel this job?</h2>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <h4>Cancelled By: </h4>

          <select
            className="form-contoll"
            style={{ width: '50%', margin: 10 }}
            value={this.state.cancelBy}
            onChange={(e) => this.setState({ cancelBy: e.target.value })}
          >
            <option value="dnt">Dentist Office</option>
            {currentJob.status === 'filled' ||
            currentJob.status === 'complete' ||
            currentJob.status === 'pay-out' ? (
              <option value="hyg">Hygienist</option>
              ) : null}
            {currentJob.status === 'pending' ? (
              <option value="pending">Pending Cancelation</option>
            ) : null}
          </select>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <button
            className="btn btn-secondary"
            style={{ marginRight: '12px', width: 100 }}
            onClick={(_) => this.setState({ cancelJobShowPopup: false })}
            type="button"
          >
            Back
          </button>

          <button
            className="btn btn-danger"
            style={{ marginLeft: '12px', width: 100 }}
            onClick={(_) => this.cancelJob()}
            type="submit"
          >
            Cancel Job
          </button>
        </div>
      </div>
    );
  };

  cancelJob() {
    const { currentJob, cancelJob } = this.props;
    const { cancelBy } = this.state;
    let userId = '';
    let isPendingJob = false;
    if (cancelBy === 'dnt') {
      userId = currentJob.offer_owner.id;
    } else if (cancelBy === 'hyg') {
      userId = currentJob.offer_final_reciever.id;
    } else if (cancelBy === 'pending') {
      userId = currentJob.offer_owner.id;
      isPendingJob = !isPendingJob;
    }
    cancelJob(currentJob.id, userId, isPendingJob);
    this.setState({ cancelJobShowPopup: false });
  }

  authorizeSection() {
    const { currentJob, payoutJob } = this.props;

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        className="card border-0"
      >
        <h3 className="card-title">
          Are you sure you want to payout
          {currentJob.offer_final_reciever
            ? ` ${currentJob?.offer_final_reciever?.first_name} ${currentJob?.offer_final_reciever?.last_name}`
            : 'invalid'}
          ?
        </h3>
        <p className="card-text">
          Payment Total: <strong>${currentJob.total.toFixed(2)}</strong>
        </p>
        <p className="card-text">
          Hours Worked:
          <strong> {currentJob.workingHours?.toFixed(2) || '-'}</strong>
        </p>
        <p className="card-text">
          Hourly Rate:
          <strong>{` $${currentJob.rate.toFixed(2) || 'No rate information.'}`}</strong>
        </p>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <button
            className="btn btn-success"
            style={{ width: 150, margin: '0' }}
            onClick={() => {
              this.setState({ showAuthorize: false, modalIsOpen: false });
              payoutJob(currentJob.id);
            }}
            type="button"
          >
            Payout
          </button>

          <button
            className="btn btn-secondary ml-1"
            style={{ width: 150, margin: '0' }}
            onClick={() => this.setState({ showAuthorize: false, modalIsOpen: false })}
            type="button"
          >
            Cancel
          </button>
        </div>
      </div>
    );
  }

  // edit job
  onEditJobDetail = (val) => {
    const { currentJob, editJob } = this.props;

    editJob(currentJob.id, val);
  };

  renderJobDetail() {
    const { currentJob, specializations, userEducation } = this.props;

    if (this.state.job_editing) {
      return (
        <JobDetailsEditBox
          job_detail={currentJob}
          allSpecializations={specializations}
          userEducation={userEducation}
          onSave={this.onEditJobDetail}
          onCancel={(_) => this.setState({ job_editing: false })}
          theOneWhoPosted={this.theOneWhoPosted}
          isVisibleModal={this.state.isVisibleModal}
          visibleModal={this.visibleModal}
        />
      );
    }

    return (
      <JobDetailsBox
        job_detail={currentJob}
        onEdit={(_) => this.setState({ job_editing: true })}
        onCancel={(_) => this.setState({ cancelJobShowPopup: true })}
        openMessages={() =>
          this.props.history.push(`/messages/${currentJob.id}`, { job: currentJob })
        }
        theOneWhoPosted={this.theOneWhoPosted}
      />
    );
  }

  clickSeacrchInCMS = () => {
    const { currentJob, history } = this.props;

    history.push('/cmsPreview', {
      job: currentJob,
      maxDistance: this.state.maxRadiusValue,
      minDistance: this.state.minRadiusValue,
    });
  };

  clickSeacrchForRadius = () => {
    const { currentJob, history } = this.props;

    history.push('/sms', {
      job_id: currentJob._id,
      maxRadiusValue: this.state.maxRadiusValue,
      minRadiusValue: this.state.minRadiusValue,
    });
  };

  setMaxRadius = (event) => {
    this.setState({
      maxRadiusValue: event.target.value,
      minRadiusValue: this.state.minRadiusValue ? this.state.minRadiusValue : 0,
    });
  };

  renderRadiusView() {
    return (
      <div
        className="card"
        style={{
          width: '45%',
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
          padding: 10,
        }}
      >
        <div className="card-body">
          <h4 className="card-title mt-4"> Enter min radiuses for search:</h4>

          <input
            className="form-control mt-2"
            value={this.state.minRadiusValue}
            onChange={(event) =>
              this.setState({
                minRadiusValue: event.target.value,
              })
            }
            placeholder="Min Radius"
          />
          <h4 className="card-title mt-5"> Enter max radiuses for search:</h4>

          <input
            className="form-control mt-2"
            value={this.state.maxRadiusValue}
            placeholder="Max Radius"
            onChange={(event) => this.setMaxRadius(event)}
          />
          <div
            style={{
              marginTop: 20,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <button
              className="btn btn-info mr-2"
              style={{ width: 100 }}
              onClick={this.clickSeacrchInCMS}
              disabled={this.state.maxRadiusValue === ''}
              type="submit"
            >
              Look in CMS
            </button>
            <button
              className="btn btn-info"
              style={{ width: 100 }}
              onClick={this.clickSeacrchForRadius}
              disabled={this.state.maxRadiusValue === ''}
              type="submit"
            >
              Search
            </button>
          </div>
        </div>
      </div>
    );
  }

  sendPressPushInRadius = () => {
    const body = {
      radius: this.state.radiusForPush,
      text: this.state.pushTemplate,
    };

    this.props.sendPushForUsersInRadius(this.props.currentJob._id, body);

    alert('Push has been sent');

    this.setState({ radiusForPush: 0, pushTemplate: '' });
  };

  renderWaiveFeePopup() {
    const { waivePendingInstantPayout } = this.props;
    const { showWaiveFeePopup } = this.state;

    return (
      <JobDetailsPopups
        isShow={showWaiveFeePopup}
        title="Are you sure you want to waive express payout transaction?"
        description=""
        leftButtonTitle="Cancel"
        rightButtonTitle="Waive"
        leftButtonAction={() => this.setState({ showWaiveFeePopup: false })}
        rightButtonAction={() => {
          this.setState({ showWaiveFeePopup: false });
          waivePendingInstantPayout();
        }}
      />
    );
  }

  renderDeteleTransactionPopup() {
    const { deleteInstantTransaction } = this.props;
    const { showDeteleTransactionPopup } = this.state;

    return (
      <JobDetailsPopups
        isShow={showDeteleTransactionPopup}
        title="Are you sure you want to remove express payout transaction?"
        description=""
        leftButtonTitle="Cancel"
        rightButtonTitle="Delete"
        leftButtonAction={() => this.setState({ showDeteleTransactionPopup: false })}
        rightButtonAction={() => {
          this.setState({ showDeteleTransactionPopup: false });
          deleteInstantTransaction();
        }}
      />
    );
  }

  renderDeleteInvoicePopup() {
    const { deleteInvoice } = this.props;
    const { showDeleteInvoicePopup, selectedTransaction } = this.state;

    return (
      <JobDetailsPopups
        isShow={showDeleteInvoicePopup}
        title="Are you sure you want to remove this invoice?"
        description=""
        leftButtonTitle="Cancel"
        rightButtonTitle="Delete"
        leftButtonAction={() => this.setState({ showDeleteInvoicePopup: false })}
        rightButtonAction={() => {
          this.setState({ showDeleteInvoicePopup: false });
          deleteInvoice(selectedTransaction.transaction_id);
        }}
      />
    );
  }

  renderPushForRadiusView() {
    return (
      <div
        style={{
          width: '45%',
          display: 'flex',
          flexDirection: 'column',
          padding: 10,
        }}
        className="card"
      >
        <h4 className="card-title mt-2" style={{ marginBottom: 10 }}>
          Enter radius:
        </h4>

        <input
          className="form-control"
          value={this.state.radiusForPush}
          placeholder="Enter Radius"
          onChange={(event) => this.setState({ radiusForPush: event.target.value })}
          style={{
            marginBottom: '10px',
            marginTop: '10px',
            width: 150,
          }}
        />
        <h4 className="card-title"> Enter text for push notification:</h4>

        <textarea
          name="Enter Push text"
          style={{ height: '150px', marginTop: '12px' }}
          value={this.state.pushTemplate}
          onChange={(event) => this.setState({ pushTemplate: event.target.value })}
        />

        <button
          className="btn btn-info mt-2 mb-2"
          style={{
            borderRadius: 10,
            width: 150,
            alignSelf: 'center',
          }}
          onClick={() => {
            this.sendPressPushInRadius();
          }}
          disabled={this.state.radiusForPush === '' && this.state.pushTemplate === ''}
          type="submit"
        >
          Send
        </button>
      </div>
    );
  }

  isJobCompleteAndWithin24Hours = () => {
    const { currentJob } = this.props;

    const jobDate = moment(currentJob?.job_datetime_end);
    const currentDateUTC = moment.utc();
    const hoursDifference = currentDateUTC.diff(jobDate, 'hours');

    return currentJob.status === 'pay-out' && hoursDifference >= 0 && hoursDifference <= 24;
  };

  renderAdjusmentPopup = () => {
    const { currentJob } = this.props;
    const { showAdjustmentPopup } = this.state;
    return (
      <AdjustmentPopup
        isShow={showAdjustmentPopup}
        closeButtonAction={() => this.setState({ showAdjustmentPopup: false })}
        confirmButtonAction={(payload) => this.confirmSubmitAdjustment(payload)}
        currentJob={currentJob}
      />
    );
  };

  confirmSubmitAdjustment = (payload) => {
    const { currentJob, submitAdjustment } = this.props;

    let userId = '';
    if (payload?.submitBy === 'Professional') {
      userId = currentJob.offer_final_reciever.id;
    } else if (payload?.submitBy === 'Office') {
      userId = currentJob.offer_owner.id;
    }

    const content = {
      userId,
      localStart: moment(payload.localStart, 'HH:mm').format('hh:mm a'),
      localEnd: moment(payload.localEnd, 'HH:mm').format('hh:mm a'),
      break: String(payload.break),
    };

    submitAdjustment(currentJob.id, content);
    this.setState({
      showAdjustmentPopup: false,
    });
  };

  assignHygienist = (hygienist) => {
    const { acceptJob, currentJob } = this.props;
    this.setState({ searchHygienist: false });

    acceptJob(currentJob.id, { hygienistId: hygienist._id });
  };

  renderHygienistSearch() {
    const { currentJob } = this.props;
    if (currentJob.offer_final_reciever) {
      return (
        <JobDetailsUserBox
          user={currentJob?.offer_final_reciever}
          style={customStyles.userContainer}
          textStyle={customStyles.userContainerText}
        />
      );
    }
    return (
      <JobFindUserPlaceholder
        title="+ Add a Hygienist"
        onSelectUser={(user) => this.assignHygienist(user)}
        user_type="HYG"
        style={customStyles.userContainer}
        textStyle={customStyles.userContainerText}
      />
    );
  }

  handleAdjustmentChange = (adjId, status) => {
    const { adjustmentChange, currentJob } = this.props;
    adjustmentChange(currentJob.id, adjId, status);
  };

  noteTab() {
    const { addShiftNote, currentUser, currentJob } = this.props;

    return (
      <NotesTab
        notes={currentUser?.notes || []}
        userId={currentJob.id}
        submitNote={addShiftNote}
        isForShift
        currentUser={currentUser}
      />
    );
  }

  theOneWhoPosted = (thePoster) => {
    if (!thePoster) return <>N/A</>;
    const profileType = thePoster.user_type === 'DNT' ? 'DENTIST' : 'HYGIENIST';
    let name = thePoster?.full_name;
    if (!thePoster?.full_name?.trim()) {
      name = 'N/A';
      return name;
    }
    return (
      <Link
        className="card-text"
        to={{
          pathname: `/user/${profileType.toLowerCase()}/${thePoster.id}`,
          state: {
            user_id: thePoster.id,
            profile: profileType,
          },
        }}
      >
        <>{name}</>
      </Link>
    );
  };

  generalTab = () => {
    const {
      currentJob,
      history,
      match,
      currentJobViews,
      currentJobViewsFromJobViewService,
    } = this.props;

    const cancelText = currentJob.is_cancelled ? 'Cancelled' : currentJob?.status?.toUpperCase();

    return (
      <div
        className={this.state.isVisibleModal ? 'card border-0 scroll' : 'card border-0'}
        style={{
          width: '100vw',
          flexGrow: 1,
          minWidth: 750,
          marginTop: 20,
          marginBottom: 150,
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <div
            className="card"
            style={{
              width: 200,
              backgroundColor: 'white',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: 50,
              borderWidth: currentJob.is_cancelled || currentJob.job_status !== 'open' ? 0 : 1,
              textTransform:
                currentJob.is_cancelled || currentJob.job_status !== 'open' ? 'uppercase' : 'none',
            }}
          >
            {currentJob.is_cancelled || currentJob.job_status !== 'open' ? (
              <h4
                className="card-title"
                style={{ fontSize: 20, color: currentJob.is_cancelled ? '#f4511e' : '#4FC0CF' }}
              >
                {cancelText}{' '}
              </h4>
            ) : (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  marginBlock: 7,
                }}
              >
                <p
                  className="global_font f-dark"
                  style={{
                    fontSize: 16,
                    fontWeight: 600,
                    margin: 0,
                    textAlign: 'center',
                  }}
                >
                  Offer Expires In
                </p>

                <Countdown
                  zeroPadDays={1}
                  zeroPadTime={2}
                  date={new Date(currentJob.job_datetime)}
                />
              </div>
            )}
          </div>
        </div>
        <div
          style={{
            width: '80%',
            maxWidth: 800,
            display: 'flex',
            alignSelf: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 19,
            gap: 11,
          }}
        >
          <JobDetailsUserBox
            user={currentJob?.offer_owner}
            style={customStyles.userContainer}
            textStyle={customStyles.userContainerText}
          />

          {this.renderHygienistSearch()}
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {this.renderJobDetail()}
          {this.manuallyConfirmJob()}
        </div>
        <div
          style={{
            display: 'flex',
            width: '100vw',
            justifyContent: 'space-evenly',
            alignItems: 'flex-start',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100vw',
              justifyContent: 'space-evenly',
              alignItems: 'center',
              marginTop: 30,
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: '80%',
                margin: 0,
              }}
            >
              {this.renderRadiusView()}
              {this.renderPushForRadiusView()}
            </div>
          </div>
        </div>
        <div style={{ marginTop: 30 }}>
          <TabNavigator
            initialTab={jobDetailScreenTab.ACCEPTED_AND_COUNTERS}
            currentTab={match.params.currentTab || jobDetailScreenTab.ACCEPTED_AND_COUNTERS}
            onTabChange={(tab) => {
              const newTab = tab === jobDetailScreenTab.ACCEPTED_AND_COUNTERS ? '' : tab;
              history.push(`/job/${match.params.id}/${newTab}`);
            }}
          >
            <TabScreen
              name={jobDetailScreenTab.ACCEPTED_AND_COUNTERS}
              options={{
                title: 'Accepted & Counters',
              }}
              component={
                <CustomHygienistList
                  currentJob={currentJob}
                  redirectToProfile={(id) => this.redirectToProfile(id)}
                  declineCounterOffer={(selectedCounter) =>
                    this.setState({
                      selectedCounter,
                      deleteHYGShowPopup: true,
                    })
                  }
                  confirmCounterOffer={(selectedCounter) =>
                    this.setState({
                      selectedCounter,
                      confirmHYGShowPopup: true,
                    })
                  }
                  redirectToSms={this.redirectToSms(
                    currentJob.offer_counters.map((counter) => counter.requestor),
                  )}
                />
              }
            />
            <TabScreen
              name={jobDetailScreenTab.INVITES}
              options={{
                title: 'Invites',
              }}
              component={
                <CustomHygienistList
                  title="Invites"
                  currentJob={currentJob}
                  redirectToProfile={(id) => this.redirectToProfile(id)}
                />
              }
            />
            <TabScreen
              name={jobDetailScreenTab.UNAVAILABLE}
              options={{
                title: 'Unavailable',
              }}
              component={
                <CustomHygienistList
                  array={currentJob.offer_unavailable}
                  title="Unavailable"
                  redirectToProfile={(id) => this.redirectToProfile(id)}
                  currentJob={currentJob}
                  redirectToSms={this.redirectToSms(currentJob.offer_unavailable)}
                  onRemoveClicks={(id) => {
                    this.setState({ showRemoveUnavailablePopup: true, unavailableId: id });
                  }}
                />
              }
            />
            <TabScreen
              name={jobDetailScreenTab.VIEWS}
              options={{
                title: 'Views',
              }}
              component={
                <div
                  className="card mt-5"
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '80%',
                    justifyContent: 'center',
                  }}
                >
                  <div
                    className="card-body"
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <h2
                      className="card-title"
                      style={{ marginLeft: 10, fontFamily: 'Nunito', color: '#494963' }}
                    >
                      Views
                    </h2>
                    <p style={{ fontFamily: 'Nunito', fontSize: 18, alignText: 'center' }}>
                      This job has been viewed by{' '}
                      <strong>{currentJobViewsFromJobViewService}</strong> unique professionals.
                    </p>
                  </div>
                </div>
              }
            />
            <TabScreen
              name={jobDetailScreenTab.CLICKED}
              options={{
                title: 'Clicked',
              }}
              component={
                <CustomHygienistList
                  redirectToProfile={(id) => this.redirectToProfile(id)}
                  array={currentJobViews}
                  title="Clicked"
                  currentJob={currentJob}
                  redirectToSms={this.redirectToSms(currentJobViews)}
                />
              }
            />
            <TabScreen
              name={jobDetailScreenTab.EDITS}
              options={{
                title: 'Edits',
              }}
              component={<EditTab editList={currentJob.edits} />}
            />
            <TabScreen
              name={jobDetailScreenTab.ADJUSTMENTS}
              options={{
                title: 'Adjustments',
              }}
              component={
                <AdjustmentList
                  offerOwnerId={currentJob?.offer_owner?.id}
                  adjustmentList={currentJob.adjustments}
                  btnAdjClicked={(adjId, status) => this.handleAdjustmentChange(adjId, status)}
                  showAdjustmentButton={this.isJobCompleteAndWithin24Hours()}
                  showAdjustmentPopup={() => this.setState({ showAdjustmentPopup: true })}
                />
              }
            />
            <TabScreen
              name={jobDetailScreenTab.TRANSACTIONS}
              options={{
                title: 'Transactions',
              }}
              component={
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <TransactionListPaymentService
                    transactionList={currentJob.transactionsPaymentService}
                    showRefundPopUp={(transactionId, transactionComponents) =>
                      this.setState({ showRefundPopUp: true, transactionId, transactionComponents })
                    }
                  />

                  {this.paymentSection()}
                </div>
              }
            />
            <TabScreen
              name={jobDetailScreenTab.RATING}
              options={{
                title: 'Rating',
              }}
              component={<RatingDetails />}
            />
            <TabScreen
              name={jobDetailScreenTab.CLOCK_IN_OUT}
              options={{
                title: 'Clock In / Clock Out',
              }}
              component={<ClockInOutDetails currentJob={currentJob} />}
            />
          </TabNavigator>
        </div>
      </div>
    );
  };

  render() {
    const { currentJob, isLoading, removeJobUnavailableUsers } = this.props;

    const {
      modalIsOpen,
      cancelJobShowPopup,
      deleteHYGShowPopup,
      showRefundPopUp,
      confirmHYGShowPopup,
      showConfirmInvoicePopup,
      showInstantPayoutPopup,
      showMarkAsPaidOutPopup,
      showWaiveFeePopup,
      showDeteleTransactionPopup,
      showDeleteInvoicePopup,
      showAdjustmentPopup,
      showRemoveUnavailablePopup,
      unavailableId,
    } = this.state;

    if (isLoading || !currentJob) {
      return (
        <div
          style={{
            display: 'flex',
            marginTop: 30,
            justifyContent: 'center',
          }}
        >
          <Spinner
            animation="border"
            role="status"
            variant="info"
            style={{
              height: 30,
              width: 30,
              marginLeft: 20,
              marginTop: 10,
              marginBottom: 10,
            }}
          />
        </div>
      );
    }

    return (
      <div
        className="card border-0"
        style={{
          width: '100vw',
          height: '100vh',
          overflowY: 'auto',
          flexGrow: 1,
          minWidth: 750,
          marginTop: 20,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          <button
            type="button"
            className="btn btn-info mr-1 ml-1"
            style={{
              backgroundColor: this.state.generalTab ? '#4FC0CF' : '#307F92',
              border: 'none',
            }}
            onClick={() => this.changeTab('general')}
          >
            General
          </button>
          <button
            type="button"
            className="btn btn-info mr-1 ml-1"
            style={{ backgroundColor: this.state.notesTab ? '#4FC0CF' : '#307F92', border: 'none' }}
            onClick={() => this.changeTab('notes')}
          >
            Notes
          </button>
        </div>

        {this.tabSelection()}

        <Modal
          isOpen={
            modalIsOpen ||
            cancelJobShowPopup ||
            deleteHYGShowPopup ||
            showRefundPopUp ||
            confirmHYGShowPopup ||
            showConfirmInvoicePopup ||
            showInstantPayoutPopup ||
            showMarkAsPaidOutPopup ||
            showWaiveFeePopup ||
            showDeteleTransactionPopup ||
            showDeleteInvoicePopup ||
            showAdjustmentPopup
          }
          onRequestClose={this.closeModal}
          style={customStyles}
        >
          {this.renderCancelJobPopup()}
          {this.payoutProcessingContent()}
          {this.renderDeleteHYGPopup()}
          {this.renderRefundPopup()}
          {this.renderConfirmHYGPopup()}
          {this.renderPaidInvoicePopup()}
          {this.renderInstantPayoutPopup()}
          {this.renderMarkAsPaidOutPopup()}
          {this.renderWaiveFeePopup()}
          {this.renderDeteleTransactionPopup()}
          {this.renderDeleteInvoicePopup()}
          {this.renderAdjusmentPopup()}
        </Modal>

        {showRemoveUnavailablePopup && (
          <CustomPortal
            title="Are you sure you want to remove this user from unavailable list?"
            submitText="Remove"
            cancelText="Cancel"
            submitPressed={() => {
              this.setState({ showRemoveUnavailablePopup: false, unavailableId: null });
              removeJobUnavailableUsers(currentJob.id, [unavailableId]);
            }}
            cancelPressed={() => {
              this.setState({ showRemoveUnavailablePopup: false, unavailableId: null });
            }}
          />
        )}
      </div>
    );
  }
}

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    minHeight: '200px',
  },
  userContainer: {
    flex: 1,
    margin: 0,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    paddingLeft: 30,
    paddingRight: 10,
    gap: 10,
    height: '180px',
  },
  userContainerText: {
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    textAlign: 'left',
  },
};

const actionArr = [
  'FETCH_JOB',
  'CANCEL_JOB',
  'CONFIRM_JOB',
  'ACCEPT_JOB',
  'PAYOUT_JOB',
  'ADJUSTMENT_CHANGE',
  'REFUND_JOB',
  'DECLINE_COUINTER_OFFER',
  'CREATE_INSTANT_PAYOUT',
  'EDIT_JOB',
  'NCNS_REPOST',
];
const loadingSelector = createLoadingSelector(actionArr);
const errorSelector = createErrorMessageSelector(actionArr);

const mapStateToProps = (state) => ({
  currentUser: state.users.currentUser,
  currentJob: state.jobs.currentJob,
  currentJobViews: state.jobs.views ?? [],
  isLoading: loadingSelector(state),
  error: errorSelector(state),
  specializations: state.jobs.allSpecializations,
  userEducation: state.users.userEducation,
  currentJobViewsFromJobViewService: state.jobs.jobViews,
});

export default connect(mapStateToProps, {
  sendPushForUsersInRadius,
  declineCounterOffer,
  editJob,
  disablePayout,
  enableAutoRefund,
  stopAdditionalCharges,
  stopInvoices,
  cancelJob,
  fetchJob,
  fetchJobViews,
  confirmJob,
  adjustmentChange,
  acceptJob,
  payoutJob,
  refundJob,
  addShiftNote,
  fetchJobNotes,
  markInvoiceAsPaid,
  markJobAsPaidOut,
  instantPayout,
  waivePendingInstantPayout,
  deleteInstantTransaction,
  deleteInvoice,
  submitAdjustment,
  removeJobUnavailableUsers,
  getUserEducation,
  fetchJobViewsFromJobViewService,
})(JobDetails);
