import store from '../store';
import tempMee from '../apis/tempMee';
import {
  AUTH_SIGN_IN_REQUEST,
  AUTH_SIGN_IN_FAILURE,
  AUTH_SIGN_IN_SUCCESS,
  AUTH_REFRESH_SUCCESS,
  AUTH_REFRESH_REQUEST,
  AUTH_REFRESH_FAILURE,
  AUTH_SIGN_IN_GOOGLE_REQUEST,
  AUTH_SIGN_IN_GOOGLE_SUCCESS,
  AUTH_SIGN_IN_GOOGLE_FAILURE,
  USER_ACTIONS,
} from './actionTypes';
import { isValidTokenKey, reverseHex } from '../utils';
import { growthbook } from '../growthbook';

export const refresh = () => async (dispatch) => {
  try {
    dispatch({ type: AUTH_REFRESH_REQUEST });

    if (!isValidTokenKey()) {
      // force logout
      dispatch(logout());
    }

    const response = await tempMee.post('/auth/refresh', {
      token: localStorage.getItem('refresh_token'),
    });

    dispatch({
      type: AUTH_REFRESH_SUCCESS,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_REFRESH_FAILURE,
      payload: err,
    });
  }
};

export const getAuthHeaders = async () => {
  const authState = store.getState().auth;
  if (!authState.expirationDate || store.getState().auth.expirationDate < new Date()) {
    const response = await tempMee.post('/auth/refresh', {
      token: localStorage.getItem('refresh_token'),
    });

    store.dispatch({
      type: AUTH_SIGN_IN_SUCCESS,
      payload: response.data,
    });
  }

  const { access_token } = store.getState().auth;
  return { Authorization: access_token };
};

export const signIn =
  ({ email, password }) =>
    async (dispatch) => {
      try {
        dispatch({ type: AUTH_SIGN_IN_REQUEST });
        const response = await tempMee.post('/admin/auth/signin', { email, password });

        dispatch({
          type: AUTH_SIGN_IN_SUCCESS,
          payload: response.data,
        });
      } catch (err) {
        dispatch({
          type: AUTH_SIGN_IN_FAILURE,
          payload: err,
        });
      }
    };

export const signInWithGoogle = (hexToken) => async (dispatch) => {
  try {
    dispatch({ type: AUTH_SIGN_IN_GOOGLE_REQUEST });

    const { access_token, refresh_token } = reverseHex(hexToken);

    dispatch({
      type: AUTH_SIGN_IN_GOOGLE_SUCCESS,
      payload: { access_token, refresh_token },
    });
  } catch (err) {
    dispatch({
      type: AUTH_SIGN_IN_GOOGLE_FAILURE,
      payload: err,
    });
  }
};

export const logout = () => async (dispatch) => {
  try {
    dispatch({ type: AUTH_SIGN_IN_REQUEST });
    const headers = await getAuthHeaders();
    await tempMee.post('/auth/logout', {}, { headers, withCredentials: true });

    growthbook.setAttributes({});
    dispatch({ type: USER_ACTIONS.RESET_UNFINISHED_USER_LIST_SEARCH });
    dispatch({ type: USER_ACTIONS.RESET_DSO_LIST_SEARCH });
    dispatch({ type: USER_ACTIONS.RESET_DENTIST_LIST_SEARCH });
    dispatch({ type: USER_ACTIONS.RESET_HYGIENIST_LIST_SEARCH });

    dispatch({
      type: AUTH_SIGN_IN_SUCCESS,
      payload: {},
    });
  } catch (err) {
    dispatch({
      type: AUTH_SIGN_IN_FAILURE,
      payload: err,
    });
  }
};
