import React from 'react';
import { useSelector } from 'react-redux';
import { userListConditionalRowStyles } from '../../../commonComponents/user-column';
import { CustomTable } from '../components/CustomTable';
import { DNTCustomFilters } from '../components/DNTCustomFilters';

export const DentistList = () => {
  const data = useSelector((state) => state.users.usersDNT);
  const count = useSelector((state) => state.users.countDNT);
  const searchParams = useSelector((state) => state.users.dentistSearchParams);

  return (
    <CustomTable
      title="Dentist"
      data={data}
      count={count}
      searchParams={searchParams}
      params={{ user_type: 'DNT' }}
      omitColumns={['Acct Standing', 'Average Rating', 'User Type']}
      conditionalRowStyles={userListConditionalRowStyles}
      CustomFilters={DNTCustomFilters}
    />
  );
};
