import { UserProfession } from '../enums/UserProfession';
import { filterObjectKeys } from '../utils';

export const EducationUtils = {
  /**
   * @param specialtiesAndProcedures
   * @param {UserProfession} profession
   * @param specialty
   * @returns {string[]}
   */
  getProceduresOfProfession({ specialtiesAndProcedures, profession, specialty }) {
    if ([UserProfession.FD, UserProfession.RDH].includes(profession)) {
      const procedures = filterObjectKeys(specialtiesAndProcedures, 'procedure');

      return Object.keys(procedures);
    }

    if ([UserProfession.DA, UserProfession.DN].includes(profession)) {
      return specialtiesAndProcedures?.[specialty] ?? [];
    }

    return [];
  },
  /**
   * Remove 'procedure_' prefix from procedure, replacing `?.split('_')?.[1]` method
   *
   * @param {string | undefined} procedure
   *
   * @returns {string}
   */
  removeProcedurePrefix(procedure) {
    if (procedure) {
      return procedure.replace(/^procedure_/g, '');
    }

    return procedure;
  },

  /**
   * Remove 'specialty_' prefix from specialty, replacing `?.split('_')?.[1]` method
   *
   * @param {string | undefined} specialty
   *
   * @returns {string}
   */
  removeSpecialtyPrefix(specialty) {
    if (specialty) {
      return specialty.replace(/^specialty_/g, '');
    }

    return specialty;
  },
};
