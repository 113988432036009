import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { assignOfficeStaffToDso } from '../../../../../../../actions/staffActions';

export const useAssignToDso = () => {
  const dispatch = useDispatch();
  const staffDetails = useSelector((state) => state.staff.staffDetails);

  const handleAssignDso = useCallback(
    (option) => {
      dispatch(assignOfficeStaffToDso({ dsoId: option.id, staffId: staffDetails.id }));
    },
    [dispatch, staffDetails.id],
  );

  return {
    handleAssignDso,
  };
};
