import { Spinner } from '../../../../../commonComponents/Spinner';
import { BorderedTable } from '../../BorderedTable/BorderedTable';
import { ListItem } from '../../BorderedTable/ListItem';
import { useOfficeList } from './hooks';

export const OfficeList = ({ entity, entityItem, AddOfficeToEntityButton, locale = { emptyText: '', title: '' } }) => {
  const { data, handleOnScroll, isInitialLoading, isLoading, total } = useOfficeList({
    entity,
    entityId: entityItem?.id,
  });

  if (isInitialLoading) {
    return (
      <div style={{ flex: 1 }}>
        <Spinner />
      </div>
    );
  }

  return (
    <div style={{ flex: 1 }}>
      <header style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h1 style={{ fontSize: 16, fontWeight: 'bold', marginBottom: 0 }}>
          {locale.title} ({total})
        </h1>
        {AddOfficeToEntityButton && <AddOfficeToEntityButton item={entityItem} />}
      </header>
      <BorderedTable
        emptyStyle={{
          alignItems: 'start',
          justifyContent: 'start',
          paddingInline: 20,
        }}
        emptyText={locale.emptyText}
        data={data}
        onScroll={handleOnScroll}
        isLoading={isLoading}
        Item={({ item }) => (
          <ListItem
            label={item.name}
            // TODO(fred): enable this after the endpoint is available
            // TrailingComponent={
            //   <>
            //     <DeleteOfficeButton office={item} regionId={regionId} />
            //   </>
            // }
          />
        )}
        style={{
          marginTop: 8,
          width: '100%',
        }}
      />
    </div>
  );
};


