import React from 'react';
import { Spinner } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import {
  GroupsTab,
  OfficeAdminsTab,
  OfficesTab,
  RegionsTab,
} from './components/tabs';
import { useDsoGroupDetails } from './hooks/useDsoGroupDetails';
import { getTabByName } from './utils';

export const DsoGroupDetails = () => {
  const params = useParams();
  const selectedTab = getTabByName(params?.currentTab);
  const {
    isLoading,
    data: dsoGroupDetails,
    handleTabSelection,
  } = useDsoGroupDetails(params?.id, selectedTab);

  if (!params?.id || isLoading) {
    return (
      <div
        style={{
          display: 'flex',
          marginTop: 200,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Spinner
          animation="border"
          role="status"
          variant="info"
          style={{
            height: 30,
            width: 30,
          }}
        />
      </div>
    );
  }

  return (
    <div
      style={{
        width: '100vw',
        height: 'calc(100vh - 102px)',
        overflowY: 'scroll',
        overflowX: 'hidden',
      }}
      className="bg-light"
    >
      <div
        className="card bg-light"
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          border: 0,
        }}
      >
        <div
          className="card-body"
          style={{
            backgroundColor: '#435963',
            width: '100%',
            minHeight: '125px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              width: '90%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <h2
                className="card-title"
                style={{
                  position: 'relative',
                  margin: '0px',
                  color: 'white',
                  fontWeight: 400,
                }}
              >
                {dsoGroupDetails.name}
              </h2>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: '#FAFAFA',
          margin: 0,
          marginBottom: 30,
        }}
      >
        <div
          style={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              marginTop: '10px',
              position: 'relative',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            {['Regions', 'Groups', 'Office Admins', 'Offices'].map((tab) => (
              <button
                type="button"
                className="btn btn-info mr-1 ml-1"
                style={{
                  backgroundColor: selectedTab === tab ? '#4FC0CF' : '#307F92',
                  border: 'none',
                }}
                onClick={() => handleTabSelection(tab)}
              >
                {tab}
              </button>
            ))}
          </div>

          <div
            style={{
              position: 'relative',
              width: '100vw',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {selectedTab === 'Regions' && <RegionsTab />}
            {selectedTab === 'Groups' && <GroupsTab />}
            {selectedTab === 'Office Admins' && <OfficeAdminsTab />}
            {selectedTab === 'Offices' && <OfficesTab />}
          </div>
        </div>
      </div>
    </div>
  );
};
