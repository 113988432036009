import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const InfiniteStateScrollSelect = ({
  fetchOptions,
  isLoading,
  stateSelector,
  onSelect,
  idKey = 'id',
  labelKey = 'label',
  customStyle = {},
}) => {
  const dispatch = useDispatch();
  const { filters, data, isActiveRequest, isFinalPage } = useSelector((state) =>
    stateSelector(state),
  );

  const formattedData = data?.map((option) => ({
    id: option[idKey],
    label: option[labelKey],
  }));

  // Fetch initial options on mount
  useEffect(() => {
    dispatch(fetchOptions(1));
  }, [dispatch]);

  // Load more options when the user scrolls to the bottom
  const handleScroll = useCallback(
    (event) => {
      const tableScrollHeight = event?.target?.scrollHeight - event?.target?.scrollTop - 20;
      const clientHeight = event?.target?.clientHeight;
      if (tableScrollHeight <= clientHeight && !isActiveRequest && !isFinalPage) {
        dispatch(fetchOptions(filters.page + 1));
      }
    },
    [dispatch, filters.page, isActiveRequest, isFinalPage],
  );

  // Handle option selection
  const handleSelect = (event) => {
    const selectedValue = event.target.value;
    const selectedOption = formattedData.find((option) => option.id === selectedValue);

    if (onSelect && selectedOption) {
      onSelect(selectedOption);
    }
  };

  return (
    <div>
      <select
        className="custom-select custom-select-lg"
        style={{
          width: '100%',
          overflowY: 'scroll',
          ...customStyle,
        }}
        size={10}
        onScroll={handleScroll}
        onChange={handleSelect}
      >
        {formattedData?.map((option) => (
          <option key={option.id} value={option.id}>
            {option.label}
          </option>
        ))}

        {isLoading && (
          <option disabled value="">
            Loading...
          </option>
        )}
      </select>
    </div>
  );
};