import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { EditPencilIcon } from '../../../../../images/icons';
import { Colors } from '../../../../../themes/colors';
import CustomPortal from '../../../../commonComponents/CustomPortal';
import { FormControl } from '../FormControl';

import "./table.scss";

export const Item = ({ item, label, editAction, isSelected = false, onClick = () => {} }) => {
  const dispatch = useDispatch();
  const { id: dsoId } = useParams();
  const [isHovered, setIsHovered] = useState(false);
  const [name, setName] = useState(item.name);
  const [showModal, setShowModal] = useState(false);

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleSubmit = () => {
    dispatch(editAction(dsoId, item.id, name));
    setShowModal(false);
  };

  const backgroundColor = useMemo(() => {
    if (isHovered) {
      return Colors.neutral_200;
    }

    if (isSelected) {
      return Colors.neutral_100;
    }

    return 'transparent';
  }, [isHovered, isSelected]);

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        fontSize: 16,
        padding: '0 20px',
        gap: 12,
        height: 48,
        backgroundColor,
        transition: 'background-color 0.3s ease',
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <span
        className='bordered-table-item'
        style={{
          flex: 1,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          cursor: 'pointer',
        }}
        onClick={onClick}
      >
        {item.name}
      </span>

      {isHovered && (
        <button
          type="button"
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 4,
            appearance: 'none',
            border: 'none',
            backgroundColor: 'transparent',
          }}
          onClick={() => setShowModal(true)}
        >
          <EditPencilIcon size={16} />
          <span>Edit</span>
        </button>
      )}

      {showModal && (
        <CustomPortal
          title={`Edit ${label} Name`}
          textStyle={{ textAlign: 'center' }}
          cancelText="Close"
          submitText={`Edit ${label}`}
          submitPressed={handleSubmit}
          cancelPressed={() => {
            setShowModal(false);
            setName(item.name);
          }}
          rightButtonDisabled={!name || name === item.name}
        >
          <FormControl label={`Edit ${label} Name:`} value={name} onChange={handleNameChange} />
        </CustomPortal>
      )}
    </div>
  );
};
