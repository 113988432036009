import { useEffect, useState } from 'react';
import debounceHook from '../../../../../commonComponents/debounceHook';
import { DsoFilter } from './DsoFilter';

export const TableHeader = ({ filters = {}, handleFilterChanged = () => {} }) => {
  const [searchStaffEmail, setSearchStaffEmail] = useState(filters.staffEmail);
  const [searchStaffName, setSearchStaffName] = useState(filters.staffName);

  const debouncedSearchStaffEmail = debounceHook(searchStaffEmail, 1000);
  const debouncedSearchStaffName = debounceHook(searchStaffName, 1000);

  useEffect(() => {
    if (filters?.staffEmail === debouncedSearchStaffEmail) return;
    handleFilterChanged('staffEmail', searchStaffEmail);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchStaffEmail]);

  useEffect(() => {
    if (filters?.staffName === debouncedSearchStaffName) return;
    handleFilterChanged('staffName', searchStaffName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchStaffName]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 20,
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          width: '100%',
          gap: 20,
          alignItems: 'end',
          marginTop: 40,
        }}
      >
        <input
          style={{
            paddingLeft: '8px',
            fontFamily: 'Nunito-Regular',
            width: 200,
            height: 32,
          }}
          placeholder="Staff name"
          value={searchStaffName}
          onChange={(e) => setSearchStaffName(e.target.value)}
        />

        <input
          style={{
            paddingLeft: '8px',
            fontFamily: 'Nunito-Regular',
            width: 200,
            height: 32,
          }}
          placeholder="Staff email"
          value={searchStaffEmail}
          onChange={(e) => setSearchStaffEmail(e.target.value)}
        />

        <DsoFilter filters={filters} handleFilterChanged={handleFilterChanged} />
      </div>
    </div>
  );
};
