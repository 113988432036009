import tempMee from '../apis/tempMee';
import { REFERRAL_ACTIONS, SHOW_MESSAGE } from './actionTypes';

import { getAuthHeaders } from './authActions';

export const fetchReferrals = (state) => async (dispatch, getState) => {
  try {
    let newSearchParams = {};
    if (state) {
      newSearchParams = {
        page: state?.page || 1,
        limit: 15,
        payFilter: state?.payFilter || '',
        searchText: state?.searchText || '',
      };
    }

    const { searchParams } = getState().referral;
    if (JSON.stringify(searchParams) === JSON.stringify(newSearchParams) && !state?.refresh) {
      return;
    }
    dispatch({
      type: REFERRAL_ACTIONS.GET_REFERRAL_REQUEST,
      payload: newSearchParams,
    });
    const headers = await getAuthHeaders();
    const response = await tempMee.get('admin/referral', {
      params: newSearchParams,
      headers,
    });

    dispatch({ type: REFERRAL_ACTIONS.GET_REFERRAL_SUCCESS, payload: response.data });
  } catch (err) {
    dispatch({ type: REFERRAL_ACTIONS.GET_REFERRAL_FAILURE, payload: err });
  }
};

export const triggerPayoutReferral = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: REFERRAL_ACTIONS.PAYOUT_REFERRAL_REQUEST,
      payload: id,
    });
    const headers = await getAuthHeaders();
    await tempMee.post(
      `admin/referral/${id}/payout`,
      {},
      {
        headers,
      },
    );
    dispatch({ type: REFERRAL_ACTIONS.PAYOUT_REFERRAL_SUCCESS, payload: id });
    dispatch({
      type: SHOW_MESSAGE,
      payload: { message: 'Payout is successful!' },
    });
  } catch (err) {
    dispatch({ type: REFERRAL_ACTIONS.PAYOUT_REFERRAL_FAILURE, payload: err });
  }
};
