import { Spinner } from '../../../../../commonComponents/Spinner';
import { Table as TableComponent } from '../../../../../commonComponents/Table/Table';
import { TabViewContainer } from '../../../../../commonComponents/TabViewContainer';
import { useRateLimitHistory } from './hooks/useRateLimitHistory';

export const RateLimitHistoryTab = () => {
  const { data, filters, isInitialLoading, isLoading, total, totalPages, handlePageChanged } =
    useRateLimitHistory();

  return (
    <TabViewContainer
      style={{
        width: '80%',
      }}
      title="Rate Limit History"
      variant="card"
    >
      <div
        style={{
          margin: '0 80px',
        }}
      >
        {isInitialLoading ? (
          <Spinner />
        ) : (
          <TableComponent
            columns={[
              {
                id: 'professionType',
                selector: ({ professionType }) => professionType,
                name: 'Profession Type',
              },
              {
                id: 'oldValue',
                selector: ({ oldValue }) => oldValue,
                name: 'Old Value',
              },
              {
                id: 'newValue',
                selector: ({ newValue }) => newValue,
                name: 'New Value',
              },
              {
                id: 'createdBy',
                selector: ({ createdBy }) => createdBy,
                name: 'Created By',
              },
            ]}
            initialPage={filters.page}
            data={data}
            noDataComponent="There are no rate limit histories"
            isLoading={isLoading}
            totalPages={totalPages}
            paginationPerPage={filters.limit}
            paginationTotalRows={total}
            onPageChange={handlePageChanged}
          />
        )}
      </div>
    </TabViewContainer>
  );
};
