import { useEffect, useState } from 'react';
import debounceHook from '../../../commonComponents/debounceHook';

export const CustomHeader = ({ filter, handleFilterChange, CustomFilters }) => {
  const [searchText, setSearchText] = useState(filter?.searchText || '');
  const debouncedSearchText = debounceHook(searchText, 1000);
  const [phone, setPhone] = useState(filter?.phone || '');
  const debouncedPhone = debounceHook(phone, 1000);

  useEffect(() => {
    handleFilterChange('searchText', searchText);
  }, [debouncedSearchText]);

  useEffect(() => {
    handleFilterChange('phone', phone);
  }, [debouncedPhone]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 20,
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          width: '100%',
          gap: 20,
          alignItems: 'end',
          marginTop: 40,
        }}
      >
        <input
          style={{
            paddingLeft: '8px',
            fontFamily: 'Nunito-Regular',
            width: 200,
            height: 32,
          }}
          placeholder="Search"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />

        <input
          style={{
            paddingLeft: '8px',
            fontFamily: 'Nunito-Regular',
            width: 200,
            height: 32,
          }}
          placeholder="Phone search"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />

        <CustomFilters filter={filter} handleFilterChange={handleFilterChange} />
      </div>
    </div>
  );
};