import { StaticRouter } from 'react-router-dom';
import {
  FETCH_PP_JOB_SUCCESS,
  EDIT_PP_JOB_SUCCESS,
  FETCH_PP_JOBS_SUCCESS,
  ASSIGN_OFFICE_PP_JOB_SUCCESS,
  FETCH_PP_JOBS_REQUEST,
  PP_RECRUITER_ACTIONS,
  PP_JOB_ACTIONS,
} from '../actions/actionTypes';
import { ActionTypes } from '../components/mainComponents/Job/PP/Enums';

const defaultState = {
  curentJob: null,
  allPPJobs: [],
  allPPRecruiters: [],
  totalPPJobs: 0,
  applications: [],
  selectedApplication: null,
  historyList: [],
  searchParams: {
    page: 1,
    limit: 15,
    status: '',
    recruiter: '',
    officeName: '',
    state: '',
    zipCode: '',
  },
};
export default (state = defaultState, action) => {
  switch (action.type) {
  case FETCH_PP_JOBS_REQUEST:
    return {
      ...state,
      searchParams: { ...state.searchParams, ...action.payload },
    };
  case FETCH_PP_JOBS_SUCCESS:
    return { ...state, allPPJobs: action.payload.jobs, totalPPJobs: action.payload.total };
  case PP_RECRUITER_ACTIONS.FETCH_PP_RECRUITER_SUCCESS:
    return { ...state, allPPRecruiters: action.payload };
  case EDIT_PP_JOB_SUCCESS:
  case FETCH_PP_JOB_SUCCESS:
  case ASSIGN_OFFICE_PP_JOB_SUCCESS:
    return {
      ...state,
      curentJob: action.payload,
    };
  case PP_JOB_ACTIONS.GET_PP_APPLICATIONS_JOBS_SUCCESS:
    return {
      ...state,
      applications: action.payload,
    };
  case PP_JOB_ACTIONS.GET_PP_APPLICATION_SUCCESS: {
    return {
      ...state,
      selectedApplication: action.payload,
    };
  }
  case PP_JOB_ACTIONS.UPDATE_PP_APPLICATION_SUCCESS: {
    return {
      ...state,
      selectedApplication: { ...state.selectedApplication, ...action.payload },
    };
  }
  case PP_JOB_ACTIONS.HIDE_SELECTED_APPLICATION: {
    return {
      ...state,
      selectedApplication: null,
    };
  }
  case PP_JOB_ACTIONS.GET_S3_URL_RESUME_SUCCESS:
    return {
      ...state,
      s3Link: action.payload,
    };
  case PP_JOB_ACTIONS.FETCH_APPLICATION_HISTORY_SUCCESS: {
    return {
      ...state,
      historyList: action.payload,
    };
  }
  case PP_JOB_ACTIONS.CREATE_ACTION_PP_JOBS_SUCCESS:
    const { applicatioId, newAction } = action.payload;

    const index = state.applications.findIndex(
      (application) => application.applicationId === applicatioId,
    );
    let { isHidden } = state.applications[index];
    if (newAction.action === ActionTypes.show) {
      isHidden = false;
    } else if (newAction.action === ActionTypes.hide) {
      isHidden = true;
    }
    const updatedApplication = {
      ...state.applications[index],
      lastApplicationAction: newAction,
      isHidden,
    };
    if (index === -1) {
      return state;
    }

    return {
      ...state,
      applications: [
        updatedApplication,
        ...state.applications.filter((app) => app.applicationId !== applicatioId),
      ],
    };
  case PP_JOB_ACTIONS.UPLOAD_APPLICATION_RESUME_JOBS_SUCCESS: {
    const resumeApplicationId = action.payload.applicatioId;
    const resumeJobId = action.payload.jobId;

    const resumeUrl = `https://s3.amazonaws.com/${process.env.REACT_APP_RESUME_BUCKET}/${resumeJobId}/${resumeApplicationId}/resume.pdf`;

    return {
      ...state,
      selectedApplication: {
        ...state.selectedApplication,
        resumeUrl,
      },
    };
  }
  case PP_JOB_ACTIONS.APPLY_FOR_JOB_SUCCESS: {
    return {
      ...state,
      applications: [action.payload, ...state.applications],
    };
  }
  case PP_JOB_ACTIONS.SET_INTERVIEW_TIME_SUCCESS: {
    const applicationIdInterviewTime = action.payload.applicatioId;
    const { interviewInfo } = action.payload;

    const indexInterviewTime = state.applications.findIndex(
      (application) => application.applicationId === applicationIdInterviewTime,
    );

    if (indexInterviewTime === -1) {
      return state;
    }
    const updatedApplicationInterview = {
      ...state.applications[indexInterviewTime],
      interview: interviewInfo,
    };

    return {
      ...state,
      applications: [
        ...state.applications.slice(0, indexInterviewTime),
        updatedApplicationInterview,
        ...state.applications.slice(indexInterviewTime + 1),
      ],
    };
  }
  case PP_JOB_ACTIONS.SET_RESUME_DETAILS_SUCCESS: {
    const editedApplicationIndex = state.applications.findIndex(
      (application) => application.applicationId === action.payload.applicationId,
    );

    if (editedApplicationIndex === -1) {
      return state;
    }
    const updatedApplicationInterview = {
      ...state.applications[editedApplicationIndex],
      resume: action.payload.resumeDetails,
    };

    return {
      ...state,
      applications: [
        ...state.applications.slice(0, editedApplicationIndex),
        updatedApplicationInterview,
        ...state.applications.slice(editedApplicationIndex + 1),
      ],
    };
  }

  default:
    return state;
  }
};
