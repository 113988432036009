import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchApplicationHistory, getApplicationDetail } from '../../../../actions/ppJobsAction';
import { fetchCurrentUser } from '../../../../actions/userActions';
import ResumeForm from '../../../commonComponents/ResumeForm';
import ApplicationGeneralIfo from './ApplicationGeneralIfo';
import { ActionTypes, getApplicationStatus } from './Enums';
import RenderApplicationDetails from './RenderApplicationDetails';
import RenderHistory from './RenderHistory';

export default () => {
  const applications = useSelector((state) => state.pp.applications);
  const [isApplyPopupOpen, setIsApplyPopupOpen] = useState(false);
  const currentJob = useSelector((state) => state.pp.curentJob);
  const [editApplicationId, setEditApplicationId] = useState('');
  const [showHistoryApplicationId, setShowHistoryApplicationId] = useState('');

  const dispatch = useDispatch();

  const showMoreInfoClicked = (applicationId) => {
    dispatch(getApplicationDetail(currentJob.id, applicationId));
    setEditApplicationId(applicationId);
  };

  const showHistoryClicked = (applicationId) => {
    dispatch(fetchApplicationHistory(currentJob.id, applicationId));
    setShowHistoryApplicationId(true);
  };

  const renderApplicationTitle = () => (
    <div
      style={{
        width: '100%',
        borderBottom: '1px solid #efefef',
        padding: '5px 10px',
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: 'lightblue',
      }}
    >
      <p className="card-text" style={{ fontWeight: '600', textAlign: 'center', width: '20%' }}>
        Status
      </p>

      <p className="card-text" style={{ fontWeight: '600', textAlign: 'center', width: '40%' }}>
        General Application Information
      </p>

      <p className="card-text" style={{ fontWeight: '600', width: '30%', textAlign: 'center' }}>
        Show details
      </p>
      <p className="card-text" style={{ fontWeight: '600', width: '30%', textAlign: 'center' }}>
        Show history
      </p>
    </div>
  );

  const renderApplication = (application) => {
    const isInterviewPending =
      application &&
      application.lastApplicationAction?.action.includes('interview') &&
      application.interview?.status === 'initialized';
    const isInterviewConfirmed =
      application &&
      application.lastApplicationAction?.action.includes('interview') &&
      application.interview?.status === 'scheduled';

    return (
      <div
        key={application.applicationId}
        style={{
          width: '100%',
          borderBottom: '1px solid #efefef',
          padding: '5px 10px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor:
            application && application.lastApplicationAction?.action === 'hired'
              ? '#d2f8d2'
              : 'white',
        }}
      >
        <div style={{ width: '20%' }}>
          <p
            className="card-text"
            style={{
              width: '90%',
              textAlign: 'center',
              fontWeight: 'bold',
              color: application.isHidden ? 'red' : 'green',
            }}
          >
            {application.isHidden ? 'Hidden' : 'Active'}
          </p>
          <p
            className="card-text"
            style={{
              textAlign: 'center',
              fontWeight: 'bold',
              width: '90%',
              color:
                application.lastApplicationAction?.action === ActionTypes.cancelInterview
                  ? 'red'
                  : 'black',
            }}
          >
            {getApplicationStatus(application.lastApplicationAction?.action)}
          </p>
          {isInterviewPending ? (
            <p className="card-text" style={{ textAlign: 'center', width: '90%', color: 'red' }}>
              Interview requested
            </p>
          ) : (
            <div />
          )}
          {isInterviewConfirmed ? (
            <p className="card-text" style={{ textAlign: 'center', width: '90%' }}>
              {moment(application.interview?.time).format('YYYY-MM-DD HH:mm A')}
            </p>
          ) : (
            <div />
          )}
        </div>

        <ApplicationGeneralIfo application={application} />

        <div style={{ width: '30%', justifyContent: 'center', display: 'flex' }}>
          <button
            className="btn btn-info mt-2"
            type="button"
            style={{ width: 150 }}
            onClick={() => {
              showMoreInfoClicked(application.applicationId);
            }}
          >
            Show
          </button>
        </div>
        <div style={{ width: '30%', justifyContent: 'center', display: 'flex' }}>
          <button
            className="btn btn-success mt-2"
            style={{ width: 150 }}
            onClick={() => showHistoryClicked(application.applicationId)}
          >
            Show history
          </button>
        </div>
      </div>
    );
  };

  return (
    <div
      className="card"
      style={{
        width: '90%',
        marginLeft: 20,
        alignSelf: 'center',
        justifyContent: 'center',
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h2
          className="card-title"
          style={{
            fontFamily: 'Nunito',
            color: '#494963',
            marginLeft: '45%',
          }}
        >
          Applications
        </h2>
        <div>
          <button
            className="btn btn-success mt-4 mr-2"
            style={{ height: 40 }}
            onClick={() => setIsApplyPopupOpen(true)}
          >
            Create new application
          </button>
        </div>
      </div>
      {applications.length === 0 ? (
        <div
          style={{
            textAlign: 'center',
            width: '70%',
            margin: 20,
            alignSelf: 'center',
            maxWidth: 1000,
            marginLeft: 40,
          }}
        >
          {' '}
          There are no applications yet
        </div>
      ) : (
        <div
          className="card"
          style={{
            width: '70%',
            margin: 20,
            alignSelf: 'center',
            maxWidth: 1000,
          }}
        >
          {renderApplicationTitle()}
          {applications.map((application) => renderApplication(application))}
        </div>
      )}
      {isApplyPopupOpen ? (
        <div
          className="container"
          style={{
            maxWidth: 550,
            marginBottom: 50,
          }}
        >
          <ResumeForm
            isOpen={isApplyPopupOpen}
            currentJob={currentJob}
            closeForm={() => setIsApplyPopupOpen(false)}
          />
        </div>
      ) : (
        <div />
      )}
      {editApplicationId !== '' ? (
        <RenderApplicationDetails
          closePopUp={() => setEditApplicationId('')}
          applicationInfo={
            applications.filter((application) => application.applicationId === editApplicationId)[0]
          }
        />
      ) : (
        <div />
      )}
      {showHistoryApplicationId !== '' ? (
        <RenderHistory closePopUp={() => setShowHistoryApplicationId('')} />
      ) : (
        <div />
      )}
    </div>
  );
};
