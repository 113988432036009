import React, { useState } from 'react';

export const ButtonGroup = ({ options, onClick }) => {
  const [selected, setSelected] = useState([]);

  const handleOnClick = (optionValue) => {
    let newSelected = [...selected];

    if (newSelected.includes(optionValue)) {
      newSelected = newSelected.filter((item) => item !== optionValue);
    } else {
      newSelected.push(optionValue);
    }

    setSelected(newSelected);
    onClick(newSelected);
  };

  const getClassNames = (index, optionValue) => {
    let className = 'button_group_container_option';

    if (index === 0) {
      className = 'button_group_container_first_option';
    } else if (index === Object.keys(options).length - 1) {
      className = 'button_group_container_last_option';
    }

    return `${className} ${selected.includes(optionValue) ? 'selected_button_group_option' : ''}`;
  };

  return (
    <div className="button_group_container" style={{ display: 'flex' }}>
      {options.map((option, index) => (
        <button
          key={option.value}
          type="button"
          style={{ all: 'unset' }}
          onClick={() => handleOnClick(option.value)}
        >
          <div className={getClassNames(index, option.value)}>
            <span>{option.text}</span>
          </div>
        </button>
      ))}
    </div>
  );
};
