import { useComboboxContext } from '../context';

export const Value = ({ placeholder, values }) => {
  const { options } = useComboboxContext();

  const isAllOptionSelected = options.length === values.length;

  if (isAllOptionSelected) {
    return <span className="multiselect__placeholder">All</span>;
  }

  if (values.length) {
    return values.map((it) => (
      <span key={it.value} className="multiselect__selected-option">
        {it.label}
      </span>
      ));
  }

  return <span className="multiselect__placeholder">{placeholder}</span>;
};
