import { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { ModalNCNSRepostConfirmation } from './ModalNCNSRepostConfirmation';
import { generateHourlyOptions } from './utils/incidentPopupUtil';

export const NCNSRepostForm = ({ jobId, jobDateTimeStart, jobDatetimeEnd, repostMaxStartTime }) => {
  const [selectedRepostMaxStartTime, setSelectedRepostMaxTime] = useState(repostMaxStartTime);
  const [isModalConfirmationShow, setModalConfirmationShow] = useState(null);
  const [hourlyOptions, setHourlyOptions] = useState([]);

  const getHourlyOptions = useCallback(() => {
    if (!jobDatetimeEnd) {
      return;
    }

    const nowAt = new Date();
    const maxAt = new Date(jobDatetimeEnd);

    setHourlyOptions(generateHourlyOptions(nowAt, maxAt));
  }, [jobDatetimeEnd]);

  const getNewStartTime = () => {
    const nextHour = moment().add(1, 'hours');
    return nextHour.format('h:mm a');
  };

  useEffect(() => {
    getHourlyOptions();
  }, [getHourlyOptions]);

  const generateOptions = useCallback(() => {
    if (repostMaxStartTime) {
      return (
        <option key={repostMaxStartTime} value={repostMaxStartTime}>
          {moment(repostMaxStartTime).format('HH:mm')}
        </option>
      );
    }

    if (hourlyOptions.length > 0) {
      return hourlyOptions.map((item) => (
        <option key={item.value} value={item.value}>
          {item.title}
        </option>
      ));
    }

    return null;
  }, [hourlyOptions, repostMaxStartTime]);

  if (moment().diff(moment(jobDateTimeStart), 'seconds') < 1800) {
    return null;
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 80,
      }}
    >
      <h3 className="card-title title" style={{ marginTop: 20, marginBottom: 20 }}>
        Repost for No Call No Show
      </h3>
      {repostMaxStartTime && (
        <p className="card-text" style={{ marginBottom: 30 }}>
          This shift has already been reposted.
        </p>
      )}
      <div className="d-flex" style={{ flexDirection: 'column' }}>
        <b>Stop Searching At:</b>
        <select
          className="custom-select custom-select-lg"
          style={{ width: 152, marginTop: 10 }}
          disabled={!!repostMaxStartTime}
          value={selectedRepostMaxStartTime}
          onChange={(e) => setSelectedRepostMaxTime(e.target.value)}
        >
          <option value={null}>--</option>
          {generateOptions()}
        </select>
      </div>
      <button
        className="btn btn-success mr-2"
        type="button"
        style={{
          width: 150,
          borderRadius: 10,
          marginTop: '12px',
        }}
        onClick={() => {
          getNewStartTime();
          setModalConfirmationShow(true);
        }}
        disabled={!selectedRepostMaxStartTime || !!repostMaxStartTime}
      >
        Repost for NCNS
      </button>

      <ModalNCNSRepostConfirmation
        isVisible={isModalConfirmationShow}
        jobId={jobId}
        repostMaxStartTime={selectedRepostMaxStartTime}
        onClose={() => setModalConfirmationShow(false)}
      />
    </div>
  );
};
