import React from 'react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

export default class LocationSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = { address: props.address ? props.address?.address?.formatted_address : '' };
  }

  handleChange = (address) => {
    this.setState({ address });
    if (this.props.onChange) {
      this.props.onChange(address);
    }
  };

  handleSelect = async (address) => {
    try {
      const results = await geocodeByAddress(address);
      const coords = await getLatLng(results[0]);
      if (this.props.onSelect) {
        this.props.onSelect({
          address: results[0],
          coords,
        });
      }
      this.setState({ address: results[0].formatted_address });
    } catch (e) {
      console.error(e);
    }
  };

  render() {
    return (
      <PlacesAutocomplete
        value={this.state.address}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
        debounce={1000}
        searchOptions={{
          componentRestrictions: { country: ['us'] },
          types: ['address'],
        }}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div
            style={{
              width: '100%',
            }}
          >
            <input
              {...getInputProps({
                placeholder: 'Search Places ...',
                className: 'location-search-input form-control',
              })}
            />
            <div
              className="autocomplete-dropdown-container"
              style={{ position: 'absolute', backgroundColor: 'white', width: 300 }}
            >
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion) => {
                const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? {
                    backgroundColor: '#fafafa',
                    cursor: 'pointer',
                    margin: 5,
                    width: '90%',
                    padding: 3,
                  }
                  : {
                    backgroundColor: '#ffffff',
                    cursor: 'pointer',
                    margin: 5,
                    width: '90%',
                    padding: 3,
                  };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}
