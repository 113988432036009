import React from 'react';
import { useSelector } from 'react-redux';
import { officeTypes } from '../../../constants';

export default function LineInfo({
  title,
  info,
  onDidChange,
  isEdit,
  isAdmin,
  isDDL,
  isSoftware,
  isInsurance,
  ddlValue,
  type,
  parkingInformation,
  isParkingCost,
  isParkingType,
  isTimePerPatient,
  isAssistedHygiene,
}) {
  const currentUser = useSelector((state) => state.users.currentUser);
  const userSoftwares = currentUser?.office_info?.education?.softwares;
  const userInsurances = currentUser?.office_info?.insurances;
  const allMetadata = useSelector((state) => state.users.allMetadata);
  const userEducation = useSelector((state) => state.users.userEducation);

  if (isDDL) {
    return (
      <div
        className="container"
        style={{
          width: '100%',
          borderBottom: '1px solid #efefef',
          padding: 5,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <p className="col-6" style={{ fontWeight: '600' }}>
          {title}
        </p>
        {isEdit ? (
          <select
            className="custom-select custom-select-lg mb-3"
            style={{ marginTop: '5px', position: 'relative' }}
            value={ddlValue}
            onChange={(e) => onDidChange(e.target.value)}
          >
            {!ddlValue && <option className="dropdown-item">Select</option>}
            {officeTypes.map(({ title: dropdownTitle, value }) => (
              <option className="dropdown-item" value={value}>
                {dropdownTitle}
              </option>
            ))}
          </select>
        ) : (
          <p className="col-6" style={{ textAlign: 'right' }}>
            {info}
          </p>
        )}
      </div>
    );
  }

  if (isAdmin) {
    return (
      <div
        className="container"
        style={{
          width: '100%',
          borderBottom: '1px solid #efefef',
          padding: 5,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <p className="col-6" style={{ fontWeight: '600' }}>
          {title}
        </p>
        {isEdit ? (
          <select
            className="custom-select custom-select-lg mb-3"
            style={{ marginTop: '5px', position: 'relative' }}
            value={Boolean(info)}
            onChange={(e) => onDidChange(e.target.value)}
          >
            <option className="dropdown-item" value>
              Yes
            </option>
            <option className="dropdown-item" value={false}>
              No
            </option>
          </select>
        ) : (
          <p className="col-6" style={{ textAlign: 'right' }}>
            {info === true ? 'Yes' : 'No'}
          </p>
        )}
      </div>
    );
  }

  if (isSoftware) {
    return (
      <div
        className="container"
        style={{
          width: '100%',
          borderBottom: '1px solid #efefef',
          padding: 5,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <p className="col-6" style={{ fontWeight: '600' }}>
          {title}
        </p>
        {isEdit ? (
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexWrap: 'wrap',
              rowGap: 10,
              columnGap: 20,
            }}
          >
            {userEducation?.softwares?.map((name) => (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <input
                  key={name}
                  className="react-switch-checkbox mr-2"
                  type="checkbox"
                  onChange={() => {
                    if (info.includes(name)) {
                      onDidChange(info.filter((item) => item !== name));
                    } else {
                      onDidChange([...info, name]);
                    }
                  }}
                  checked={info?.includes(name)}
                />

                <p style={{ marginBottom: 0 }}>{name}</p>
              </div>
            ))}
          </div>
        ) : (
          <p className="col-6" style={{ textAlign: 'right' }}>
            {userSoftwares?.join(', ') || '-'}
          </p>
        )}
      </div>
    );
  }

  if (isInsurance) {
    return (
      <div
        className="container"
        style={{
          width: '100%',
          borderBottom: '1px solid #efefef',
          padding: 5,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <p className="col-6" style={{ fontWeight: '600' }}>
          {title}
        </p>
        {isEdit ? (
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexWrap: 'wrap',
              rowGap: 10,
              columnGap: 20,
            }}
          >
            {userEducation?.insurances?.map((name) => (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <input
                  key={name}
                  className="react-switch-checkbox mr-2"
                  type="checkbox"
                  onChange={() => {
                    if (info.includes(name)) {
                      onDidChange(info.filter((item) => item !== name));
                    } else {
                      onDidChange([...info, name]);
                    }
                  }}
                  checked={info?.includes(name)}
                />

                <p style={{ marginBottom: 0 }}>{name}</p>
              </div>
            ))}
          </div>
        ) : (
          <p className="col-6" style={{ textAlign: 'right' }}>
            {userInsurances?.join(', ') || '-'}
          </p>
        )}
      </div>
    );
  }

  const getParkingCostValue = () => {
    if (currentUser?.parkingInformation?.isParkingFree) return 'Free';
    if (!currentUser?.parkingInformation?.cost || !currentUser?.parkingInformation?.costType)
      return '-';

    return info;
  };

  if (isParkingCost) {
    return (
      <div
        className="container"
        style={{
          width: '100%',
          borderBottom: '1px solid #efefef',
          padding: 5,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <p className="col-6" style={{ fontWeight: '600' }}>
          {title}
        </p>

        {isEdit ? (
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexWrap: 'wrap',
              rowGap: 10,
              columnGap: 20,
            }}
          >
            <div
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <p className="global_font" style={{ width: '100%', margin: 0 }}>
                Free Parking:
              </p>

              <select
                className="custom-select custom-select-lg mb-3"
                style={{ marginTop: '5px', position: 'relative' }}
                value={parkingInformation?.is_parking_free}
                onChange={(e) =>
                  onDidChange({
                    ...parkingInformation,
                    is_parking_free: e.target.value ? e.target.value === 'true' : undefined,
                  })
                }
              >
                <option className="dropdown-item" value="">
                  Select
                </option>
                <option className="dropdown-item" value>
                  Yes
                </option>
                <option className="dropdown-item" value={false}>
                  No
                </option>
              </select>
            </div>

            {parkingInformation?.is_parking_free === false && (
              <>
                <input
                  className={type ? 'form-control disabled-arrow-input' : 'form-control'}
                  value={parkingInformation?.parking_cost}
                  type={type || 'text'}
                  onChange={(e) =>
                    onDidChange({
                      ...parkingInformation,
                      parking_cost: e.target.value,
                    })
                  }
                />

                <select
                  className="custom-select custom-select-lg mb-3"
                  style={{ marginTop: '5px', position: 'relative' }}
                  value={parkingInformation?.parking_cost_type}
                  onChange={(e) =>
                    onDidChange({
                      ...parkingInformation,
                      parking_cost_type: e.target.value,
                    })
                  }
                >
                  <option className="dropdown-item" value="hr">
                    per hour
                  </option>
                  <option className="dropdown-item" value="day">
                    per day
                  </option>
                </select>
              </>
            )}
          </div>
        ) : (
          <p className="col-6" style={{ textAlign: 'right' }}>
            {getParkingCostValue()}
          </p>
        )}
      </div>
    );
  }

  if (isParkingType) {
    return (
      <div
        className="container"
        style={{
          width: '100%',
          borderBottom: '1px solid #efefef',
          padding: 5,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <p className="col-6" style={{ fontWeight: '600' }}>
          {title}
        </p>

        {isEdit ? (
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexWrap: 'wrap',
              rowGap: 10,
              columnGap: 20,
            }}
          >
            <select
              className="custom-select custom-select-lg mb-3"
              style={{ marginTop: '5px', position: 'relative' }}
              value={info}
              onChange={(e) => onDidChange(e.target.value)}
            >
              {!info && <option className="dropdown-item">Select</option>}
              {allMetadata?.parkingTypes?.map((parkingType) => (
                <option className="dropdown-item" value={parkingType}>
                  {parkingType}
                </option>
              ))}
            </select>
          </div>
        ) : (
          <p className="col-6" style={{ textAlign: 'right' }}>
            {info}
          </p>
        )}
      </div>
    );
  }

  if (isTimePerPatient) {
    return (
      <div
        className="container"
        style={{
          width: '100%',
          borderBottom: '1px solid #efefef',
          padding: 5,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <p className="col-6" style={{ fontWeight: '600' }}>
          {title}
        </p>

        {isEdit ? (
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexWrap: 'wrap',
              rowGap: 10,
              columnGap: 20,
            }}
          >
            <select
              className="custom-select custom-select-lg mb-3"
              style={{ marginTop: '5px', position: 'relative' }}
              value={info}
              onChange={(e) => onDidChange(e.target.value)}
            >
              {!info && <option className="dropdown-item">Select</option>}
              {['N/A', '30 min', '45 min', '1 hr', '1.5 hr', '2+ hr'].map((value) => (
                <option className="dropdown-item" value={value}>
                  {value}
                </option>
              ))}
            </select>
          </div>
        ) : (
          <p className="col-6" style={{ textAlign: 'right' }}>
            {info}
          </p>
        )}
      </div>
    );
  }

  if (isAssistedHygiene) {
    return (
      <div
        className="container"
        style={{
          width: '100%',
          borderBottom: '1px solid #efefef',
          padding: 5,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <p className="col-6" style={{ fontWeight: '600' }}>
          {title}
        </p>
        {isEdit ? (
          <select
            className="custom-select custom-select-lg mb-3"
            style={{ marginTop: '5px', position: 'relative' }}
            value={info}
            onChange={(e) => onDidChange(e.target.value === 'true')}
          >
            {info === undefined && <option className="dropdown-item">Select</option>}
            <option className="dropdown-item" value>
              Yes
            </option>
            <option className="dropdown-item" value={false}>
              No
            </option>
          </select>
        ) : (
          <p className="col-6" style={{ textAlign: 'right' }}>
            {info !== undefined ? (info ? 'Yes' : 'No') : '-'}
          </p>
        )}
      </div>
    );
  }

  return (
    <div
      className="container"
      style={{
        width: '100%',
        borderBottom: '1px solid #efefef',
        padding: 5,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <p className="col-6" style={{ fontWeight: '600' }}>
        {title}
      </p>
      {isEdit ? (
        <input
          className={type ? 'form-control disabled-arrow-input' : 'form-control'}
          value={info}
          type={type || 'text'}
          onChange={(e) => onDidChange(e.target.value)}
        />
      ) : (
        <p className="col-6" style={{ textAlign: 'right' }}>
          {info}
        </p>
      )}
    </div>
  );
}
