import React from 'react';
import '../../../themes/LoyaltyCardGroup.scss';
import { useSelector } from 'react-redux';

export function LoyaltyCardGroup() {
  const loyaltyLevel = useSelector((state) => state.metrics.loyalty?.[0]?.level);

  return (
    <div className="loyalty-card-group">
      <div className="card border-0">
        <h3 className="card-title">
          Loyalty Program Level
        </h3>

        <div className="row-block">
          <p className="card-text row-block__label">Level</p>
          <p className="card-text">{loyaltyLevel ?? '-'}</p>
        </div>
      </div>
    </div>
  );
}
