import React from 'react';
import dateFormat from 'dateformat';
import { Link } from 'react-router-dom';
import { EditUserFields } from '../../User/EditUserFields';

export default ({ editList, isUserEdits }) => {
  const valueFormatJobEdits = (valueType, value) => {
    switch (valueType) {
    case 'is_lunch_break_paid':
      return value ? 'Yes' : 'No';
    case 'job_datetime_end':
    case 'job_datetime':
    case 'adjustment_finish':
    case 'adjustment_start':
      return value && value !== 'undefined'
        ? dateFormat(value, 'ddd, mmmm dd, yyyy h:MM:ss TT')
        : value;
    case 'rate':
      return parseFloat(value).toFixed(2) || value;
    case 'author':
      return value;
    case 'paid_out':
      return `${value}`;
    case 'disabled_payout':
      return value === 'true' ? 'disabled' : 'enabled';
    case 'lunch_break_time':
    case 'adjustment_break':
    case 'adjustment_status':
    default:
      return value || '-';
    }
  };

  const typeFormatJobEdits = (valueType) => {
    switch (valueType) {
    case 'job_datetime':
      return 'Start Date';
    case 'is_lunch_break_paid':
      return 'Meal Break Paid';
    case 'job_datetime_end':
      return 'End Date';
    case 'lunch_break_time':
      return 'Meal Break Time';
    case 'rate':
      return 'Rate';
    case 'disabled_payout':
      return 'Disabled payout';

    case 'adjustment_start':
      return 'Adjustment Applied: Start Time';
    case 'adjustment_finish':
      return 'Adjustment Applied: End Time';
    case 'adjustment_break':
      return 'Adjustment Applied: Meal Break';
    case 'adjustment_status':
      return 'Adjustment Status';
    case 'paid_out':
      return 'Paid Out';
    default:
      return valueType;
    }
  };

  if (editList.length === 0) {
    return (
      <div
        style={{
          height: 200,
          overflowY: 'auto',
          borderBottom: '1px solid #efefef',
        }}
      >
        <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
          <p
            style={{
              fontSize: 18,
              alignText: 'center',
            }}
          >
            No Edits
          </p>
        </div>
      </div>
    );
  }
  const renderFieldName = (fieldName) => {
    if (!isUserEdits) {
      return typeFormatJobEdits(fieldName);
    }
    if (isUserEdits && EditUserFields[fieldName]) {
      return EditUserFields[fieldName];
    }
    return fieldName;
  };
  return (
    <div
      style={{
        minHeight: 200,
        maxHeight: 500,
        overflowY: 'auto',
        borderBottom: '1px solid #efefef',
        width: '100%',
      }}
    >
      {editList.map((editItem) => (
        <div
          key={editItem.field}
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyItems: 'center',
            paddingBottom: 10,
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: '1px solid #efefef',
              borderRadius: 25,
              marginTop: 10,
              flex: 1,
            }}
          >
            <p
              style={{
                margin: 0,
                padding: '10px 10px',
                flex: 5,
              }}
            >
              {dateFormat(editItem.date, 'dddd, mmmm dS, yyyy, h:MM:ss TT') || ''}
            </p>
            <p
              style={{
                margin: 0,
                padding: '10px 10px',
                flex: 3,
              }}
            >
              {editItem?.author?.full_name ? (
                <Link
                  style={{ marginTop: 10, marginBottom: 10 }}
                  to={{
                    pathname: `/user/${
                      editItem?.author?.user_type === 'dentist' ? 'dentist' : 'hygienist'
                    }/${editItem?.author.id}`,
                  }}
                >
                  {editItem?.author?.full_name?.toString()}
                </Link>
              ) : (
                <p
                  style={{
                    margin: 0,
                    padding: '10px 10px',
                    flex: 3,
                  }}
                >
                  {' '}
                  N/A
                </p>
              )}
            </p>
            <p
              style={{
                margin: 0,
                padding: '10px 5px',

                flex: 3,
              }}
            >
              {renderFieldName(editItem.field)}
            </p>
            <p
              style={{
                margin: 0,
                padding: '10px 5px',
                flex: 3,
              }}
            >
              {isUserEdits
                ? editItem.oldValue?.toString()
                : valueFormatJobEdits(editItem.field, editItem.oldValue)}
            </p>
            <p
              style={{
                margin: 0,
                padding: '10px 10px',
                flex: 3,
              }}
            >
              {isUserEdits
                ? editItem.value?.toString()
                : valueFormatJobEdits(editItem.field, editItem.value)}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};
