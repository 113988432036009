export const CustomSelect = ({ id, label, value, onChange, options }) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      minWidth: 200,
    }}
  >
    <p>{label}:</p>

    <select
      className="custom-select custom-select-lg"
      style={{ height: 32 }}
      value={value}
      onChange={(val) => {
        onChange(id, val.target.value !== 'all' ? val.target.value : '');
      }}
    >
      {options.map((option) => (
        <option key={option.code} value={option.code}>
          {option.name}
        </option>
      ))}
    </select>
  </div>
);
