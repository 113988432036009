export const convertTimeToMinutes = (time) => {
  if (time.includes('min')) return parseInt(time, 10);

  if (time.includes('hr')) {
    const hours = parseFloat(time, 10);
    return hours * 60;
  }

  return 0;
};
