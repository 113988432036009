import { InputNumber } from 'antd';
import { UserProfession } from '../../../../../../../../enums/UserProfession';
import { useForm } from './hooks/useForm';
import { useRateLimit } from './hooks/useRateLimit';

export const RateLimitCard = ({ staffId }) => {
  const { staffRateLimits } = useRateLimit(staffId);
  const {
    handeDeleteButton,
    handleCancelButton,
    handleEditButton,
    handleSaveButton,
    inputValues,
    isDisabled,
    isEditMode,
    setInputValues,
  } = useForm(staffId);

  const renderRateLimitValue = (key) => {
    if (!isEditMode) {
      const value = staffRateLimits[`rateLimit${key}`];
      return value ? `$${value.toFixed(2)}` : '-';
    }

    return (
      <div
        style={{
          display: 'flex',
          gap: 10,
          alignItems: 'center',
        }}
      >
        <InputNumber
          min={1}
          formatter={(value) => `$ ${value}`.replace(/\D/g, '').replace(/^/, '$ ')} // Keep $ and numbers
          parser={(value) => value.replace(/\$\s?|[^0-9]/g, '')} // Remove $ and non-numeric characters during parsing
          onChange={(inputValue) => {
            setInputValues({
              ...inputValues,
              [key]: inputValue,
            });
          }}
          value={inputValues[key]}
          size="large"
          style={{ width: '120px' }}
        />
        {staffRateLimits[`rateLimit${key}`] !== null && (
          <button type="button" className="btn btn-danger" onClick={() => handeDeleteButton(key)}>
            Remove Limit
          </button>
        )}
      </div>
    );
  };

  return (
    <div className="card" style={{ height: 380 }}>
      <div
        className="card-body"
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignContent: 'center',
        }}
      >
        <h3 className="card-title" style={{ textAlign: 'center' }}>
          Rate Limit
        </h3>
        <div
          style={{
            marginTop: 20,
            marginLeft: 16,
            display: 'flex',
            flexDirection: 'column',
            gap: 12,
          }}
        >
          {[UserProfession.RDH, UserProfession.DA, UserProfession.DN, UserProfession.FD].map(
            (key) => (
              <div
                key={key}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 12,
                  height: 40,
                }}
              >
                <b style={{ width: 130 }}>Rate limit for {key}:</b>
                {renderRateLimitValue(key)}
              </div>
            ),
          )}
        </div>

        <div
          style={{
            marginTop: 32,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {!isEditMode ? (
            <div
              style={{
                display: 'flex',
                gap: 20,
              }}
            >
              <button
                type="button"
                className="btn btn-info"
                style={{ width: '100px' }}
                onClick={handleEditButton}
              >
                Edit
              </button>
            </div>
          ) : (
            <div
              style={{
                display: 'flex',
                gap: 20,
              }}
            >
              <button
                type="button"
                className="btn btn-secondary"
                style={{
                  width: '100px',
                }}
                onClick={handleCancelButton}
              >
                Cancel
              </button>

              <button
                type="button"
                className="btn btn-success"
                style={{
                  width: '100px',
                }}
                onClick={handleSaveButton}
                disabled={isDisabled}
              >
                Save
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
