import { useSelector } from 'react-redux';

export function useW9() {
  const professional = useSelector((state) => state.users.currentUser);
  const professionalStripeId = useSelector((state) => state.users.currentUserStripeId);

  const w9Status = professional?.professional_info?.contractor?.w9?.status ?? null;
  const hasProfessionalStripeId = professionalStripeId?.length > 0;

  return {
    showStripeButton: w9Status !== 'new' && w9Status !== null && hasProfessionalStripeId,
    stripeUrl: hasProfessionalStripeId
      ? `https://dashboard.stripe.com/connect/accounts/${professionalStripeId}/activity`
      : null,
  };
}
