import { useSelector } from 'react-redux';
import StatePicker from '../../commonComponents/StatePicker';
import { LICENSE_FILTERS } from './utils';

export const CustomHeader = ({ filter, handleFilterChange }) => {
  const allProfessions = useSelector((state) => state.jobs.allProfessions);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 20,
        marginTop: 32,
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          width: '100%',
          gap: 20,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              minWidth: 140,
            }}
          >
            <p>State:</p>

            <StatePicker
              style={{
                height: 32,
              }}
              value={filter.state}
              didSelect={(state) => handleFilterChange('state', state)}
            />
          </div>
        </div>
        {LICENSE_FILTERS({ allProfessions }).map(({ key, label, options }) => (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              minWidth: 140,
            }}
          >
            <p>{label}:</p>

            <select
              className="custom-select custom-select-lg"
              style={{ height: 32 }}
              value={filter[key]}
              onChange={(val) => {
                handleFilterChange(key, val.target.value !== 'all' ? val.target.value : '');
              }}
            >
              {options.map((option) => (
                <option key={option.code} value={option.code}>
                  {option.name}
                </option>
              ))}
            </select>
          </div>
        ))}
      </div>
    </div>
  );
};
