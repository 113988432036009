import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOfficeAdminsList } from '../../../../../actions/dsosActions';
import { createLoadingSelector } from '../../../../../apis/selectors';

const loadingSelector = createLoadingSelector(['FETCH_DSO_OFFICE_ADMINS_LIST']);

export const useOfficeAdminList = () => {
  const dispatch = useDispatch();

  const { filters, data, total } = useSelector((state) => state.dsos.dsoOfficeAdminsList);
  const isLoading = useSelector((state) => loadingSelector(state));

  const handleFilterChanged = (type, value) => {
    dispatch(fetchOfficeAdminsList({ ...filters, [type]: value, page: 1 }));
  };

  const handlePageChanged = useCallback(
    (page) => {
      dispatch(fetchOfficeAdminsList({ ...filters, page }, false));
    },
    [dispatch, filters],
  );

  useEffect(() => {
    dispatch(fetchOfficeAdminsList({ page: 1 }, false));
  }, [dispatch]);

  return {
    data,
    filters,
    isLoading,
    total,
    totalPages: Math.ceil(total / filters.limit),
    handleFilterChanged,
    handlePageChanged,
  };
};
