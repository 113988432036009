import { Colors } from '../../themes/colors';

export const TabViewContainer = ({
  children,
  className,
  style = {},
  title,
  TitleLeading = <div style={{ flex: 1 }} />,
  TitleTrailing = <div style={{ flex: 1 }} />,
  variant = 'default',
}) => {
  const getVariantStyle = (ui) => variants[variant][ui] || {};

  return (
    <div
      className={className}
      style={{
        display: 'flex',
        flexDirection: 'column',
        margin: 25,
        minWidth: 800,
        paddingBottom: 40,
        paddingInline: 40,
        width: '100%',
        ...(getVariantStyle('container') || {}),
        ...style,
      }}
    >
      <article
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
        }}
      >
        {TitleLeading}
        <h1
          style={{
            color: Colors.neutral_600,
            fontSize: 24,
            fontWeight: 600,
            textAlign: 'center',
            ...(getVariantStyle('title') || {}),
          }}
        >
          {title}
        </h1>
        {TitleTrailing}
      </article>
      <>{children}</>
    </div>
  );
};

const variants = {
  card: {
    container: {
      backgroundColor: Colors.white,
      boxShadow: '0 1px 3px rgba(0, 0, 0, 0.2)',
    },
    title: {
      marginTop: 34,
      marginBottom: 30,
    },
  },
  default: {
    container: {},
    title: {
      marginTop: 44,
      marginBottom: 38,
    },
  },
};
