import tempMee from '../apis/tempMee';
import history from '../history';
import { removeFalsyValues } from '../utils';
import { isObjectEqual } from '../utils/Object';
import { DSOS_ACTIONS, SHOW_MESSAGE } from './actionTypes';
import { getAuthHeaders } from './authActions';

export const fetchDsosList =
  (filters, isInfiniteScroll = false) =>
  async (dispatch, getState) => {
    try {
      const { data, filters: stateFilters, isActiveRequest, isFinal } = getState().dsos.dsosList;
      const newFilterParams = { ...stateFilters, ...filters };

      if (
        isFinal ||
        isActiveRequest ||
        (data.length > 0 && isObjectEqual(stateFilters, newFilterParams))
      ) {
        return;
      }

      dispatch({ type: DSOS_ACTIONS.FETCH_DSOS_LIST_REQUEST, payload: newFilterParams });
      const headers = await getAuthHeaders();
      const response = await tempMee.get('/office-service/admin/dsos', {
        params: {
          page: filters.page || stateFilters.page,
          limit: filters.limit || stateFilters.limit,
          ...removeFalsyValues(newFilterParams),
        },
        headers,
      });
      dispatch({
        type: DSOS_ACTIONS.FETCH_DSOS_LIST_SUCCESS,
        payload: { ...response.data, page: filters.page, isInfiniteScroll },
      });
    } catch (err) {
      dispatch({ type: DSOS_ACTIONS.FETCH_DSOS_LIST_FAILURE, payload: err });
    }
  };

export const fetchDsoById = (dsoId) => async (dispatch) => {
  try {
    dispatch({ type: DSOS_ACTIONS.FETCH_DSO_BY_ID_REQUEST });
    const headers = await getAuthHeaders();
    const response = await tempMee.get(`/office-service/admin/dsos/${dsoId}`, {
      headers,
    });
    dispatch({
      type: DSOS_ACTIONS.FETCH_DSO_BY_ID_SUCCESS,
      payload: response.data,
    });
  } catch (err) {
    dispatch({ type: DSOS_ACTIONS.FETCH_DSO_BY_ID_FAILURE, payload: err });
  }
};

export const fetchDsoGroupList =
  (dsoId, filters = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: DSOS_ACTIONS.FETCH_DSO_GROUP_LIST_REQUEST,
      payload: { dsoId, page: filters.page },
    });
    try {
      const { page, limit } = getState().dsos.dsoGroupList.filters;
      const headers = await getAuthHeaders();
      const response = await tempMee.get(`/office-service/admin/dsos/${dsoId}/groups`, {
        params: {
          page: filters.page || page,
          limit: filters.limit || limit,
          ...removeFalsyValues(filters),
        },
        headers,
      });
      dispatch({
        type: DSOS_ACTIONS.FETCH_DSO_GROUP_LIST_SUCCESS,
        payload: response.data,
      });
    } catch (err) {
      dispatch({ type: DSOS_ACTIONS.FETCH_DSO_GROUP_LIST_FAILURE, payload: err });
    }
  };

export const addNewDso = (name) => async (dispatch) => {
  dispatch({ type: DSOS_ACTIONS.ADD_DSO_REQUEST });
  try {
    const headers = await getAuthHeaders();
    const response = await tempMee.post(`/office-service/admin/dsos`, { name }, { headers });

    history.push(`/dsos/${response.data.id}`, {
      id: response.data.id,
      name: response.data.name,
    });

    dispatch({
      type: DSOS_ACTIONS.ADD_DSO_SUCCESS,
      payload: response.data,
    });

    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        message: 'The DSO Group was created successfully.',
      },
    });
  } catch (err) {
    dispatch({ type: DSOS_ACTIONS.ADD_DSO_FAILURE, payload: err });
  }
};

export const addNewGroupToDso = (dsoID, name) => async (dispatch) => {
  dispatch({ type: DSOS_ACTIONS.ADD_GROUP_TO_DSO_REQUEST });
  try {
    const headers = await getAuthHeaders();
    const response = await tempMee.post(
      `/office-service/admin/dsos/${dsoID}/groups`,
      { name },
      {
        headers,
      },
    );
    dispatch({
      type: DSOS_ACTIONS.ADD_GROUP_TO_DSO_SUCCESS,
      payload: response.data,
    });
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        message: 'The group was created successfully.',
      },
    });
  } catch (err) {
    dispatch({ type: DSOS_ACTIONS.ADD_GROUP_TO_DSO_FAILURE, payload: err });
  }
};

export const addNewAdminToDso = (dsoId, formValues) => async (dispatch, getState) => {
  dispatch({ type: DSOS_ACTIONS.ADD_DSO_OFFICE_ADMIN_REQUEST });

  try {
    const headers = await getAuthHeaders();
    const { access_token: accessToken } = getState().auth;
    const response = await tempMee.post(
      `/auth-service/admin/office-staffs`,
      {
        dsoID: dsoId,
        email: formValues.email,
        lastName: formValues.lastName,
        firstName: formValues.firstName,
      },
      {
        headers: {
          ...headers,
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );

    dispatch({
      type: DSOS_ACTIONS.ADD_DSO_OFFICE_ADMIN_SUCCESS,
      payload: response.data,
    });

    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        message: 'The DSO Admin was created successfully.',
      },
    });

    dispatch(fetchOfficeAdminsList({ dsoId }));
  } catch (e) {
    dispatch({ type: DSOS_ACTIONS.ADD_DSO_OFFICE_ADMIN_FAILURE, payload: e });
  }
};

export const editDsoGroupName = (dsoId, groupId, name) => async (dispatch) => {
  dispatch({ type: DSOS_ACTIONS.EDIT_DSO_GROUP_NAME_REQUEST });
  try {
    const headers = await getAuthHeaders();
    const response = await tempMee.patch(
      `/office-service/admin/dsos/${dsoId}/groups/${groupId}`,
      { name },
      { headers },
    );
    dispatch({
      type: DSOS_ACTIONS.EDIT_DSO_GROUP_NAME_SUCCESS,
      payload: response.data,
    });
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        message: 'The group name was edited successfully.',
      },
    });
  } catch (err) {
    dispatch({ type: DSOS_ACTIONS.EDIT_DSO_GROUP_NAME_FAILURE, payload: err });
  }
};

export const fetchDsoRegionList =
  (dsoId, filters = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: DSOS_ACTIONS.FETCH_DSO_REGION_LIST_REQUEST,
      payload: { dsoId, page: filters.page },
    });
    try {
      const { page, limit } = getState().dsos.dsoRegionList.filters;
      const headers = await getAuthHeaders();
      const response = await tempMee.get(`/office-service/admin/dsos/${dsoId}/regions`, {
        params: {
          page: filters.page || page,
          limit: filters.limit || limit,
          ...removeFalsyValues(filters),
        },
        headers,
      });
      dispatch({
        type: DSOS_ACTIONS.FETCH_DSO_REGION_LIST_SUCCESS,
        payload: response.data,
      });
    } catch (err) {
      dispatch({ type: DSOS_ACTIONS.FETCH_DSO_REGION_LIST_FAILURE, payload: err });
    }
  };

export const addNewRegionToDso = (dsoID, name) => async (dispatch) => {
  dispatch({ type: DSOS_ACTIONS.ADD_REGION_TO_DSO_REQUEST });
  try {
    const headers = await getAuthHeaders();
    const response = await tempMee.post(
      `/office-service/admin/dsos/${dsoID}/regions`,
      { name },
      {
        headers,
      },
    );
    dispatch({
      type: DSOS_ACTIONS.ADD_REGION_TO_DSO_SUCCESS,
      payload: response.data,
    });
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        message: 'The region was created successfully.',
      },
    });
  } catch (err) {
    dispatch({ type: DSOS_ACTIONS.ADD_REGION_TO_DSO_FAILURE, payload: err });
  }
};

export const editDsoRegionName = (dsoId, regionId, name) => async (dispatch) => {
  dispatch({ type: DSOS_ACTIONS.EDIT_DSO_REGION_NAME_REQUEST });
  try {
    const headers = await getAuthHeaders();
    const response = await tempMee.patch(
      `/office-service/admin/dsos/${dsoId}/regions/${regionId}`,
      { name },
      { headers },
    );
    dispatch({
      type: DSOS_ACTIONS.EDIT_DSO_REGION_NAME_SUCCESS,
      payload: response.data,
    });
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        message: 'The region name was edited successfully.',
      },
    });
  } catch (err) {
    dispatch({ type: DSOS_ACTIONS.EDIT_DSO_REGION_NAME_FAILURE, payload: err });
  }
};

export const fetchOfficeAdminsList =
  (filters = {}, isInfiniteScroll = true) =>
  async (dispatch, getState) => {
    dispatch({
      type: DSOS_ACTIONS.FETCH_DSO_OFFICE_ADMINS_LIST_REQUEST,
      payload: {
        dsoId: filters.dsoId,
        page: filters.page,
        staffEmail: filters.staffEmail,
        staffName: filters.staffName,
      },
    });
    try {
      const { page, limit } = getState().dsos.dsoOfficeAdminsList.filters;
      const headers = await getAuthHeaders();
      const { access_token: accessToken } = getState().auth;
      const response = await tempMee.get(`/auth-service/admin/office-staffs`, {
        params: {
          dsoID: filters.dsoId,
          limit: filters.limit || limit,
          page: filters.page || page,
          staffEmail: filters.staffEmail,
          staffName: filters.staffName,
        },
        headers: {
          ...headers,
          Authorization: `Bearer ${accessToken}`,
        },
      });
      dispatch({
        type: DSOS_ACTIONS.FETCH_DSO_OFFICE_ADMINS_LIST_SUCCESS,
        payload: {
          data: response.data,
          isInfiniteScroll,
        },
      });
    } catch (err) {
      dispatch({ type: DSOS_ACTIONS.FETCH_DSO_OFFICE_ADMINS_LIST_FAILURE, payload: err });
    }
  };

export const fetchDsoRegionOffices =
  (entity, entityId, filter = { page: 1, limit: 15 }) =>
  async (dispatch, getState) => {
    dispatch({
      type: DSOS_ACTIONS.FETCH_DSO_REGION_OFFICE_LIST_REQUEST,
      payload: {
        entity,
        entityId,
        limit: filter.limit || 15,
        page: filter.page || 1,
      },
    });

    try {
      const { entity, entityId, limit, page } = getState().dsos.dsoRegionOfficeList.filters;

      const headers = await getAuthHeaders();

      const response = await tempMee.get(`/office-service/admin/offices`, {
        params: {
          entityID: entityId,
          entityType: entity,
          limit,
          page,
        },
        headers,
      });

      dispatch({
        type: DSOS_ACTIONS.FETCH_DSO_REGION_OFFICE_LIST_SUCCESS,
        payload: response.data,
      });
    } catch (e) {
      dispatch({ type: DSOS_ACTIONS.FETCH_DSO_REGION_OFFICE_LIST_FAILURE, payload: e });
    }
  };

export const fetchDsoGroupOffices =
  (entity, entityId, filter = { page: 1, limit: 15 }) =>
  async (dispatch, getState) => {
    dispatch({
      type: DSOS_ACTIONS.FETCH_DSO_GROUP_OFFICE_LIST_REQUEST,
      payload: {
        entity,
        entityId,
        limit: filter.limit || 15,
        page: filter.page || 1,
      },
    });

    try {
      const { entity, entityId, limit, page } = getState().dsos.dsoGroupOfficeList.filters;

      const headers = await getAuthHeaders();

      const response = await tempMee.get(`/office-service/admin/offices`, {
        params: {
          entityID: entityId,
          entityType: entity,
          limit,
          page,
        },
        headers,
      });

      dispatch({
        type: DSOS_ACTIONS.FETCH_DSO_GROUP_OFFICE_LIST_SUCCESS,
        payload: response.data,
      });
    } catch (e) {
      dispatch({ type: DSOS_ACTIONS.FETCH_DSO_GROUP_OFFICE_LIST_FAILURE, payload: e });
    }
  };

export const clearDsoRegionOffices = () => (dispatch) => {
  dispatch({ type: DSOS_ACTIONS.CLEAR_DSO_REGION_OFFICE_LIST });
};

export const clearDsoGroupOffices = () => (dispatch) => {
  dispatch({ type: DSOS_ACTIONS.CLEAR_DSO_GROUP_OFFICE_LIST });
};

export const clearDsoOffices = () => (dispatch) => {
  dispatch({ type: DSOS_ACTIONS.CLEAR_DSO_OFFICE_LIST });
};

export const getOfficeById = (officeId) => async (dispatch) => {
  dispatch({
    type: DSOS_ACTIONS.GET_OFFICE_BY_ID_REQUEST,
  });

  try {
    const headers = await getAuthHeaders();

    const response = await tempMee.get(`/office-service/admin/offices/${officeId}`, {
      headers,
    });

    dispatch({
      type: DSOS_ACTIONS.GET_OFFICE_BY_ID_SUCCESS,
      payload: response.data,
    });
  } catch (e) {
    dispatch({ type: DSOS_ACTIONS.GET_OFFICE_BY_ID_FAILURE, payload: e });
  }
};

export const assignOfficesToDso = (dsoId, officesIds) => async (dispatch) => {
  dispatch({
    type: DSOS_ACTIONS.ASSIGN_OFFICES_TO_DSO_REQUEST,
  });

  try {
    const headers = await getAuthHeaders();

    await tempMee.put(
      `/office-service/admin/dsos/${dsoId}/offices`,
      {
        officeIDs: officesIds,
      },
      {
        headers,
      },
    );

    dispatch({
      type: DSOS_ACTIONS.ASSIGN_OFFICES_TO_DSO_SUCCESS,
      payload: { dsoId, officesIds },
    });
    dispatch({
      type: SHOW_MESSAGE,
      payload: { message: 'DSO Name was updated successfully.' },
    });
  } catch (e) {
    dispatch({ type: DSOS_ACTIONS.ASSIGN_OFFICES_TO_DSO_FAILURE, payload: e });
  }
};

export const fetchDsoOffices =
  (entity, entityId, filter = { page: 1, limit: 15 }) =>
  async (dispatch, getState) => {
    dispatch({
      type: DSOS_ACTIONS.FETCH_DSO_OFFICE_LIST_REQUEST,
      payload: {
        entity,
        dsoId: entityId,
        limit: filter.limit || 15,
        page: filter.page || 1,
      },
    });

    try {
      const { dsoId, filters: {entity: entityType, limit, page} } = getState().dsos.dsoOfficeList;

      const headers = await getAuthHeaders();

      const response = await tempMee.get(`/office-service/admin/offices`, {
        params: {
          ...removeFalsyValues(filter),
          entityID: dsoId,
          entityType,
          limit,
          page,
        },
        headers,
      });

      dispatch({
        type: DSOS_ACTIONS.FETCH_DSO_OFFICE_LIST_SUCCESS,
        payload: response.data,
      });
    } catch (e) {
      dispatch({ type: DSOS_ACTIONS.FETCH_DSO_OFFICE_LIST_FAILURE, payload: e });
    }
  };


export const addOfficesToRegion = ({ regionId, officesIds }) =>
  async (dispatch) => {
    dispatch({ type: DSOS_ACTIONS.ADD_OFFICES_TO_REGION_REQUEST });

    try {
      const headers = await getAuthHeaders();
      await tempMee.put(
        `/office-service/admin/regions/${regionId}/offices`,
        { OfficeIDs: officesIds },
        { headers },
      );

      dispatch({
        type: DSOS_ACTIONS.ADD_OFFICES_TO_REGION_SUCCESS,
        payload: { regionId, officesIds },
      });

      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          message: `The ${officesIds.length > 1 ? 'offices were' : 'office was'} added to the region successfully.`,
        },
      });
    } catch (e) {
      dispatch({ type: DSOS_ACTIONS.ADD_OFFICES_TO_REGION_FAILURE, payload: e });
    }
  };