import { Colors } from '../../../../../themes/colors';
import { AddNewButton } from './AddNewButton';

export const Header = ({ action, label, total = 0 }) => (
  <div
    style={{
      alignItems: 'end',
      display: 'flex',
      flexDirection: 'column',
      gap: 12,
    }}
  >
    <AddNewButton action={action} label={label} />
    <div>
      <b
        style={{
          fontSize: 20,
          color: Colors.neutral_600,
        }}
      >
        Total | {total}
      </b>
    </div>
  </div>
);
