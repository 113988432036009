import history from '../../../../history';
import Styles from '../../../../themes/style.module.scss';
import { Table as TableComponent } from '../../../commonComponents/Table/Table';
import { style } from '../../../commonComponents/user-column';
import { TableHeader } from './components';
import { useOfficeAdminList } from './hooks/useOfficeAdminList';

export const OfficeAdminList = () => {
  const { data, filters, isLoading, total, totalPages, handleFilterChanged, handlePageChanged } =
    useOfficeAdminList();

  return (
    <div
      style={{
        display: 'flex',
        height: '100vh',
        overflowY: 'auto',
        flexDirection: 'column',
        position: 'relative',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          paddingTop: 8,
          alignItems: 'center',
          justifyContent: 'flex-end',
          width: '100%',
          position: 'absolute',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <h3 className={Styles.h3} style={{ fontSize: '24px', textAlign: 'center' }}>
            Office Admins
          </h3>
        </div>
      </div>
      <div style={{ width: '90%', paddingTop: 100 }}>
        <TableHeader filters={filters} handleFilterChanged={handleFilterChanged} />
        <TableComponent
          filter={filters}
          columns={[
            {
              id: 'firstName',
              selector: ({ firstName }) => firstName,
              name: 'First Name',
              style: style.tableText,
            },
            {
              id: 'lastName',
              selector: ({ lastName }) => lastName,
              name: 'Last Name',
              style: style.tableText,
            },
            {
              id: 'email',
              selector: ({ email }) => email,
              name: 'Email',
              style: style.tableText,
            },
            {
              id: 'dsoManager',
              selector: ({ dsoId }) => dsoId || '-',
              name: 'DSO Manager',
              style: style.tableText,
            },
          ]}
          initialPage={filters.page}
          data={data}
          forceLoading={isLoading}
          noDataComponent="There are no Office Admins"
          isLoading={isLoading}
          totalPages={totalPages}
          paginationPerPage={filters.limit}
          paginationTotalRows={total}
          onPageChange={handlePageChanged}
          onRowClicked={(it) => history.push(`/staff/${it.id}`)}
        />
      </div>
    </div>
  );
};
