import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearDsoOffices, fetchDsoRegionList } from '../../../../../../../actions/dsosActions';
import { createLoadingSelector } from '../../../../../../../apis/selectors';

const actions = ['FETCH_DSO_REGION_LIST'];
const loadingSelector = createLoadingSelector(actions);

export const useDsoRegionList = (dsoId) => {
  const dispatch = useDispatch();
  const { filters, data, total, isActiveRequest, isFinalPage, dsoId: stateDsoId } = useSelector(
    (state) => state.dsos.dsoRegionList,
  );
  const totalPages = Math.ceil(total / filters.limit);
  const isLoading = useSelector((state) => loadingSelector(state));

  const handleScrollList = useCallback(
    (event) => {
      const tableScrollHeight = event?.target?.scrollHeight - event?.target?.scrollTop - 20;
      const clientHeight = event?.target?.clientHeight;
      if (tableScrollHeight <= clientHeight && !isActiveRequest && !isFinalPage) {
        dispatch(fetchDsoRegionList(dsoId, { page: filters.page + 1 }));
      }
    },
    [dispatch, filters.page, isActiveRequest, isFinalPage, dsoId],
  );

  useEffect(() => {
    dispatch(clearDsoOffices());
    dispatch(fetchDsoRegionList(dsoId));
  }, [dispatch, dsoId]);

  return {
    data: stateDsoId === dsoId ? data : [],
    total,
    totalPages,
    limit: filters.limit,
    isInitialLoading: !data.length && isLoading,
    isLoading: isActiveRequest,
    handleScrollList,
  };
};
