import { GOOGLE_PLACE_FETCH } from '../actions/actionTypes';

const defaultState = {
  places: [],
};

export default (state = defaultState, action) => {
  switch (action.type) {
  case GOOGLE_PLACE_FETCH:
    return state;
  default:
    return state;
  }
};
