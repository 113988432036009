import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { addNewDso } from '../../../../../actions/dsosActions';
import CustomPortal from '../../../../commonComponents/CustomPortal';
import { FormControl } from '../../../DsoGroupDetails/components/FormControl';

export const AddNewDso = () => {
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [showModal, setShowModal] = useState(false);

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleSubmit = () => {
    dispatch(addNewDso(name));
    setShowModal(false);
    setName('');
  };

  return (
    <>
      <button
        className="btn btn-info"
        onClick={() => setShowModal(true)}
        type="button"
      >
        Add new DSO
      </button>

      {showModal && (
        <CustomPortal
          title="Add New DSO"
          textStyle={{ textAlign: 'center' }}
          cancelText="Close"
          submitText="Create DSO"
          submitPressed={handleSubmit}
          cancelPressed={() => {
            setShowModal(false)
            setName('')
          }}
          rightButtonDisabled={!name}
        >
          <FormControl label="DSO Name:" value={name} onChange={handleNameChange} />
        </CustomPortal>
      )}
    </>
  );
};