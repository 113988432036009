export const toggleTabState = (currentTab = 'general') => ({
  generalTab: currentTab === 'general',
  licenses: currentTab === 'licenses',
  skills: currentTab === 'skills',
  paymentTab: currentTab === 'payment',
  invoicesTab: currentTab === 'invoices',
  jobsTab: currentTab === 'jobs',
  offersTab: currentTab === 'offers',
  editsTab: currentTab === 'edits',
  receiptPDFJobsTab: currentTab === 'receiptPDFJobsTab',
  officeReciptsTab: currentTab === 'officeReciptsTab',
  blockedTab: currentTab === 'blocked',
  npsTab: currentTab === 'nps',
  notesTab: currentTab === 'notes',
  childOfficesTab: currentTab === 'childOffices',
  ppJobsTab: currentTab === 'ppJobs',
  notificationsTab: currentTab === 'notifications',
  favoritesTab: currentTab === 'favorites',
  workerClassificationTab: currentTab === 'workerClassification',
  highlyRatedTab: currentTab === 'highlyRated',
  reviewTab: currentTab === 'reviewTab',
  mapTab: currentTab === 'mapTab',
  medMalTab: currentTab === 'medMalTab',
  badgesTab: currentTab === 'badges',
});
