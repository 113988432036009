import React from 'react';
import moment from 'moment';

export default function MedMalInsuranceCarrier({ data }) {
  if (!data || !data?.length) {
    return '--';
  }

  return (
    <div>
      {data.map((insuranceCarrier, index) => (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'wrap',
            borderBottom: index !== data.length - 1 ? '1px solid #ededed' : 'none',
            flex: 1,
            marginBottom: 10,
            height: 180,
          }}
        >
          <p style={{ width: '50%' }}>
            <strong>Insurance Carrier Name: </strong>
            <div>{insuranceCarrier?.insuranceName || '--'}</div>
          </p>
          <p style={{ width: '50%' }}>
            <strong>Effective Date: </strong>
            <div>
              {insuranceCarrier?.effectiveDate
                ? moment(insuranceCarrier?.effectiveDate).format('MM/DD/YYYY')
                : '--'}
            </div>
          </p>
          <p style={{ width: '50%' }}>
            <strong>Expiration Date: </strong>
            <div>
              {insuranceCarrier?.expirationDate
                ? moment(insuranceCarrier?.expirationDate).format('MM/DD/YYYY')
                : '--'}
            </div>
          </p>
          <p style={{ width: '50%' }}>
            <strong>No of Claims-made: </strong>
            <div>{insuranceCarrier?.numberOfClaims || '--'}</div>
          </p>
          <p style={{ width: '50%' }}>
            <strong>Liability Limit: </strong>
            <div>
              {insuranceCarrier?.liabilityLimit
                ? `$${new Intl.NumberFormat('en-US').format(insuranceCarrier.liabilityLimit)}`
                : '--'}
            </div>
          </p>
        </div>
      ))}
    </div>
  );
}
