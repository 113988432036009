import Spinner from 'react-bootstrap/Spinner';
import { useParams } from 'react-router-dom';
import { Checkbox } from '../../../../../commonComponents/Checkbox';
import { ENTITY_TYPES } from '../../../enums';
import { LineInfo } from '../../LineInfo';
import { SectionHeader } from '../components/SectionHeader';
import { InfoGrid } from './components/InfoGrid';
import { RateLimitCard } from './components/RateLimitCard/RateLimitCard';
import { useDsoExecutive, useGeneralTab } from './hooks';
import { AssignDso } from './components/AssignDso/AssignDso';
import { useAssignToDso } from './hooks/useAssignToDso';

export const GeneralTab = () => {
  const params = useParams();
  const staffId = params?.id;
  const { isLoading, dataGroup, dataRegion, staffDetails, dsoGroupDetails, officeAccesses } =
    useGeneralTab(staffId);
  const { isDsoExecutive, toggleDsoExecutive } = useDsoExecutive(staffId);
  const { handleAssignDso } = useAssignToDso();

  const isChecked = (id) => officeAccesses?.some((item) => item.entityID === id);

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: '2fr 1.2fr',
        columnGap: 20,
        width: '80%',
        justifyContent: 'center',
        marginTop: 12,
      }}
    >
      <div className="card" style={{ display: 'flex', paddingBottom: 80 }}>
        <div style={{ margin: '0 80px' }}>
          {isLoading ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: 400,
              }}
            >
              <Spinner
                animation="border"
                role="status"
                variant="info"
                style={{
                  height: 40,
                  width: 40,
                }}
              />
            </div>
          ) : (
            <div style={{ width: '100%', marginTop: 20 }}>
              <SectionHeader title="General Info" />
              <LineInfo title="First Name" text={staffDetails.firstName} />
              <LineInfo title="Last Name" text={staffDetails.lastName} />
              <LineInfo title="Email" text={staffDetails.email} />

              {dataGroup.length !== 0 && dataRegion.length !== 0 && (
                <SectionHeader title="Admin Associations" />
              )}
              <Checkbox
                label="DSO Executive"
                checked={isDsoExecutive}
                textStyle={{ color: 'black', flex: '0 0 auto' }}
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'row-reverse',
                  gap: 8,
                  justifyContent: 'start',
                  marginLeft: 20,
                  marginTop: 16,
                }}
                onChanged={toggleDsoExecutive}
              />
              {dataRegion.length !== 0 && (
                <InfoGrid
                  title="Region(s)"
                  items={dataRegion}
                  isChecked={isChecked}
                  entityType={ENTITY_TYPES.REGION}
                />
              )}
              {dataGroup.length !== 0 && (
                <InfoGrid
                  title="Groups"
                  items={dataGroup}
                  isChecked={isChecked}
                  entityType={ENTITY_TYPES.GROUP}
                />
              )}
            </div>
          )}
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 16,
        }}
      >
        <AssignDso
          value={{
            id: dsoGroupDetails?.id,
            label: dsoGroupDetails?.name,
          }}
          onSave={handleAssignDso}
        />
        <RateLimitCard staffId={staffId} />
      </div>
    </div>
  );
};
