import React from 'react';

/**
 * Gives a button that looks like a link
 *
 * @param {function} onClicked
 * @param children
 * @returns {React.JSX.Element}
 */
export function InlineLinkButton({ onClicked, children }) {
  return (
    <span>
      <button
        type="button"
        onClick={onClicked}
        style={{
          appearance: 'none',
          display: 'inline-block',
          border: 'none',
          margin: 0,
          padding: '10px',
          backgroundColor: 'transparent',
          textAlign: 'start',
          textDecoration: 'underline',
        }}
      >
        {children}
      </button>
    </span>
  );
}
