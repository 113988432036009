import { useState } from 'react';
import { Colors } from '../../../../../themes/colors';

export const ListItem = ({ label, TrailingComponent }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        fontSize: 16,
        padding: '0 20px',
        gap: 12,
        height: 48,
        backgroundColor: isHovered ? Colors.neutral_200 : 'transparent',
        transition: 'background-color 0.3s ease',
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <span
        style={{
          flex: 1,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          cursor: 'pointer',
        }}
      >
        {label}
      </span>

      {isHovered && TrailingComponent && <>{TrailingComponent}</>}
    </div>
  );
};
