import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteStaffRateLimit,
  fetchStaffRateLimit,
  saveStaffRateLimits,
} from '../../../../../../../../../actions/staffActions';
import { createLoadingSelector } from '../../../../../../../../../apis/selectors';
import { removeFalsyValues } from '../../../../../../../../../utils';

const actions = ['FETCH_RATE_LIMITS'];
const loadingSelector = createLoadingSelector(actions);

export const useRateLimit = (staffId) => {
  const dispatch = useDispatch();
  const staffRateLimits = useSelector((state) => state.staff.staffRateLimits);
  const isLoading = useSelector((state) => loadingSelector(state));

  const saveLimits = (values) => {
    const updatedRateLimits = removeFalsyValues(values);
    if (Object.keys(updatedRateLimits).length === 0) {
      return;
    }
    dispatch(saveStaffRateLimits(staffId, values));
  };

  const deleteLimit = (key) => {
    dispatch(deleteStaffRateLimit(staffId, key));
  };

  useEffect(() => {
    if (!isLoading && staffId) {
      dispatch(fetchStaffRateLimit(staffId));
    }
  }, [dispatch, staffId]);

  return {
    staffRateLimits,
    isLoading,
    saveLimits,
    deleteLimit,
  };
};
