import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDsoById } from '../../../../actions/dsosActions';
import { fetchStaffById } from '../../../../actions/staffActions';
import { createLoadingSelector } from '../../../../apis/selectors';
import { TABS } from '../enums';

const actions = ['FETCH_STAFF_BY_ID', 'FETCH_DSO_BY_ID'];
const loadingSelector = createLoadingSelector(actions);

export const useStaffDetails = (staffId) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.staff.staffDetails);
  const isLoading = useSelector((state) => loadingSelector(state));
  const [selectedTab, setSelectedTab] = useState(TABS.GENERAL);
  const dsoGroupDetails = useSelector((state) => state.dsos.dsoGroupDetails);

  const handleTabSelection = (tab) => {
    setSelectedTab(tab);
  };

  useEffect(() => {
    dispatch(fetchStaffById(staffId));
  }, [dispatch, staffId]);

  useEffect(() => {
    if (data?.dsoID && dsoGroupDetails?.id !== data?.dsoID) {
      dispatch(fetchDsoById(data?.dsoID));
    }
  }, [data, dispatch]);

  return {
    data,
    isLoading,
    selectedTab,
    handleTabSelection,
    dsoGroupDetails,
  };
};
