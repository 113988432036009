import React, { Component } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { connect } from 'react-redux';
import dateFormat from 'dateformat';
import Styles from '../../themes/style.module.scss';
import MobileAppModal from '../commonComponents/MobileAppModal';
import { fetchAllSMS, sendSMS, sendSMSforPhones } from '../../actions/smsAction';
import { createLoadingSelector, createErrorMessageSelector } from '../../apis/selectors';
import { getAuthHeaders } from '../../actions/authActions';
import tempMee from '../../apis/tempMee';

if (!String.prototype.format) {
  String.prototype.format = function () {
    const args = arguments;
    return this.replace(/{(\d+)}/g, (match, number) =>
      typeof args[number] !== 'undefined' ? args[number] : match,
    );
  };
}

class SMSCampaign extends Component {
  constructor(props) {
    super(props);
    this.state = {
      smsType: 'twilio',
      templateBody:
        'Hey {0} - This is TempMee. (:\nDownload the app or learn more here https://tempmee.com/hygienist/',
      phoneNameBody:
        props?.location && props.location.state?.PhonesAndNamesParam
          ? props.location.state.PhonesAndNamesParam
          : '(XXX) XXX-XXXX, USER NAME\n(XXX) XXX-XXXX, USER NAME 2',
      phoneNumbersArray: [],
      smsBodyArray: [],
      errorTextConfirmAction: '',
      initialLoaderViewIsEnabled: false,
      errorText: '',
    };

    this.smsScroll = React.createRef();
  }

  componentWillMount() {
    const { location, history } = this.props;
    if (location.state) {
      if (history.location.state.job_id !== undefined) {
        this.setState({ initialLoaderViewIsEnabled: true });
        this.getUserInRadius();
      }
      if (location.state.report) {
        this.setState({ phoneNameBody: location.state.report });
      }
    }
  }

  componentDidUpdate() {
    const overflow = this.smsScroll.current;
    if (overflow) {
      overflow.scrollTo(0, overflow.scrollHeight);
    }
  }

  submitSMS = () => {
    const { templateBody, phoneNameBody, smsType } = this.state;
    const { fetchAllSMS } = this.props;
    const arrayPhoneNames = phoneNameBody
      .split('\n')
      .map((row) => row.split(',').map((text) => text.trim()))
      .filter((row) => row[0].length !== 0);
    const phones = arrayPhoneNames.map((pair) => pair[0]);
    const bodies = arrayPhoneNames.map((pair) => templateBody.format(...pair.slice(1)));
    this.setState({
      smsBodyArray: bodies,
      allSMSCount: phones.length,
      phoneNumbersArray: phones,
    });

    fetchAllSMS(phones[0], smsType);
  };

  // all sms by one click
  sumbitSMSforPhones = () => {
    const { sendSMSforPhones } = this.props;
    const { templateBody, phoneNameBody, smsType } = this.state;
    const arrayPhoneNames = phoneNameBody
      .split('\n')
      .map((row) => row.split(',').map((text) => text.trim()))
      .filter((row) => row[0].length !== 0);
    const lengthOfItems = arrayPhoneNames.map((pair) => pair.length);
    const isConsistent = lengthOfItems.every((v) => v === lengthOfItems[0]);
    if (!isConsistent) {
      this.setState({ errorText: 'Please, check inputs.They are not in correct format' });
      return;
    }
    sendSMSforPhones({ arrayPhoneNames, templateBody, textline: smsType });
  };

  prepareTextForCounts() {
    return `${this.state.allSMSCount - this.state.phoneNumbersArray.length + 1} / ${
      this.state.allSMSCount
    } `;
  }

  handlePhoneNameChange = (event) => {
    this.setState({ phoneNameBody: event.target.value });
  };

  getUserInRadius = async () => {
    try {
      const headers = await getAuthHeaders();
      const response = await tempMee.get(
        `/admin/jobs/${this.props.history.location.state.job_id}/radius`,
        {
          params: {
            maxRadius: this.props.history.location.state.maxRadiusValue,
            minRadius: this.props.history.location.state.minRadiusValue || 0,
          },
          headers,
        },
      );
      const arr = response.data.map((user) =>
        user.distance
          ? `${user.contact_number}, ${user.first_name}, ${user.distance?.toFixed(1)}`
          : `${user.contact_number}, ${user.first_name}`,
      );
      this.setState({ phoneNameBody: arr.join('\n') });
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ initialLoaderViewIsEnabled: false });
    }
  };

  handleTemplateChange = (event) => {
    this.setState({ templateBody: event.target.value });
  };

  callbackChangeText = (newText) => {
    const newArray = [newText, ...this.state.smsBodyArray.slice(1)];
    this.setState({ smsBodyArray: newArray });
  };

  confirmHasBeenClicked = async () => {
    const { phoneNumbersArray, smsBodyArray, smsType } = this.state;
    this.props.sendSMS({
      phone: phoneNumbersArray[0],
      body: smsBodyArray[0],
      textline: smsType,
    });
    this.skipHasBeenClicked();
  };

  skipHasBeenClicked = () => {
    const { fetchAllSMS } = this.props;
    const { phoneNumbersArray, smsBodyArray, smsType } = this.state;

    this.setState(
      {
        phoneNumbersArray: phoneNumbersArray.slice(1),
        smsBodyArray: smsBodyArray.slice(1),
        errorTextConfirmAction: '',
      },
      () => {
        if (this.state.phoneNumbersArray.length !== 0) {
          fetchAllSMS(this.state.phoneNumbersArray[0], smsType);
        }
      },
    );
  };

  cancelHasBeenClicked = () => {
    this.setState({
      phoneNumbersArray: [],
      smsBodyArray: [],
      errorTextConfirmAction: '',
    });
  };

  renderMessage() {
    const { reachable, allSMSforPhone } = this.props;

    const styleCellGray = {
      backgroundColor: reachable ? '#efefef' : 'red',
      textAlign: 'left',
      alignSelf: 'flex-start',
    };

    const styleCellBlue = {
      backgroundColor: reachable ? '#3EB0CA' : 'red',
      textAlign: 'right',
      alignSelf: 'flex-end',
    };

    return allSMSforPhone
      .sort((a, b) => a.created_at - b.created_at)
      .map((item) => {
        const date = dateFormat(new Date(item.created_at * 1000), 'ddd, mmmm dS, yyyy, h:MM:ss TT');

        const customStyle = item.creator.type === 'user' ? styleCellGray : styleCellBlue;
        return (
          <div
            style={{
              ...customStyle,
              marginBottom: 5,
              marginTop: 5,
              padding: 5,
              borderRadius: 5,
            }}
            key={item.uuid}
          >
            {item.body}
            <div style={{ fontSize: '12px', fontWeight: 'bold' }}>{date}</div>
          </div>
        );
      });
  }

  render() {
    const { customerNotes, smsCampaignAllowed, adminNotes, isLoading } = this.props;
    if (this.state.initialLoaderViewIsEnabled) {
      return (
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'center',
            flexGrow: 1,
            marginTop: 30,
          }}
        >
          <Spinner
            animation="border"
            role="status"
            variant="info"
            style={{
              height: 30,
              width: 30,
            }}
          />
        </div>
      );
    }
    return (
      <div
        style={{
          width: '100vw',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          height: '90vh',
          position: 'relative',
          overflowY: 'auto',
          flexGrow: 1,
          minWidth: 750,
        }}
      >
        <h1 style={{ textAlign: 'center' }}> SMS Campaign</h1>

        <select
          className="form-control"
          style={{ width: 300 }}
          value={this.state.smsType}
          onChange={(value) => this.setState({ smsType: value.target.value })}
        >
          <option value="twilio">Twilio</option>
          <option selected value="tempmee">
            TempMee
          </option>
          <option value="pp">Perminent Placement</option>
          <option value="notifications">Opt-In Notifications & Alerts</option>
        </select>
        <div
          style={{
            width: '100%',
            height: '70%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              width: '40%',
              flexDirection: 'column',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <h2 className={Styles.h3} style={{ textAlign: 'center' }}>
              Template
            </h2>
            <textarea
              name="Text1"
              style={{ width: '100%', height: '90%', marginRight: '12px' }}
              value={this.state.templateBody}
              onChange={this.handleTemplateChange}
            />
          </div>
          <div
            style={{
              width: '40%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <h2 className={Styles.h3} style={{ textAlign: 'center' }}>
              Phones and names
            </h2>

            <textarea
              name="Text2"
              style={{ width: '100%', height: '90%', marginLeft: '12px' }}
              value={this.state.phoneNameBody}
              onChange={this.handlePhoneNameChange}
            />
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginTop: 20,
            marginBottom: 20,
          }}
        >
          <button
            className="btn btn-success"
            onClick={this.submitSMS}
            type="button"
            style={{
              width: '200px',
              height: '50px',
              fontSize: 16,
              borderRadius: 25,
              marginRight: 10,
            }}
          >
            Send SMS
          </button>
          <button
            className="btn btn-success"
            onClick={this.sumbitSMSforPhones}
            type="button"
            style={{
              width: '200px',
              height: '50px',
              fontSize: 16,
              borderRadius: 25,
              marginRight: 10,
            }}
          >
            Send All
          </button>
        </div>
        {this.state.errorText && <div style={{ color: 'red' }}>{this.state.errorText}</div>}
        <MobileAppModal
          smsPhone={this.state.phoneNumbersArray[0]}
          smsText={this.state.smsBodyArray[0]}
          customerNotesText={customerNotes}
          errorText={this.state.errorTextConfirmAction}
          showLoader={isLoading}
          adminNotes={adminNotes}
          countText={this.prepareTextForCounts()}
          callbackSubmit={this.confirmHasBeenClicked}
          callbackSkip={this.skipHasBeenClicked}
          callbackChangeText={this.callbackChangeText}
          callbackCancel={this.cancelHasBeenClicked}
          show={this.state.phoneNumbersArray.length !== 0}
          content="confirmSMS"
        >
          <h4 style={{ textAlign: 'center' }}>Previous messages:</h4>
          <div
            className="overflow-auto"
            style={{ alignItems: 'center', width: '100%', padding: 30 }}
            ref={this.smsScroll}
          >
            {isLoading ? (
              <div
                style={{
                  display: 'flex',
                  height: '300px',
                  alignContent: 'center',
                  width: '100%',
                  justifyContent: 'center',
                }}
              >
                <Spinner
                  animation="border"
                  role="status"
                  variant="info"
                  style={{
                    marginTop: 25,
                    height: 30,
                    width: 30,
                    marginBottom: 20,
                  }}
                />
              </div>
            ) : (
              <div
                style={{
                  display: 'flex',
                  alignContent: 'center',
                  height: '300px',
                  width: '100%',
                  marginTop: 20,
                  flexDirection: 'column',
                }}
              >
                {this.renderMessage()}
                {!smsCampaignAllowed ? (
                  <strong style={{ color: 'red' }}>User requested to not send him messages!</strong>
                ) : null}
              </div>
            )}
          </div>
        </MobileAppModal>
      </div>
    );
  }
}
const actions = ['GET_ALL_USER_SMS', 'SEND_SMS'];
const loadingSelector = createLoadingSelector(actions);
const errorSelector = createErrorMessageSelector(actions);
const mapStateToProps = (state) => ({
  isLoading: loadingSelector(state),
  error: errorSelector(state),
  customerNotes: state.sms.customerNotes,
  allSMSforPhone: state.sms.allSMSforPhone,
  reachable: state.sms.reachable,
  smsCampaignAllowed: state.sms.smsCampaignAllowed,
  adminNotes: state.sms.adminNotes,
});

export default connect(mapStateToProps, {
  fetchAllSMS,
  sendSMS,
  sendSMSforPhones,
})(SMSCampaign);
