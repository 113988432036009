import axios from 'axios';
import { v4 as uuid } from 'uuid';
import { Constant } from '../ApiRules/ApiRules';
import store from '../store';
import { AUTH_TOKEN_EXPIRED } from '../actions/actionTypes';

const tempMeeApi = axios.create({
  baseURL: Constant.BASE_URL,
});

tempMeeApi.interceptors.request.use((config) => ({
  ...config,
  headers: {
    ...{ 'x-correlation-id': uuid() },
    ...config.headers,
  },
}));

tempMeeApi.interceptors.response.use(
  (response) => response,
  (error) => {
    const request = error.config;

    if (request.url.includes('auth/refresh') && error.response && error.response.status === 403) {
      store.dispatch({ type: AUTH_TOKEN_EXPIRED });
    }

    return Promise.reject(error);
  },
);

export default tempMeeApi;
