import { useState } from 'react';
import { AsYouType } from 'libphonenumber-js';

export default function (defaultPhone) {
  const [number, setNumber] = useState(defaultPhone || '');
  return [
    number,
    (value) => {
      if (number.length < value.length) {
        setNumber(new AsYouType('US').input(value));
      } else {
        let index = number.length - 1;
        for (; index >= 0 && !(number[index] >= '0' && number[index] <= '9'); index--);
        const newVal = number.substring(0, Math.max(0, index));
        setNumber(new AsYouType('US').input(newVal));
      }
    },
  ];
}
