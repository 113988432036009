export class Constant {
  static BASE_URL = process.env.REACT_APP_BASE_URL;

  static STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY;

  static URL_cms_report = 'location/users/report';

  static URL_cms_report_indeed = 'location/users/indeed';

  static URL_cms_users = 'location/users';

  static IOS_APP_URL = 'https://apps.apple.com/us/app/gotu/id1437086894';

  static IOS_APP_VERSION_URL = 'https://itunes.apple.com/lookup?bundleId=com.tempmee';

  static ANDROID_APP_URL = 'https://play.google.com/store/apps/details?id=com.tempmeeapp&hl=en';
}

export var WebServices = {
  callWebService(url, body, token) {
    body.tsOffset = new Date().getTimezoneOffset() / 60;
    let headers;
    token
      ? (headers = {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      })
      : (headers = {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });

    return fetch(url, headers)
      .then((response) => response.text()) // Convert to text instead of res.json()
      .then((text) => text)
      .then((response) => JSON.parse(response)) // Parse the text.
      .then(
        (jsonRes) => jsonRes, // main output
      );
  },

  callWebService_GET(url, body) {
    const urlRequest = new URL(url);
    urlRequest.search = new URLSearchParams(body).toString();
    body.tsOffset = new Date().getTimezoneOffset() / 60;
    return this.sendGetRequest(urlRequest, body.CallToken);
  },

  sendGetRequest(url, token) {
    return fetch(url, {
      // Use your url here
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        CallToken: token || '',
      },
    })
      .then((response) => response.text()) // Convert to text instead of res.json()
      .then((text) => text)
      .then((response) => JSON.parse(response)) // Parse the text.
      .then((jsonRes) => jsonRes);
  },
};
