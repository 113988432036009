export const OptionItem = ({ isSelected, label, value, onClick }) => (
  <li
    className={`multiselect__option ${isSelected ? 'multiselect__option--selected' : ''}`}
    key={value}
    onClick={onClick}
  >
    <input checked={isSelected} name={value} type="checkbox" />
    {label}
  </li>
);
