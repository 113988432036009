import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { addOfficesToRegion, fetchDsoOffices } from '../../../../../../../actions/dsosActions';
import { createLoadingSelector } from '../../../../../../../apis/selectors';
import debounceHook from '../../../../../../commonComponents/debounceHook';

const actions = ['FETCH_DSO_OFFICE_LIST'];
const loadingSelector = createLoadingSelector(actions);

export const useAddOfficeToRegion = (item) => {
  const dispatch = useDispatch();
  const { id: dsoId } = useParams();
  const [name, setName] = useState('');
  const [selectedOffice, setSelectedOffice] = useState(null);
  const debouncedSearchText = debounceHook(name, 250);
  const { data } = useSelector((state) => state.dsos.dsoOfficeList);
  const isLoading = useSelector((state) => loadingSelector(state));
  const [showModal, setShowModal] = useState(false);

  const handleSearch = (searchTerm) => {
    if (selectedOffice?.name === searchTerm) return;
    setSelectedOffice(null);
    setName(searchTerm);
  };

  const handleSelect = (option) => {
    setSelectedOffice(option);
  };

  const handleSubmit = () => {
    if (selectedOffice) {
      dispatch(addOfficesToRegion({ regionId: item?.id, officesIds: [selectedOffice?.id] }));
      setShowModal(false);
      setName('');
      setSelectedOffice(null);
    }
  };

  useEffect(() => {
    if (debouncedSearchText) {
      dispatch(fetchDsoOffices('dso', dsoId, { limit: 5, page: 1, officeName: name }));
    }
  }, [dispatch, debouncedSearchText, dsoId, name]);

  return {
    showModal,
    setShowModal,
    selectedOffice,
    data,
    isLoading,
    handleSearch,
    handleSelect,
    handleSubmit,
  };
};
