import React from 'react';
import { useSelector } from 'react-redux';
import RatingCard from './RatingCard';

const RatingDetails = () => {
  const rating = useSelector((state) => state.jobs.rating);
  const hygienistRating = rating?.toDentist;
  const dentistRating = rating?.toHygienist;

  const hygienistRatingItems = [{ label: 'Rating', value: hygienistRating?.officeRating?.averageRating }];
  const dentistRatingItems = dentistRating?.professionalRating
    ? [
      {
        label: 'Professional Skills',
        value: dentistRating?.professionalRating?.professionalSkills,
      },
      {
        label: 'Interpersonal Skills',
        value: dentistRating?.professionalRating?.interpersonalSkills,
      },
      { label: 'Time Management', value: dentistRating?.professionalRating?.timeManagement },
    ]
    : [{ label: 'Rating', value: dentistRating?.rating_count }];

  return (
    <div
      style={{
        width: '80%',
        maxWidth: 800,
        display: 'flex',
        alignSelf: 'center',
        justifyContent: 'center',
        marginTop: 19,
        gap: 11,
      }}
    >
      {hygienistRating ? (
        <RatingCard
          title="DENTIST"
          name={hygienistRating?.rating_to?.office_name}
          averageRating={hygienistRating?.rating_count}
          ratingItems={hygienistRatingItems}
          feedback={hygienistRating?.feedback_for_tempmee}
        />
      ) : (
        <div style={{ flex: 1 }} />
      )}
      {dentistRating ? (
        <RatingCard
          title="DENTAL PROFESSIONAL"
          name={dentistRating?.rating_to?.full_name}
          averageRating={dentistRating?.professionalRating?.averageRating}
          ratingItems={dentistRatingItems}
          feedback={dentistRating?.feedback_for_tempmee}
        />
      ) : (
        <div style={{ flex: 1 }} />
      )}
    </div>
  );
};

export default RatingDetails;
