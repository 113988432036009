import React, { useEffect, useRef, useState } from 'react';

export const SearchableDropdown = ({
  onSelect,
  onSearch,
  placeholder = 'Type to search...',
  idKey = 'id',
  labelKey = 'label',
  options = [],
  isLoading = false,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    if (!searchTerm) {
      setIsDropdownOpen(false);
    }
    onSearch(searchTerm);
  }, [searchTerm, onSearch]);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div style={{ position: 'relative', width: 300 }}>
      <input
        type="text"
        placeholder={placeholder}
        value={searchTerm}
        onChange={(e) => {
          setSearchTerm(e.target.value);
          setIsDropdownOpen(true);
        }}
        style={{
          width: '100%',
          padding: '10px',
          boxSizing: 'border-box',
        }}
      />
      {isDropdownOpen && options.length > 0 && !isLoading && (
        <div
          ref={dropdownRef}
          style={{
            position: 'absolute',
            top: '100%',
            left: 0,
            right: 0,
            maxHeight: '150px',
            overflowY: 'auto',
            backgroundColor: '#fff',
            border: '1px solid #ccc',
            zIndex: 1000,
          }}
        >
          {options.map((option) => (
            // eslint-disable-next-line jsx-a11y/no-static-element-interactions
            <div
              key={option[idKey]}
              onClick={() => {
                onSelect(option);
                setSearchTerm(option[labelKey]);
                setIsDropdownOpen(false);
              }}
              style={{
                padding: '10px',
                cursor: 'pointer',
                borderBottom: '1px solid #f0f0f0',
              }}
            >
              {option[labelKey]}
            </div>
          ))}
        </div>
      )}
      {isLoading && (
        <div
          style={{
            position: 'absolute',
            top: '100%',
            left: 0,
            right: 0,
            padding: '10px',
            backgroundColor: '#fff',
            border: '1px solid #ccc',
            zIndex: 1000,
            textAlign: 'center',
          }}
        >
          Loading...
        </div>
      )}
      {isDropdownOpen && options.length === 0 && (
        <div
          ref={dropdownRef}
          style={{
            position: 'absolute',
            top: '100%',
            left: 0,
            right: 0,
            padding: '10px',
            backgroundColor: '#fff',
            border: '1px solid #ccc',
            zIndex: 1000,
            textAlign: 'center',
          }}
        >
          No results found
        </div>
      )}
    </div>
  );
};
