export const InterviewType = {
  call: 'call',
  inPerson: 'in-person',
  working: 'working',
};

export const ActionTypes = {
  declined: 'declined',
  rejected: 'rejected',
  interviewPhone: 'interview-phone',
  interviewInperson: 'interview-inperson',
  interviewWorkingday: 'interview-workingday',
  hired: 'hired',
  hide: 'hide',
  show: 'show',
  cancelInterview: 'cancel-interview',
};

export const getApplicationStatus = (lastApplicationAction) => {
  switch (lastApplicationAction) {
  case ActionTypes.declined:
    return 'Declined';
  case ActionTypes.rejected:
    return 'Rejected';
  case ActionTypes.interviewInperson:
    return 'Interview in person';
  case ActionTypes.interviewPhone:
    return 'Interview phone';
  case ActionTypes.interviewWorkingday:
    return 'Interview working day';
  case ActionTypes.hired:
    return 'Hired';
  case ActionTypes.hide:
    return 'Hide';
  case ActionTypes.show:
    return 'Show';
  case ActionTypes.show:
    return 'Show';
  case ActionTypes.cancelInterview:
    return 'Interview Cancelled';
  default:
    return 'New';
  }
};
