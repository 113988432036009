import React, { useEffect, useRef } from 'react';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';
import TableauReport from 'tableau-react';

export default ({ url }) => {
  const [height, setHeight] = React.useState(null);
  const ref = useRef(null);

  useEffect(() => {
    setHeight(ref?.current?.offsetHeight);
  }, [ref.current]);

  return (
    <div
      ref={ref}
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        overflowY: 'auto',
      }}
    >
      {height && (
        <div
          style={{
            height: '100%',
          }}
        >
          <TableauReport
            url={url}
            options={{
              height: height - 100,
              width: '100%',
            }}
          />
        </div>
      )}
    </div>
  );
};
