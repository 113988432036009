import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearDsoGroupOffices, clearDsoRegionOffices, fetchDsoGroupOffices, fetchDsoRegionOffices } from '../../../../../../../actions/dsosActions';
import { createLoadingSelector } from '../../../../../../../apis/selectors';

const loadingSelector = createLoadingSelector([
  'FETCH_DSO_GROUP_OFFICE_LIST',
  'FETCH_DSO_REGION_OFFICE_LIST',
]);

export const useOfficeList = ({ entity, entityId }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(loadingSelector);
  const { data, filters, isActiveRequest, isFinalPage, total } = useSelector((state) => {
    if (entity === 'group') {
      return state.dsos.dsoGroupOfficeList;
    }

    return state.dsos.dsoRegionOfficeList;
  });

  const getOfficeList = useCallback(
    (entity, entityId, page = 1, limit = 15) => {
      const action = entity === 'group' ? fetchDsoGroupOffices : fetchDsoRegionOffices;
      
      dispatch(action(entity, entityId, { limit, page }));
    },
    [dispatch],
  );

  const handleOnScroll = useCallback(
    (e) => {
      const tableScrollHeight = e?.target?.scrollHeight - e?.target?.scrollTop - 20;
      const clientHeight = e?.target?.clientHeight;

      if (tableScrollHeight <= clientHeight && !isActiveRequest && !isFinalPage) {
        getOfficeList(entity, entityId, filters.page + 1);
      }
    },
    [entity, entityId, filters.page, getOfficeList, isActiveRequest, isFinalPage],
  );

  useEffect(() => {
    if (entity && entityId) {
      getOfficeList(entity, entityId);
    }

    return () => {
      if (entity) {
        const action = entity === 'group' ? clearDsoGroupOffices : clearDsoRegionOffices;
        dispatch(action());
      }
    }
  }, [dispatch, entity, entityId, getOfficeList]);

  return {
    data,
    isInitialLoading: !data.length && isLoading,
    isLoading,
    total,
    getOfficeList,
    handleOnScroll,
  };
};
