import moment from 'moment';
import Modal from 'react-modal';
import { useDispatch } from 'react-redux';
import { NCNSRepostJob } from '../../../../actions/jobsAction';

export const ModalNCNSRepostConfirmation = ({ isVisible, onClose, jobId, repostMaxStartTime }) => {
  const getMaxRepostTime = () => moment(repostMaxStartTime).format('h:mm a');

  const dispatch = useDispatch();

  const getNewStartTime = () => {
    const nextHour = moment().add(1, 'hours');
    return nextHour.format('h:mm a');
  };

  const startTime = getNewStartTime();
  const maxRepostTime = getMaxRepostTime();

  const handleSubmitRepost = () => {
    dispatch(
      NCNSRepostJob({
        jobId,
        repostMaxStartTime,
        startTimeDisplay: startTime,
        maxTimeDisplay: maxRepostTime,
      }),
    );
    onClose();
  };

  return (
    <Modal isOpen={isVisible} onRequestClose={() => onClose(false)} style={customStyles}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          marginTop: 16,
          marginBottom: 20,
        }}
      >
        <h4>Repost NCNS Shift?</h4>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 16,
            marginLeft: 20,
            marginRight: 20,
          }}
        >
          <p style={{ textAlign: 'center' }}>
            This shift will be reposted as an <b>urgent shift with auto-confirm</b> with a start
            <br />
            time of <b>{getNewStartTime()}</b>, and will auto-update until{' '}
            <b>{getMaxRepostTime()}</b>.
          </p>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: 20,
          }}
        >
          <button
            className="btn btn-secondary"
            style={{
              marginRight: '12px',
              width: 192,
              borderRadius: 10,
              backgroundColor: '#808080',
            }}
            onClick={() => onClose()}
            type="button"
          >
            Cancel
          </button>

          <button
            className="btn btn-success"
            style={{ marginLeft: '12px', width: 192, borderRadius: 10 }}
            onClick={() => handleSubmitRepost()}
            type="submit"
          >
            Repost for NCNS
          </button>
        </div>
      </div>
    </Modal>
  );
};

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    minHeight: '200px',
  },
  userContainer: {
    flex: 1,
    margin: 0,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    paddingLeft: 30,
    paddingRight: 10,
    gap: 10,
    height: '180px',
  },
  userContainerText: {
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    textAlign: 'left',
  },
};
