import React from 'react';
import dateFormat from 'dateformat';
import { TabViewContainer } from '../../commonComponents/TabViewContainer';
import { Colors } from '../../../themes/colors';

export default ({ npsInfo }) => (
  <TabViewContainer
    TitleTrailing={
      <div style={{ flex: 1, display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
        <div
          style={{
            backgroundColor: 'gray',
            height: 40,
            width: 40,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 20,
            marginLeft: 10,
            marginRight: 10,
          }}
        >
          <h3
            className="card-title"
            style={{
              color: Colors.white,
              margin: 0,
              padding: 0,
            }}
          >
            {npsInfo.length}
          </h3>
        </div>
      </div>
    }
    style={{
      width: '50%',
      justifyContent: 'center',
    }}
    title="NPS"
    variant="card"
  >
    <div
      style={{
        display: 'flex',
        borderBottom: '1px solid #ededed',
        flex: 1,
        marginLeft: 20,
        marginRight: 20,
      }}
    >
      <div style={{ flex: 5 }}>
        <strong>Date</strong>
      </div>
      <div style={{ flex: 3 }}>
        <strong>Score</strong>
      </div>
    </div>

    {npsInfo.length === 0 ? (
      <div
        style={{
          minHeight: 200,
          overflowY: 'auto',
          borderBottom: '1px solid #efefef',
        }}
      >
        <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
          <p
            style={{
              fontSize: 18,
              alignText: 'center',
            }}
          >
            No NPS
          </p>
        </div>
      </div>
    ) : (
      <div
        style={{
          minHeight: 200,
          overflowY: 'auto',
          borderBottom: '1px solid #efefef',
        }}
      >
        {npsInfo.map((nps) => (
          <div
            key={nps.id}
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyItems: 'center',
              marginLeft: 10,
              marginRight: 10,
              paddingLeft: 10,
              paddingRight: 10,
              paddingBottom: 10,
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                border: '1px solid #efefef',
                borderRadius: 25,
                marginTop: 10,
                flex: 1,
              }}
            >
              <p
                style={{
                  margin: 0,
                  padding: '10px 10px',
                  flex: 5,
                }}
              >
                {dateFormat(nps.date, 'dddd, mmmm dS, yyyy, h:MM:ss TT') || ''}
              </p>
              <p
                style={{
                  margin: 0,
                  padding: '10px 10px',
                  flex: 3,
                }}
              >
                {nps.score}
              </p>
            </div>
          </div>
        ))}
      </div>
    )}
  </TabViewContainer>
);
