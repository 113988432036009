const tabMappings = {
  regions: 'Regions',
  groups: 'Groups',
  'office-admins': 'Office Admins',
  offices: 'Offices',
};

export const getTabByName = (currentTab) => tabMappings[currentTab] || tabMappings.regions;

export const getUrlByTab = (dsoId, tab) => {
  const normalizedTab =
    Object.keys(tabMappings).find((key) => tabMappings[key] === tab) || 'regions';
  return `/dsos/${dsoId}/${normalizedTab}`;
};
