import React, { useCallback, useState } from 'react';
import Modal from 'react-modal';

import { useSelector } from 'react-redux';
import { states } from '../../../commonComponents/StatePicker';
import {
  deleteLicense,
  hideExistingLicensePopup,
  updateUserLicense,
} from '../../../../actions/userActions';

import {
  createCredentialEntryType,
  updateCredentialEntryType,
} from '../../../../actions/licensesAction';

import Slider from './Slider';
import DragAndDrop from './DragDrop';
import EntryFields from './EntryFields';
import CustomButton from '../../../commonComponents/buttons';
import { showErrorMessage } from '../../../../utils';

import MagnifierIcon from '../../../../images/magnifier.svg';
import { professionsWithSubProfessionsTable, userDaSubProfessions } from '../../../../constants';
import { UserProfession } from '../../../../enums/UserProfession';

const abraviations = states.map((state) => state.abbreviation);

const StateCredential = ({
  license,
  stateLicense,
  reference,
  daCertificate,
  cprBls,
  radiology,
  hasStateLicense,
  hasDACertificate,
  hasCPRBLS,
  hasRadiology,
  hasReference,
  hasOnlyOneCredential,
  getStatusColor,
  stateCredentials,
  setLicenses,
  allProfessions,
  licenses,
  setShowDeletePopup,
  setDeleteId,
  dispatch,
}) => {
  const { profession, professionName, state: licenseState, id: licenseId } = license || {};

  const subProfession = license?.subProfession || 'none';
  const subProfessionName = userDaSubProfessions.find((item) => item.code === subProfession)?.name;

  const isCurrentProfessionHasSubProfession =
    professionsWithSubProfessionsTable[profession]?.length > 0;
  const hasSubProfession = subProfession?.length > 0;

  const currentUser = useSelector((state) => state.users.currentUser);

  const [editMode, setEditMode] = useState(false);
  const [prevLicenseState, setPrevLicenseState] = useState();
  const nationalProviderIdentifier =
    currentUser?.professional_info?.nationalProviderIdentifier || '';

  const showExistingLicensePopup = useSelector((state) => state.users.showExistingLicensePopup);

  const statesFiltered = states.filter((state) => state.abbreviation === licenseState);
  const licVerificationUrl = statesFiltered.length > 0 ? statesFiltered[0].licVerificationUrl : '';

  const credentialOfLicense = stateCredentials?.[licenseState]?.[profession];

  const hasRadiologyEntry = credentialOfLicense?.radiology?.input_type === 'entry_field';
  const [radiologyChecked, setRadiologyChecked] = useState(false);

  const hasStateLicenseEntry = credentialOfLicense?.state_license?.input_type === 'entry_field';
  const [stateLicenseChecked, setStateLicenseChecked] = useState(false);

  const hasDaCertificateEntry = credentialOfLicense?.da_certificate?.input_type === 'entry_field';
  const [daCertificateChecked, setDaCertificateChecked] = useState(false);

  const hasCprLbsCertificateEntry = credentialOfLicense?.['cpr/bls']?.input_type === 'entry_field';
  const [cprLbsCertificateChecked, setCprLbsCertificateChecked] = useState(false);

  const hasReferenceEntry = credentialOfLicense?.reference?.input_type === 'entry_field';
  const maxReferenceAllowed = 3;

  const allowToSaveCredentials = (() => {
    const validations = [];

    if (hasRadiologyEntry) {
      validations.push(radiologyChecked);
    }

    if (hasStateLicenseEntry) {
      validations.push(stateLicenseChecked);
    }

    if (hasDaCertificateEntry) {
      validations.push(daCertificateChecked);
    }

    if (hasCprLbsCertificateEntry) {
      validations.push(cprLbsCertificateChecked);
    }

    return validations.every((validation) => validation === true);
  })();

  const resetCheckboxes = useCallback(() => {
    setRadiologyChecked(false);
    setStateLicenseChecked(false);
    setDaCertificateChecked(false);
    setCprLbsCertificateChecked(false);
  }, []);

  return (
    <div style={{ border: '1px solid #b7b7b7', padding: 10, marginBottom: 70 }}>
      <div className="row" style={{ marginBottom: 10 }}>
        <div
          className={`col-6 ${hasOnlyOneCredential && 'offset-3'}`}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            overflowY: 'auto',
            marginBottom: 10,
          }}
        >
          <div
            className="card"
            style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              padding: '5px 0',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: '0 10px',
                borderBottom: '1px solid #efefef ',
              }}
            >
              <div>License Status:</div>
              <div
                style={{
                  color: getStatusColor(license.status),
                }}
              >
                {!editMode ? (
                  license.status.charAt(0).toUpperCase() + license.status.slice(1)
                ) : (
                  <select
                    defaultValue={license.status}
                    style={{
                      height: 40,
                    }}
                    value={license.status}
                    className="form-control"
                    onChange={(e) => {
                      const value = e?.target?.value;
                      setLicenses((prevState) => {
                        const licenseIndex = prevState.findIndex((data) => data.id === license.id);

                        prevState[licenseIndex].status = value;

                        return [...prevState];
                      });
                    }}
                  >
                    <option value="verified">Verified</option>
                    <option value="new">New</option>
                    <option value="invalid">Invalid</option>
                    <option value="expired">Expired</option>
                    <option value="upcoming">Upcoming</option>
                  </select>
                )}
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: '0 10px',
                borderBottom: '1px solid #efefef ',
              }}
            >
              <div>State:</div>
              <div>
                {!editMode ? (
                  licenseState
                ) : (
                  <select
                    className="custom-select custom-select-lg"
                    onChange={(event) => {
                      const state = event?.target?.value;

                      setLicenses((prevState) => {
                        const licenseIndex = prevState.findIndex((data) => data.id === license.id);

                        prevState[licenseIndex].state = state;

                        return [...prevState];
                      });
                    }}
                    value={license.state}
                    style={{
                      height: 32,
                    }}
                  >
                    {abraviations.map((key) => (
                      <option key={key} value={key}>
                        {key}
                      </option>
                    ))}
                  </select>
                )}
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: '0 10px',
                borderBottom: '1px solid #efefef ',
              }}
            >
              <div>Profession:</div>
              <div>
                {!editMode ? (
                  professionName
                ) : (
                  <select
                    defaultValue={professionName}
                    className="custom-select custom-select-lg"
                    value={professionName}
                    onChange={(e) => {
                      const value = e?.target?.value;
                      setLicenses((prevState) => {
                        const licenseIndex = prevState.findIndex((data) => data.id === license.id);

                        prevState[licenseIndex].professionName = value;
                        prevState[licenseIndex].profession = allProfessions.find(
                          (key) => key.name === value,
                        ).code;

                        return [...prevState];
                      });
                    }}
                    style={{ height: 40 }}
                  >
                    {allProfessions.map((key) => (
                      <option value={key.name}>{key.name}</option>
                    ))}
                  </select>
                )}
              </div>
            </div>

            {((editMode && isCurrentProfessionHasSubProfession) ||
              (!editMode && hasSubProfession && subProfessionName)) && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  padding: '0 10px',
                  borderBottom: '1px solid #efefef ',
                }}
              >
                <div>Sub-Profession:</div>
                <div>
                  {!editMode ? (
                    subProfessionName
                  ) : (
                    <select
                      defaultValue={subProfessionName}
                      className="custom-select custom-select-lg"
                      value={subProfession}
                      onChange={(e) => {
                        const value = e?.target?.value;

                        setLicenses((prevState) => {
                          const licenseIndex = prevState.findIndex(
                            (data) => data.id === license.id,
                          );

                          prevState[licenseIndex].subProfession = value;
                          prevState[licenseIndex].subProfessionName = allProfessions.find(
                            (key) => key.code === value,
                          )?.name;

                          return [...prevState];
                        });
                      }}
                      style={{ height: 40 }}
                    >
                      <option key="none" value="">
                        None
                      </option>
                      {professionsWithSubProfessionsTable[profession].map((key) => (
                        <option value={key.code}>{key.name}</option>
                      ))}
                    </select>
                  )}
                </div>
              </div>
            )}

            {license.profession === UserProfession.DN && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  padding: '0 10px',
                  borderBottom: '1px solid #efefef ',
                }}
              >
                <div>NPI Number:</div>
                <div>{nationalProviderIdentifier || 'N/A'}</div>
              </div>
            )}
          </div>

          {hasStateLicense && (
            <div
              className="card"
              style={{
                flex: 4,
                display: 'flex',
                flexDirection: 'column',
                marginTop: 10,
                paddingBottom: 40,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexDirection: 'row',
                }}
              >
                <div
                  className="card-body"
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                    paddingTop: 25,
                  }}
                >
                  <div
                    style={{
                      flex: 3,
                      display: 'flex',
                      justifyContent: 'space-between',
                      flexDirection: 'column',
                    }}
                  >
                    <h4
                      className="card-title"
                      style={{ marginLeft: 40, fontFamily: 'Nunito', color: '#494963' }}
                    >
                      {stateCredentials[license.state][license.profession].state_license.title}{' '}
                      <span style={{ color: '#899197', fontWeight: '300' }}>
                        {stateCredentials[license.state][license.profession].state_license.required
                          ? 'Required(*)'
                          : '(Optional)'}{' '}
                      </span>
                    </h4>
                    <div style={{ color: '#899197' }}>Enter License Number</div>
                  </div>

                  <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
                    <a
                      className="btn btn-secondary"
                      style={{ width: 94, height: 34 }}
                      href={licVerificationUrl}
                      target="_blank"
                      rel="noreferrer"
                      onClick={() => {
                        const el = document.createElement('textarea');
                        el.value = stateLicense?.entry_field?.license;
                        document.body.appendChild(el);
                        el.select();
                        document.execCommand('copy');
                        document.body.removeChild(el);
                      }}
                    >
                      <img
                        src={MagnifierIcon}
                        alt="magnifier icon"
                        style={{
                          marginRight: 4,
                          marginTop: -2,
                        }}
                      />
                      Lookup
                    </a>
                  </div>
                </div>
              </div>

              {hasStateLicenseEntry && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                    paddingBottom: 40,
                  }}
                >
                  <EntryFields
                    credential={stateLicense}
                    license={license}
                    setLicenses={setLicenses}
                    credentialType="state_license"
                    editMode={editMode}
                    isCredentialCheckboxChecked={stateLicenseChecked}
                    onCredentialCheckboxCheckChanged={setStateLicenseChecked}
                  />
                </div>
              )}

              {stateCredentials[license.state][license.profession].state_license.input_type ===
                'upload' && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      border: '1px dashed #cecece',
                      borderRadius: '5px',
                      fontWeight: 400,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    {stateLicense && stateLicense?.files?.length > 0 ? (
                      <Slider
                        credential={stateLicense}
                        license={license}
                        setLicenses={setLicenses}
                        credentialType="state_license"
                      />
                    ) : (
                      <DragAndDrop
                        credential={stateLicense}
                        license={license}
                        setLicenses={setLicenses}
                        credentialType="state_license"
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>

        {hasDACertificate && (
          <div className="col-6" style={{ marginBottom: 10 }}>
            <div
              className="card"
              style={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: 395,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexDirection: 'row',
                }}
              >
                <div
                  className="card-body"
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                    paddingTop: 25,
                  }}
                >
                  <div
                    style={{
                      flex: 3,
                      display: 'flex',
                      justifyContent: 'space-between',
                      flexDirection: 'column',
                    }}
                  >
                    <h4
                      className="card-title"
                      style={{ marginLeft: 40, fontFamily: 'Nunito', color: '#494963' }}
                    >
                      {stateCredentials[license.state][license.profession].da_certificate.title}{' '}
                      <span style={{ color: '#899197', fontWeight: '300' }}>
                        {stateCredentials[license.state][license.profession].da_certificate.required
                          ? 'Required(*)'
                          : '(Optional)'}
                      </span>
                    </h4>
                    <div style={{ color: '#899197', fontStyle: 'italic' }}>
                      Example: DANB CDA, AMT RDA, DA Diploma, Expanded Functions, Duties
                      Certificates
                    </div>
                    <div style={{ color: '#899197', marginTop: 10 }}>Upload files</div>
                  </div>
                </div>
              </div>

              {hasDaCertificateEntry && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                    paddingBottom: 40,
                  }}
                >
                  <EntryFields
                    credential={daCertificate}
                    license={license}
                    setLicenses={setLicenses}
                    credentialType="da_certificate"
                    editMode={editMode}
                    daCertificateCheckboxStatus={daCertificateChecked}
                    setDaCertificateCheckboxStatus={setDaCertificateChecked}
                  />
                </div>
              )}

              {stateCredentials[license.state][license.profession].da_certificate.input_type ===
                'upload' && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      border: '1px dashed #cecece',
                      borderRadius: '5px',
                      fontWeight: 400,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    {daCertificate && daCertificate?.files?.length > 0 ? (
                      <Slider
                        credential={daCertificate}
                        license={license}
                        setLicenses={setLicenses}
                        credentialType="da_certificate"
                      />
                    ) : (
                      <DragAndDrop
                        credential={daCertificate}
                        license={license}
                        setLicenses={setLicenses}
                        credentialType="da_certificate"
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}

        {hasRadiology && (
          <div className="col-6" style={{ marginBottom: 10 }}>
            <div
              className="card"
              style={{
                display: 'flex',
                flexDirection: 'column',
                paddingBottom: 40,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexDirection: 'row',
                }}
              >
                <div
                  className="card-body"
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                    paddingTop: 25,
                  }}
                >
                  <div
                    style={{
                      flex: 3,
                      display: 'flex',
                      justifyContent: 'space-between',
                      flexDirection: 'column',
                    }}
                  >
                    <h4
                      className="card-title"
                      style={{ marginLeft: 40, fontFamily: 'Nunito', color: '#494963' }}
                    >
                      {stateCredentials[license.state][license.profession].radiology.title}{' '}
                      <span style={{ color: '#899197', fontWeight: '300' }}>
                        {stateCredentials[license.state][license.profession].radiology.required
                          ? 'Required(*)'
                          : '(Optional)'}
                      </span>
                    </h4>
                    <div style={{ color: '#899197', fontStyle: 'italic' }}>
                      Example: Radiology/X-Ray Certificate, Radiology License
                    </div>
                    <div style={{ color: '#899197', marginTop: 10 }}>Upload files</div>
                  </div>
                </div>
              </div>

              {hasRadiologyEntry && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                    paddingBottom: 40,
                  }}
                >
                  <EntryFields
                    credential={radiology}
                    license={license}
                    setLicenses={setLicenses}
                    credentialType="radiology"
                    editMode={editMode}
                    isCredentialCheckboxChecked={radiologyChecked}
                    onCredentialCheckboxCheckChanged={setRadiologyChecked}
                  />
                </div>
              )}

              {stateCredentials[license.state][license.profession].radiology.input_type ===
                'upload' && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      border: '1px dashed #cecece',
                      borderRadius: '5px',
                      fontWeight: 400,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    {radiology && radiology?.files?.length > 0 ? (
                      <Slider
                        credential={radiology}
                        license={license}
                        setLicenses={setLicenses}
                        credentialType="radiology"
                      />
                    ) : (
                      <DragAndDrop
                        credential={radiology}
                        license={license}
                        setLicenses={setLicenses}
                        credentialType="radiology"
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}

        {hasCPRBLS && (
          <div className="col-6" style={{ marginBottom: 10 }}>
            <div
              className="card"
              style={{
                display: 'flex',
                flexDirection: 'column',
                paddingBottom: 40,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexDirection: 'row',
                }}
              >
                <div
                  className="card-body"
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                    paddingTop: 25,
                  }}
                >
                  <div
                    style={{
                      flex: 3,
                      display: 'flex',
                      justifyContent: 'space-between',
                      flexDirection: 'column',
                    }}
                  >
                    <h4
                      className="card-title"
                      style={{ marginLeft: 40, fontFamily: 'Nunito', color: '#494963' }}
                    >
                      {stateCredentials[license.state][license.profession]['cpr/bls'].title}
                      CPR/BLS Certificate{' '}
                      <span style={{ color: '#899197', fontWeight: '300' }}>
                        {stateCredentials[license.state][license.profession]['cpr/bls'].required
                          ? 'Required(*)'
                          : '(Optional)'}
                      </span>
                    </h4>
                    <div style={{ color: '#899197', fontStyle: 'italic' }}>
                      Example: CPR/BLS Certificate
                    </div>
                    <div style={{ color: '#899197', marginTop: 10 }}>Upload files</div>
                  </div>
                </div>
              </div>

              {hasCprLbsCertificateEntry && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                    paddingBottom: 40,
                  }}
                >
                  <EntryFields
                    credential={cprBls}
                    license={license}
                    setLicenses={setLicenses}
                    credentialType="cpr/bls"
                    editMode={editMode}
                    isCredentialCheckboxChecked={cprLbsCertificateChecked}
                    onCredentialCheckboxCheckChanged={setCprLbsCertificateChecked}
                  />
                </div>
              )}

              {stateCredentials[license.state][license.profession]['cpr/bls'].input_type ===
                'upload' && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      border: '1px dashed #cecece',
                      borderRadius: '5px',
                      fontWeight: 400,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    {cprBls && cprBls?.files?.length > 0 ? (
                      <Slider
                        credential={cprBls}
                        license={license}
                        setLicenses={setLicenses}
                        credentialType="cpr/bls"
                      />
                    ) : (
                      <DragAndDrop
                        credential={cprBls}
                        license={license}
                        setLicenses={setLicenses}
                        credentialType="cpr/bls"
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}

        {hasReference && (
          <>
            {Array.from({ length: maxReferenceAllowed }).map((value, index) => {
              const referenceSettings =
                stateCredentials[license.state][license.profession]?.reference;
              const referenceCredential = reference?.entry_field?.references?.[index];
              const key = `reference-${license?.id}-${index}`;

              return (
                <div className="col-6" style={{ marginBottom: 10 }} key={key}>
                  <div
                    className="card"
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      paddingBottom: 40,
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexDirection: 'row',
                      }}
                    >
                      <div
                        className="card-body"
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          flexDirection: 'row',
                          paddingTop: 25,
                        }}
                      >
                        <div
                          style={{
                            flex: 3,
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexDirection: 'column',
                          }}
                        >
                          <h4 className="card-title" style={{ marginLeft: 40, color: '#494963' }}>
                            {referenceSettings?.title} {index + 1}{' '}
                            <span style={{ color: '#899197', fontWeight: '300' }}>
                              {index === 0 && referenceSettings?.required
                                ? 'Required(*)'
                                : '(Optional)'}
                            </span>
                          </h4>
                        </div>
                      </div>
                    </div>

                    {hasReferenceEntry && (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          flexDirection: 'column',
                          alignItems: 'center',
                          paddingBottom: 40,
                        }}
                      >
                        <EntryFields
                          onReferenceEntryChanged={(reference) => {
                            const licenseIndex = licenses.findIndex(
                              (data) => data.id === license.id,
                            );

                            if (licenseIndex > -1) {
                              setLicenses((prevState) => {
                                const credentialIndex = prevState[
                                  licenseIndex
                                ].credentials.findIndex(
                                  (c) => c && c.credential_type === 'reference',
                                );

                                if (prevState[licenseIndex].credentials[credentialIndex]) {
                                  const newState = [...prevState];
                                  const field =
                                    newState[licenseIndex].credentials[credentialIndex].entry_field;

                                  if (!Object.prototype.hasOwnProperty.call(field, 'references')) {
                                    newState[licenseIndex].credentials[
                                      credentialIndex
                                    ].entry_field = {
                                      ...field,
                                      references: [],
                                    };
                                  }

                                  newState[licenseIndex].credentials[
                                    credentialIndex
                                  ].entry_field.references[index] = reference;

                                  return [...newState];
                                }

                                return [...prevState];
                              });
                            }
                          }}
                          credential={referenceCredential}
                          license={license}
                          setLicenses={setLicenses}
                          credentialType="reference"
                          editMode={editMode}
                        />
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </>
        )}

        <div
          className="col-12"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 20,
          }}
        >
          {!editMode ? (
            <>
              <button
                className="btn btn-info"
                style={{ width: 94, height: 34 }}
                onClick={() => {
                  setPrevLicenseState(JSON.parse(JSON.stringify(licenses)));

                  setEditMode(true);
                  resetCheckboxes();
                }}
                type="button"
              >
                Edit
              </button>
              <button
                className="btn btn-danger"
                style={{ width: 94, height: 34 }}
                onClick={() => {
                  setShowDeletePopup(true);
                  setDeleteId(license.id);
                }}
                type="button"
              >
                Delete
              </button>
            </>
          ) : (
            <>
              <button
                className="btn btn-success"
                style={{ width: 94, height: 34, marginRight: 60 }}
                onClick={async () => {
                  if (!allowToSaveCredentials) {
                    showErrorMessage({
                      message: 'Confirm that the credentials provided belong to this professional.',
                      position: 'bottom-left',
                    });

                    return;
                  }

                  const validCredentials = license.credentials.map((credential) => {
                    const { credential_type: credentialType, entry_field: entryField } =
                      credential || {};

                    if (credentialType === 'reference') {
                      return {
                        ...credential,
                        entry_field: {
                          // only send references key, anything else is not needed
                          references: (entryField.references ?? []).filter(
                            ({ officeName, contactEmail, employmentStart, employmentEnd }) =>
                              officeName.trim().length > 0 &&
                              contactEmail.trim().length > 0 &&
                              !!employmentStart &&
                              !!employmentEnd,
                          ),
                        },
                      };
                    }

                    return credential;
                  });

                  setEditMode(false);

                  dispatch(
                    updateUserLicense(
                      license.id,
                      {
                        license: license?.license,
                        state: license?.state,
                        status: license?.status,
                        profession: license?.profession,
                        subProfession: license?.subProfession?.length
                          ? license?.subProfession
                          : null,
                      },
                      // since field of Front Desk credential is different from other professions,
                      // license profession/sub-profession need to be updated first before updating credentials
                      // to avoid wrong input type fields error
                      async () => {
                        if (validCredentials) {
                          for (const c of validCredentials) {
                            const {
                              credential_input_type: credentialInputType,
                              credential_type: credentialType,
                              entry_field: entryField,
                              id,
                            } = c || {};

                            if (c) {
                              const isReferenceCredentialValid =
                                credentialType === 'reference' &&
                                credentialInputType === 'entry_field' &&
                                entryField?.references?.length > 0;
                              const isLicenseCredentialValid =
                                credentialType !== 'reference' &&
                                credentialInputType === 'entry_field' &&
                                entryField?.license;

                              if (isReferenceCredentialValid || isLicenseCredentialValid) {
                                if (!id) {
                                  dispatch(createCredentialEntryType(c, licenseId));
                                } else {
                                  dispatch(updateCredentialEntryType(c, licenseId, id));
                                }
                              }
                            }
                          }
                        }
                      },
                    ),
                  );
                }}
                type="button"
              >
                Save
              </button>

              <button
                className="btn btn-secondary"
                style={{ width: 94, height: 34 }}
                onClick={() => {
                  setLicenses(JSON.parse(JSON.stringify(prevLicenseState)));
                  setEditMode(false);
                }}
                type="button"
              >
                Cancel
              </button>
            </>
          )}
        </div>
      </div>

      <Modal
        isOpen={showExistingLicensePopup}
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '50%',
            padding: '50px 0px',
          },
        }}
      >
        <div
          className="card border-0"
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div style={{ width: '80%' }}>
            <h3 className="card-text" style={{ fontSize: '20px', textAlign: 'center' }}>
              This professional has another license with the same profession type AND state.
              <br />
              <br />
              Click YES to delete the old license and keep the new license.
              <br />
              <br />
              Click NO to keep both licenses.
            </h3>
          </div>
          <div
            style={{
              display: 'flex',
              width: '60%',
              justifyContent: 'space-between',
              marginTop: '20px',
            }}
          >
            <CustomButton
              width={200}
              name="YES"
              fontSize={16}
              color="#88DD00"
              click={() => {
                const existingLicense = licenses?.find(
                  ({ id, state, profession }) =>
                    state === license?.state &&
                    profession === license?.profession &&
                    id !== license?.id,
                );

                if (existingLicense?.id) {
                  dispatch(deleteLicense(existingLicense?.id));
                }

                dispatch(hideExistingLicensePopup());
              }}
            />
            <CustomButton
              width={200}
              name="NO"
              fontSize={16}
              color="#F4511E"
              click={() => dispatch(hideExistingLicensePopup())}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default StateCredential;
