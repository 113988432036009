import React from 'react';
import { Colors } from '../../themes/colors';

export default function CheckCircle({ checked }) {
  const circleStyle = {
    opacity: checked ? 1 : 0,
    r: checked ? 4.5 : 0,
    transition: 'opacity 0.3s ease, r 0.3s ease',
  };

  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="1"
        y="1"
        width="15"
        height="15"
        rx="7.5"
        stroke={Colors.secondary_500}
        strokeWidth="2"
      />
      <circle cx="8.5" cy="8.5" fill={Colors.secondary_500} style={circleStyle} />
    </svg>
  );
}
