import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchDsoById,
  fetchDsoGroupList,
  fetchDsoRegionList,
} from '../../../../../../../actions/dsosActions';
import { fetchOfficeAccessesByStaffId } from '../../../../../../../actions/staffActions';
import { createLoadingSelector } from '../../../../../../../apis/selectors';

const actions = [
  'FETCH_DSO_BY_ID',
  'FETCH_STAFF_BY_ID',
  'FETCH_DSO_GROUP_LIST',
  'FETCH_DSO_REGION_LIST',
  'FETCH_STAFF_OFFICE_ACCESSES',
  'FETCH_RATE_LIMITS',
];
const loadingSelector = createLoadingSelector(actions);

export const useGeneralTab = (staffId) => {
  const dispatch = useDispatch();
  const dsoGroupDetails = useSelector((state) => state.dsos.dsoGroupDetails);
  const staffDetails = useSelector((state) => state.staff.staffDetails);
  const { data: officeAccesses, staffId: stateOfficeAccessesStaffId } = useSelector(
    (state) => state.staff.officeAccesses,
  );
  const { data: dsoGroupData, dsoId: stateGroupDsoId } = useSelector(
    (state) => state.dsos.dsoGroupList,
  );
  const { data: dsoRegionData, dsoId: stateRegionDsoId } = useSelector(
    (state) => state.dsos.dsoRegionList,
  );
  const isLoading = useSelector((state) => loadingSelector(state));

  useEffect(() => {
    if (!isLoading && staffDetails.dsoID && staffDetails.id === staffId) {
      dispatch(fetchDsoById(staffDetails.dsoID));
      dispatch(fetchDsoGroupList(staffDetails.dsoID, { limit: 50 }));
      dispatch(fetchDsoRegionList(staffDetails.dsoID, { limit: 50 }));
      dispatch(fetchOfficeAccessesByStaffId(staffDetails.id));
    }
  }, [dispatch, staffDetails, staffId]);

  return {
    dsoGroupDetails,
    staffDetails,
    dataGroup: stateGroupDsoId === staffDetails.dsoID ? dsoGroupData : [],
    dataRegion: stateRegionDsoId === staffDetails.dsoID ? dsoRegionData : [],
    officeAccesses: stateOfficeAccessesStaffId === staffDetails.id ? officeAccesses : [],
    isLoading,
  };
};
