import { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addAccessToStaffEntity,
  deleteAccessOfStaffEntity,
} from '../../../../../../../actions/staffActions';
import { Checkbox } from '../../../../../../commonComponents/Checkbox';
import CustomPortal from '../../../../../../commonComponents/CustomPortal';

export const InfoGrid = ({ title, items, isChecked, entityType }) => {
  const dispatch = useDispatch();
  const staffDetails = useSelector((state) => state.staff.staffDetails);
  const { data: officeAccesses } = useSelector((state) => state.staff.officeAccesses);

  const [modalState, setModalState] = useState({
    show: false,
    item: null,
    checked: false,
  });

  const openModal = useCallback((item, checked) => {
    setModalState({ show: true, item, checked });
  }, []);

  const closeModal = useCallback(() => {
    setModalState({ show: false, item: null, checked: false });
  }, []);

  const handleConfirm = useCallback(() => {
    const { item, checked } = modalState;

    if (checked) {
      // Delete access
      const accessId = officeAccesses.find((access) => access.entityID === item.id)?.id;
      dispatch(deleteAccessOfStaffEntity(staffDetails.id, accessId));
    } else {
      // Add access
      dispatch(
        addAccessToStaffEntity(staffDetails.id, {
          id: item.id,
          entityType,
        }),
      );
    }

    closeModal();
  }, [modalState, officeAccesses, dispatch, staffDetails.id, entityType, closeModal]);

  const renderCheckbox = useCallback(
    (item) => {
      const checked = isChecked(item.id);
      return (
        <Checkbox
          key={item.id}
          htmlFor={item.id}
          label={item.name}
          checked={checked}
          textStyle={{ color: 'black' }}
          style={{ marginTop: 16 }}
          onChanged={() => openModal(item, checked)}
        />
      );
    },
    [isChecked, openModal],
  );

  return (
    <div style={{ marginLeft: 20, marginTop: 20 }}>
      <span style={{ fontWeight: 600 }}>{title}</span>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr',
          margin: '0 20px',
          alignItems: 'center',
        }}
      >
        {items?.map(renderCheckbox)}
      </div>

      {modalState.show && (
        <CustomPortal
          title={`Are you sure you want to ${
            modalState.checked ? 'delete' : 'update'
          } permissions for the staff member - ${staffDetails.firstName} ${staffDetails.lastName}?`}
          textStyle={{ textAlign: 'center' }}
          cancelText="Cancel"
          submitText="Confirm"
          submitPressed={handleConfirm}
          cancelPressed={closeModal}
        >
          <div>
            <br />
            <span style={{ fontSize: 18 }}>
              <b>Entity: </b>
              <span style={{ textTransform: 'capitalize' }}>
                {entityType} - {modalState.item.name}
              </span>
            </span>
          </div>
        </CustomPortal>
      )}
    </div>
  );
};
