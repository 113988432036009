import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  assignOfficesToDso,
  fetchDsosList,
  getOfficeById,
} from '../../../../../actions/dsosActions';
import { createLoadingSelector } from '../../../../../apis/selectors';
import history from '../../../../../history';
import { InfiniteStateScrollSelect } from '../../../../commonComponents/InfiniteStateScrollSelect/InfiniteStateScrollSelect';

const actions = ['FETCH_DSOS_LIST'];
const loadingSelector = createLoadingSelector(actions);

export const DsoName = ({ officeId }) => {
  const dispatch = useDispatch();
  const [isEditMode, setIsEditMode] = useState(false);
  const isLoading = useSelector((state) => loadingSelector(state));
  const [selectedOption, setSelectedOption] = useState(null);
  const office = useSelector((state) => state.dsos.office);

  const fetchOptions = (page = 1) => fetchDsosList({ page }, true);

  useEffect(() => {
    dispatch(getOfficeById(officeId));
  }, [dispatch, officeId]);

  const handleEditButton = () => {
    setIsEditMode(true);
  };

  const handleCancelButton = () => {
    setIsEditMode(false);
    setSelectedOption(null);
  };

  const handleOnSelect = (item) => {
    setSelectedOption(item);
  };

  const handleSaveButton = () => {
    dispatch(assignOfficesToDso(selectedOption.id, [officeId]));
    setSelectedOption(null);
    setIsEditMode(false);
  };

  const handleViewDsoButton = () => {
    history.push(`/dsos/${office?.dso?.id}/regions`);
  };

  return (
    <div className="card mb-2">
      <div
        className="card-body"
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignContent: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <h3 className="card-title" style={{ textAlign: 'center' }}>
            DSO Name (new)
          </h3>
          <br />
          {!isEditMode && (
            <h4 style={{ textAlign: 'center', width: '100%' }}>
              {office.dso?.name || 'Not selected'}
            </h4>
          )}
          {isEditMode && (
            <InfiniteStateScrollSelect
              fetchOptions={fetchOptions}
              stateSelector={(state) => state.dsos.dsosList}
              isLoading={isLoading}
              labelKey="name"
              customStyle={{ height: 100 }}
              onSelect={handleOnSelect}
            />
          )}
        </div>

        <div
          style={{
            marginTop: 12,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {!isEditMode && (
            <div
              style={{
                display: 'flex',
                gap: 20,
              }}
            >
              {office?.dso?.id && (
                <button
                  type="button"
                  className="btn btn-secondary"
                  style={{ width: '100px' }}
                  onClick={handleViewDsoButton}
                >
                  View DSO
                </button>
              )}
              <button
                type="button"
                className="btn btn-info"
                style={{ width: '100px' }}
                onClick={handleEditButton}
              >
                Edit
              </button>
            </div>
          )}

          {isEditMode && (
            <div
              style={{
                display: 'flex',
                gap: 20,
              }}
            >
              <button
                type="button"
                className="btn btn-secondary"
                style={{
                  width: '100px',
                }}
                onClick={handleCancelButton}
              >
                Cancel
              </button>

              <button
                type="button"
                className="btn btn-success"
                style={{
                  width: '100px',
                }}
                onClick={handleSaveButton}
              >
                Save
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
