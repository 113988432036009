export const numberOfPermanentStaffOptions = [
  'N/A',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15+',
];

export const numberOfOperatoriesOptions = [
  'N/A',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10+',
];

export const bookedOutPatientsOptions = [
  'N/A',
  'Available Immediately',
  '1-2 weeks',
  '3-4 weeks',
  '1-2 months',
  '3-4 months',
  '5-6 months',
  '6+ months',
];
