import tempMee from '../apis/tempMee';
import history from '../history';
import { convertTimeToMinutes } from '../utils/String';
import { MULTIDAY_ACTIONS, SHOW_MESSAGE } from './actionTypes';
import { getAuthHeaders } from './authActions';

export const fetchMultidayJobs = (state) => async (dispatch, getState) => {
  const newSearchParams = {
    page: state.page,
    limit: state.sizePerPage || 15,
  };
  const { searchParams } = getState().multiday;
  if (JSON.stringify(searchParams) === JSON.stringify(newSearchParams)) {
    return;
  }
  dispatch({ type: MULTIDAY_ACTIONS.FETCH_MULTIDAY_JOBS_REQUEST, payload: newSearchParams });
  try {
    const headers = await getAuthHeaders();
    const response = await tempMee.get('admin/multi-day', {
      params: newSearchParams,
      headers,
    });
    dispatch({ type: MULTIDAY_ACTIONS.FETCH_MULTIDAY_JOBS_SUCCESS, payload: response.data });
  } catch (err) {
    dispatch({ type: MULTIDAY_ACTIONS.FETCH_MULTIDAY_JOBS_FAILURE, payload: err });
  }
};

export const fetchMultidayById = (multidayId) => async (dispatch, getState) => {
  try {
    dispatch({ type: MULTIDAY_ACTIONS.FETCH_MDB_BY_ID_REQUEST });
    const headers = await getAuthHeaders();
    const response = await tempMee.get(`admin/multi-day/${multidayId}`, {
      headers,
    });
    dispatch({ type: MULTIDAY_ACTIONS.FETCH_MDB_BY_ID_SUCCESS, payload: response.data });
  } catch (err) {
    dispatch({ type: MULTIDAY_ACTIONS.FETCH_MDB_BY_ID_FAILURE, payload: err });
  }
};

export const fetchMultidayJobsById = (multidayId, state) => async (dispatch, getState) => {
  try {
    const newSearchParams = {
      page: state.page,
      limit: state.sizePerPage || 15,
      mdb: multidayId,
    };
    const { searchParamsJobs } = getState().multiday;
    if (JSON.stringify(searchParamsJobs) === JSON.stringify(newSearchParams)) {
      return;
    }
    dispatch({ type: MULTIDAY_ACTIONS.FETCH_MDB_JOBS_BY_ID_REQUEST, payload: newSearchParams });

    const headers = await getAuthHeaders();
    const response = await tempMee.get('admin/jobs', {
      headers,
      params: newSearchParams,
    });
    dispatch({ type: MULTIDAY_ACTIONS.FETCH_MDB_JOBS_BY_ID_SUCCESS, payload: response.data });
  } catch (err) {
    dispatch({ type: MULTIDAY_ACTIONS.FETCH_MDB_JOBS_BY_ID_FAILURE, payload: err });
  }
};

export const postMultiday = () => async (dispatch, getState) => {
  dispatch({ type: MULTIDAY_ACTIONS.POST_MULTIDAY_REQUEST });

  try {
    const headers = await getAuthHeaders();
    const data = getState().multiday.postMultidayData;
    const formattedData = data.map((job) => ({
      ...job,
      break_time: convertTimeToMinutes(job.break_time),
    }));
    const response = await tempMee.post('admin/multi-day', formattedData, {
      headers,
    });
    dispatch({ type: MULTIDAY_ACTIONS.POST_MULTIDAY_SUCCESS, payload: response.data });
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        message: 'The job was created successfully.',
      },
    });

    history.push(`/multiday/${response.data.id}`);
  } catch (err) {
    dispatch({ type: MULTIDAY_ACTIONS.POST_MULTIDAY_FAILURE, payload: err });
  }
};

export const preparePostMDB = (data) => ({
  type: MULTIDAY_ACTIONS.PREPARE_DATA_MDB_POST_SUCCESS,
  payload: data,
});

export const cancelPosting = () => ({
  type: MULTIDAY_ACTIONS.CANCEL_POSTING_SUCCESS,
});
