import MultiSelect from "./MultiSelect";
import { states } from "./StatePicker";

export const MultiStatePicker = ({ value, onChange, style = {}, optionsStyle = {}}) => {
  const options = states.map((state) => ({
    label: state.abbreviation,
    value: state.abbreviation,
  }));

  return <MultiSelect
    className="custom-select custom-select-lg"
    style={{ position: 'relative', ...style }}
    optionsStyle={{ width: 250, ...optionsStyle }}
    value={value ? value?.split(",") : options.map((el) => el?.value)}
    options={options}
    onChange={(values) => onChange(
      values.length === options.length ? 
        undefined : values.join(",")
    )}
    placeholder="All"
    showAllButton
    autoApply={false}
  />
};