import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOfficeAdminsList } from '../../../../../../../actions/dsosActions';
import { createLoadingSelector } from '../../../../../../../apis/selectors';
import history from '../../../../../../../history';

const actions = ['FETCH_DSO_OFFICE_ADMINS_LIST'];
const loadingSelector = createLoadingSelector(actions);

export const useOfficeAdminsList = (dsoId) => {
  const dispatch = useDispatch();
  const {
    filters,
    data,
    total,
    isActiveRequest,
    isFinalPage,
    dsoId: stateDsoId,
  } = useSelector((state) => state.dsos.dsoOfficeAdminsList);
  const isLoading = useSelector((state) => loadingSelector(state));

  const handleScrollList = useCallback(
    (event) => {
      const tableScrollHeight = event?.target?.scrollHeight - event?.target?.scrollTop - 20;
      const clientHeight = event?.target?.clientHeight;
      if (tableScrollHeight <= clientHeight && !isActiveRequest && !isFinalPage) {
        dispatch(fetchOfficeAdminsList({ dsoId, page: filters.page + 1 }));
      }
    },
    [dispatch, filters.page, isActiveRequest, isFinalPage, dsoId],
  );

  const handleViewStaffClick = (staffId) => {
    history.push(`/staff/${staffId}`);
  };

  useEffect(() => {
    dispatch(fetchOfficeAdminsList({ dsoId }));
  }, [dispatch, dsoId]);

  return {
    data: stateDsoId === dsoId ? data : [],
    total,
    limit: filters.limit,
    isInitialLoading: !data.length && isLoading,
    isLoading: isActiveRequest,
    handleScrollList,
    handleViewStaffClick,
  };
};
