import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect } from 'react';
import { createLoadingSelector } from '../../../../apis/selectors';
import { fetchUserHighlyRatedProfessionalsList } from '../../../../actions/userActions';

const loadingSelector = createLoadingSelector(['FETCH_USER_HIGHLY_RATED_PROFESSIONALS']);

export const useHighlyRatedList = () => {
  const dispatch = useDispatch();
  const highlyRatedProfessionals = useSelector(
    (state) => state.users.office.highlyRatedProfessionals,
  );
  const { page, data, isActiveRequest, isFinal } = highlyRatedProfessionals;
  const isLoading = useSelector((state) => loadingSelector(state));

  const handleScrollList = useCallback(
    (event) => {
      const tableScrollHeight = event?.target?.scrollHeight - event?.target?.scrollTop - 20;
      const clientHeight = event?.target?.clientHeight;
      if (tableScrollHeight <= clientHeight && !isActiveRequest && !isFinal) {
        dispatch(fetchUserHighlyRatedProfessionalsList(page + 1));
      }
    },
    [dispatch, page, isActiveRequest, isFinal],
  );

  useEffect(() => {
    dispatch(fetchUserHighlyRatedProfessionalsList(1));
  }, [dispatch]);

  return {
    data,
    isLoadingFirstPage: isLoading && page === 1,
    isLoading,
    handleScrollList,
  };
};
