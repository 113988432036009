import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { medMalStatus } from '../../constants';
import MedMalInsuranceCarrier from './MedMalInsuranceCarrier';
import { updateMedMalStatus } from '../../actions/userActions';

const InsuranceCode = 'q9';

export default function MedMalSurvey() {
  const dispatch = useDispatch();
  // eslint-disable-next-line camelcase
  const user_profile = useSelector((state) => state.users.currentUser);
  const allMetadata = useSelector((state) => state.users.allMetadata);
  const allQuestions = allMetadata?.medMalSurvey?.questions ?? [];
  const answers = user_profile?.medMalSurveys?.length
    ? user_profile?.medMalSurveys[user_profile?.medMalSurveys?.length - 1].answers
    : [];

  const insuranceAnswers = user_profile?.medMalInsuranceSurveys?.length
    ? user_profile?.medMalInsuranceSurveys[user_profile?.medMalInsuranceSurveys?.length - 1].answers
    : [];

  const renderStatusOption = () => (
    <select
      className="custom-select custom-select-lg mb-3"
      style={{ marginTop: '5px', position: 'relative' }}
      value={user_profile?.medMalSurveyStatus}
      onChange={(e) => {
        dispatch(updateMedMalStatus({ medMalSurveyStatus: e.target.value }));
      }}
    >
      {Object.values(medMalStatus).map((status) => (
        <option className="dropdown-item" value={status.code}>
          {status.description}
        </option>
      ))}
    </select>
  );

  const renderAnswer = (question, index) => {
    const answer = answers?.find((ans) => ans.question === question?.code);
    const isInsurance = question?.code === InsuranceCode;

    let boolAnswer = '--';
    if (isInsurance && answers.length) {
      boolAnswer = insuranceAnswers.length ? 'Yes' : 'No';
    } else if (answer?.boolAnswer !== null && answer?.boolAnswer !== undefined) {
      boolAnswer = answer.boolAnswer ? 'Yes' : 'No';
    }

    return (
      <tr key={question?.question}>
        <td
          style={{
            padding: 30,
            fontSize: 16,
            border: '1px solid #EDEDED',
            width: '45%',
            verticalAlign: 'top',
          }}
        >
          <ol>
            <li value={index + 1}>{question?.title}</li>
          </ol>
        </td>
        <td
          style={{
            padding: 30,
            fontSize: 16,
            border: '1px solid #EDEDED',
            width: '10%',
            verticalAlign: 'top',
            textAlign: 'center',
          }}
        >
          {boolAnswer}
        </td>
        <td
          style={{
            padding: 30,
            fontSize: 16,
            border: '1px solid #EDEDED',
            width: '45%',
            verticalAlign: 'top',
          }}
        >
          {isInsurance ? (
            <MedMalInsuranceCarrier data={insuranceAnswers} />
          ) : (
            answer?.textAnswer || '--'
          )}
        </td>
      </tr>
    );
  };

  return (
    <div
      className="card mt-3 ml-4 mr-4 mb-3"
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '90%',
        padding: '20px 90px',
      }}
    >
      <h1 className="global_font f-dark" style={{ fontSize: 30, textAlign: 'center' }}>
        MedMal Survey
      </h1>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: 150,
        }}
      >
        <h4 className="card-title">Survey Status: </h4>
        {renderStatusOption()}
      </div>

      <div className="mt-5 mb-5">
        <table style={{ width: '100%' }}>
          {allQuestions.map((question, index) => renderAnswer(question, index))}
        </table>
      </div>
    </div>
  );
}
