import { METRICS_ACTION } from '../actions/actionTypes';

const defaultState = {
  badges: [],
  loyalty: [],
};

export default (state = defaultState, action) => {
  switch (action.type) {
  case METRICS_ACTION.FETCH_HYG_BADGES_SUCCESS: {
    return {
      ...state,
      badges: action.payload,
    };
  }

  case METRICS_ACTION.FETCH_HYG_LOYALTY_LEVEL_SUCCESS: {
    return {
      ...state,
      loyalty: action.payload ?? [],
    };
  }

  default:
    return state;
  }
};
