import {
  CMS_FETCH_USERS_SUCCESS,
  CMS_FETCH_USERS_REPORT_SUCCESS,
  INDEED_FETCH_USERS_REPORT_SUCCESS,
  CMS_CLEAR_USERS_REPORT,
} from '../actions/actionTypes';

const defaultState = {
  users: [],
  totalUsers: 0,
  columns: [],
  report: [],
  indeedUsers: [],
};

export default (state = defaultState, action) => {
  switch (action.type) {
  case CMS_FETCH_USERS_SUCCESS:
  case INDEED_FETCH_USERS_REPORT_SUCCESS:
    const users = action.payload.response.map((obj) => {
      if (obj.resume_posted) {
        const date = new Date(obj.resume_posted).toLocaleDateString();
        return {
          ...obj,
          resume_posted: date,
        };
      }
      return obj;
    });
    let res = [];

    if (action.payload.page === 1) {
      const { keys } = action.payload;
      const indexReport = keys.findIndex((el) => el === 'report');
      res = keys.slice(0, indexReport).concat(keys.slice(indexReport + 1), ['report']);
    }

    return {
      ...state,
      users,
      totalUsers: action.payload.total,
      page: action.payload.page,
      columns: action.payload.page === 1 ? res : state.columns,
      report: [],
    };

  case CMS_FETCH_USERS_REPORT_SUCCESS:
    return {
      ...state,
      report: action.payload,
    };

  case CMS_CLEAR_USERS_REPORT:
    return {
      ...state,
      report: [],
    };

  default:
    return state;
  }
};
