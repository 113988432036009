import React from 'react';
import { Spinner } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useOfficeAdminsList } from './hooks';
import { CreateNewButton } from '../../OfficeAdmin/CreateNewButton';

export const OfficeAdminsTab = () => {
  const { id: dsoId } = useParams();
  const { data, isLoading, handleScrollList, isInitialLoading, handleViewStaffClick } =
    useOfficeAdminsList(dsoId);

  return (
    <div
      className="card mt-3 ml-4 mr-4 mb-3"
      style={{
        height: '100%',
        display: 'flex',
        width: '80%',
        paddingBottom: 40,
      }}
    >
      <span
        style={{
          fontSize: 24,
          marginTop: 20,
          textAlign: 'center',
        }}
      >
        DSO Admins
      </span>
      <div
        style={{
          margin: '0 80px',
        }}
      >
        {isInitialLoading && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: 400,
            }}
          >
            <Spinner
              animation="border"
              role="status"
              variant="info"
              style={{
                height: 40,
                width: 40,
              }}
            />
          </div>
        )}
        {!isInitialLoading && (
          <>
            <div
              style={{
                display: 'flex',
                gap: 12,
                justifyContent: 'flex-end',
                width: '100%',
              }}
             >
              <CreateNewButton />
            </div>
            <div
            className="card"
            style={{
              width: '100%',
              height: 500,
              overflowY: 'scroll',
              overflowX: 'scroll',
              marginTop: 40,
            }}
            onScroll={handleScrollList}
          >
            <table>
              <tr
                style={{
                  width: '100%',
                  borderBottom: '1px solid #efefef',
                  padding: '5px 10px',
                  backgroundColor: 'lightblue',
                }}
              >
                <td className="table-col-4 card-text table-title-text">#</td>
                <td className="table-col-8 card-text table-title-text">Email</td>
                <td className="table-col-12 card-text table-title-text">Full Name</td>
              </tr>
              {data.map((item) => (
                <tr
                  key={item.id}
                  style={{
                    width: '100%',
                    borderBottom: '1px solid #efefef',
                    padding: '5px 10px',
                    alignItems: 'center',
                  }}
                >
                  <td
                    className="card-text"
                    onClick={() => handleViewStaffClick(item.id)}
                    style={{
                      cursor: 'pointer',
                      textAlign: 'center',
                      textDecoration: 'underline',
                    }}
                  >
                      View Staff
                    </td>
                    <td className="card-text table-value-text">{item.email}</td>
                    <td className="card-text table-value-text">
                      {item.firstName} {item.lastName}
                    </td>
                  </tr>
              ))}
            </table>
            {isLoading && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: 10,
                  marginBottom: 20,
                }}
              >
                <Spinner
                  animation="border"
                  role="status"
                  variant="info"
                  style={{
                    height: 30,
                    width: 30,
                    marginLeft: 20,
                    marginTop: 10,
                  }}
                />
              </div>
            )}
            {!data.length && (
              <div
                style={{
                  height: 200,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center',
                }}
              >
                There are no Office Admins
              </div>
            )}
          </div>
          </>
        )}
      </div>
    </div>
  );
};
