import React, { useState, useRef, useEffect } from 'react';
import '../../themes/MultiSelect.css';
import useDebounce from '../../hooks/useDebounce';

const MultiSelect = ({
  options = [],
  value = [],
  onChange,
  className,
  style,
  optionsStyle,
  placeholder,
  alwaysShowPlaceholder = false,
  autoApply = true,
  showAllButton = false,
  debounceTime = 500,
  Icon,
  maxSelection,
}) => {
  const [selectedOptions, setSelectedOptions] = useState(value || []);

  const [isOptionsVisible, setIsOptionsVisible] = useState(false);
  const optionsRef = useRef(null);

  const handleToggleOptions = (event) => {
    if (Array.from(event.target.classList).includes('multiselect__apply')) {
      setIsOptionsVisible(false);
    } else {
      setIsOptionsVisible(true);
    }
  };

  const handleOutsideClick = (event) => {
    if (optionsRef.current && !optionsRef.current.contains(event.target)) {
      setIsOptionsVisible(false);
    }
  };

  const emitOnChange = (options) => {
    if (onChange) {
      onChange(options);
    }
  };

  const emitOnChangeDebounced = useDebounce(emitOnChange, debounceTime);

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    if (autoApply) {
      emitOnChangeDebounced(selectedOptions);
    }
  }, [selectedOptions]);

  // inner state of `selectedOptions` breaking the `value` change, useEffect is needed
  // refactor might be needed to remove `selectedOptions` redundancy
  useEffect(() => {
    setSelectedOptions(value);
  }, [value]);

  const renderPlaceholder = () => {
    if (alwaysShowPlaceholder || value.length === 0) {
      return <span className="multiselect__placeholder">{placeholder || 'Select'}</span>;
    }

    if (value.length === options.length) {
      return <span className="multiselect__placeholder">All</span>;
    }

    return value.map((option) => {
      const selectedOption = options?.find((opt) => opt.value === option);

      return (
        <span key={option} className="multiselect__selected-option">
          {selectedOption?.label || option}
        </span>
      );
    });
  };

  return (
    <button
      type="button"
      onClick={handleToggleOptions}
      className={`multiselect ${className}`}
      style={style}
      title={value
        ?.map((option) => {
          const selectedOption = options?.find((opt) => opt.value === option);
          return selectedOption?.label || option;
        })
        .join(', ')}
    >
      <div className="multiselect__selected">
        {Icon && (
          <img
            src={Icon}
            style={{ objectFit: 'contain', marginRight: 5, marginBottom: 2 }}
            alt="custom_toggle_icon"
          />
        )}

        {renderPlaceholder()}
      </div>

      {isOptionsVisible && (
        <div ref={optionsRef} className="multiselect__options_wrapper" style={optionsStyle}>
          {showAllButton && (
            <div className="multiselect__top_buttons">
              <span
                className="multiselect__all"
                onClick={() => {
                  if (selectedOptions.length === options.length) {
                    setSelectedOptions([]);
                    return;
                  }

                  setSelectedOptions(options.map((option) => option.value));
                }}
              >
                <input
                  name="checkAll"
                  type="checkbox"
                  checked={selectedOptions.length === options.length}
                />
                All
              </span>
            </div>
          )}

          <ul className="multiselect__options">
            {options.map((option) => (
              <li
                key={option.value}
                className={`multiselect__option ${
                  selectedOptions.includes(option.value) ? 'multiselect__option--selected' : ''
                }`}
                onClick={() => {
                  setSelectedOptions((prevSelectedOptions) => {
                    const isSelected = prevSelectedOptions.includes(option.value);
                    let updatedOptions = [];

                    if (isSelected) {
                      // Remove the option from the list
                      updatedOptions = prevSelectedOptions.filter(
                        (prevOption) => prevOption !== option.value,
                      );
                    } else if (
                      (maxSelection && prevSelectedOptions.length < maxSelection) ||
                      !maxSelection
                    ) {
                      // Add the option to the list
                      updatedOptions = [...prevSelectedOptions, option.value];
                    } else if (maxSelection && prevSelectedOptions.length >= maxSelection) {
                      updatedOptions = [...prevSelectedOptions];
                    }

                    return updatedOptions;
                  });
                }}
              >
                <input
                  name={option.value}
                  type="checkbox"
                  checked={selectedOptions.includes(option.value)}
                />
                {option.label}
              </li>
            ))}
          </ul>

          {!autoApply && (
            <div className="multiselect__bottom_buttons">
              <button
                disabled={
                  selectedOptions.length === 0 ||
                  JSON.stringify(selectedOptions) === JSON.stringify(value)
                }
                className="btn btn-info multiselect__apply"
                type="button"
                onClick={() => {
                  emitOnChange(selectedOptions);
                  setIsOptionsVisible(false);
                }}
              >
                Apply
              </button>
            </div>
          )}
        </div>
      )}
    </button>
  );
};

export default MultiSelect;
