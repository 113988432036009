import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactQuill from 'react-quill'; // ES6
import 'react-quill/dist/quill.snow.css';
import Modal from 'react-modal';
import Spinner from 'react-bootstrap/Spinner';
import Compressor from 'compressorjs';
import NPSInfo from './NPSInfo';
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import EditTab from '../Job/Edits/EditTab';
import NotesTab from './NotesCard';
import ChildOffices from './ChildOffices';

import Styles from '../../../themes/style.module.scss';
import BlockedHygienist from './BlockedHygienist';
import ProfileHygienistGeneralInfo from './ProfileHygienistGeneralInfo';
import ProfileDentistGeneralInfo from './ProfileDentistGeneralInfo';

import CustomPaymentMethodsList from '../../commonComponents/CustomPaymentMethodsList';
import { UserPPJobList } from '../../commonComponents/UserPPJobList';
import NotificationSettings from '../../commonComponents/NotificationSettings';
import FavoritesList from '../../commonComponents/FavoritesList';
import NotificationSettingsHYG from '../../commonComponents/NotificationSettingsHYG';
import { UserJobList } from '../../commonComponents/UserJobList/UserJobList';
import { UserOfferList } from '../../commonComponents/UserOfferList';

import {
  addUserNote,
  deleteCurrentUser,
  fetchChildOffices,
  fetchCurrentUser,
  fetchProfessionalStats,
  fetchSpecificUser,
  fetchUserNotes,
  fetchUsersAround,
  getUserOffers,
  getUserReceipts,
  resetUserPassword,
  sendEmail,
  sendUserMFA,
  setCurrentUser,
  updateAccountStanding,
  updateCurrentUser,
  updateCurrentUserPayment,
  updateRamVerifiedStatus,
  updateUserAvatar,
  updateUserPicture,
} from '../../../actions/userActions';

import { fetchStateCredentials } from '../../../actions/licensesAction';

import {
  getAllPayment,
  getReceivingAccounts,
  selectDNTPaymentMethod,
  selectHygMarketingPaymentMethod,
  selectHygPaymentMethod,
} from '../../../actions/paymnetsAction';
import ProfilePaymentDNT from './ProfilePaymentDNT';

import { emailTemplate1, emailTemplate2 } from '../../../assets/emailTemplates';
import { createErrorMessageSelector, createLoadingSelector } from '../../../apis/selectors';
import LicenseCredentialTab from './LicenseCredentialTab';
import ReceiptList from './Receipts/ReceiptList';
import Invoices from './invoices';
import BlockedByOffices from './BlockedByOffices';
import UserReview from './UserReview';
import UserMap from './Profile/UserMap';
import ProfileSkills from './ProfileSkills';
import MedMalSurvey from '../../commonComponents/MedMalSurvey';
import ResetPasswordPopup from './ResetPasswordPopup';
import WorkerClassification from '../../commonComponents/WorkerClassification';
import { toggleTabState } from './utils';

import StarIcon from '../../../images/star_Green.png';
import ToothIcon from '../../../images/HY_tooth.png';
import OfficeAvatarIcon from '../../../images/office-avatar.svg';
import { getShouldUseMetricsService } from '../../../growthbook';
import { Badges } from '../../commonComponents/Badges';
import HYGFavoritesList from '../../commonComponents/HYGFavoritesList';
import HighlyRatedList from '../../commonComponents/HighlyRatedList/HighlyRatedList';
import { fetchProfessionalLoyaltyLevel } from '../../../actions/metricsAction';
import { DNTTabs } from './DNTTabs/DNTTabs';

import '../../../themes/Profile.scss';

let modalHeight;
// First, checks if it isn't implemented yet.
if (!String.prototype.format) {
  String.prototype.format = function() {
    const args = arguments;
    return this.replace(/{(\d+)}/g, (match, number) =>
      typeof args[number] !== 'undefined' ? args[number] : match,
    );
  };
}

class Profile extends Component {
  constructor(props) {
    super(props);

    this.textInput = React.createRef();

    this.state = {
      // all available tabs
      generalTab: true,
      licenses: false,
      skills: false,
      paymentTab: false,
      invoicesTab: false,
      jobsTab: false,
      offersTab: false,
      mapTab: false,
      editsTab: false,
      officeReciptsTab: false,
      blockedTab: false,
      npsTab: false,
      notesTab: false,
      childOfficesTab: false,
      reviewTab: false,
      medMalTab: false,
      ppJobsTab: false,
      notificationsTab: false,
      favoritesTab: false,
      workerClassificationTab: false,
      highlyRatedTab: false,
      badgesTab: false,

      // screen states
      profileType: this.props.match.params.profileType,
      modalIsOpen: false,
      email: '',
      verify: false,
      deleted: false,
      emailSubject: 'Your TempMee account has been approved!',
      selectedEmail: '',
      showResetPasswordPopup: false,
      passwordResetCode: '',
      newPassword: '',
    };
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  async componentDidMount() {
    const {
      fetchCurrentUser,
      history,
      match,
      fetchStateCredentials,
      stateCredentials,
      fetchProfessionalLoyaltyLevel,
    } = this.props;

    const tabState = toggleTabState(match.params.currentTab);

    // if no tab is selected, redirect to general tab
    if (!Object.values(tabState).some((val) => val)) {
      history.replace(`/user/${match.params.profileType}/${match.params.id}`);
    }

    this.setState(tabState);

    if (JSON.stringify(stateCredentials) === '{}') {
      fetchStateCredentials();
    }

    const userProfileId =
      match.params.profileType && match.params.id
        ? match.params.id
        : history.location.state.user_id;

    await fetchCurrentUser(userProfileId);
    fetchProfessionalLoyaltyLevel([userProfileId]);

    this.fetchData();
  }

  async componentDidUpdate(prevProps) {
    const { fetchCurrentUser, fetchProfessionalStats, history, match } = this.props;

    if (
      match.params.profileType !== prevProps.match.params.profileType ||
      match.params.id !== prevProps.match.params.id
    ) {
      await fetchCurrentUser(
        match.params.profileType && match.params.id
          ? match.params.id
          : history.location.state.user_id,
      );
    }

    if (match.params.currentTab !== prevProps.match.params.currentTab) {
      const tabState = toggleTabState(match.params.currentTab);
      this.setState(tabState, () => {
        this.fetchData();
      });
    }

    if (
      this.props.userProfile?.id !== prevProps.userProfile?.id &&
      this.props.userProfile?.user_type === 'HYG'
    ) {
      fetchProfessionalStats(this.props.userProfile.id);
    }
  }

  fetchData = () => {
    const {
      userProfile,
      getReceivingAccounts,
      getAllPayment,
      fetchUserNotes,
      fetchChildOffices,
      getUserOffers,
    } = this.props;

    if (this.state.paymentTab) {
      if (userProfile?.user_type === 'HYG') {
        getReceivingAccounts();
      } else {
        getAllPayment();
      }
    } else if (this.state.notesTab) {
      fetchUserNotes(userProfile.id);
    } else if (this.state.childOfficesTab) {
      fetchChildOffices(userProfile.id);
    } else if (this.state.offersTab) {
      getUserOffers({
        userId: userProfile?.id,
        licenseTypes: userProfile?.licenseTypes,
      });
    }
  };

  handleAvatarChange = async (event) => {
    if (!event.target.files[0]) {
      return;
    }

    new Compressor(event.target.files[0], {
      quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
      maxWidth: 1024,
      maxHeight: 1024,
      success: (compressedResult) => {
        this.props.updateUserAvatar(compressedResult);
      },
      error(err) {
        console.log(err.message);
      },
    });
  };

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  afterOpenModal() {
    // references are now sync'd and can be accessed.
    this.subtitle.style.color = '#808080';
  }

  closeModal() {
    this.setState({ verify: false, modalIsOpen: false, deleted: false });
  }

  sendEmailAndNotification = () => {
    this.setState({
      modalIsOpen: false,
      verify: false,
    });
    const { selectedEmail, emailSubject } = this.state;
    this.props.sendEmail(emailSubject, selectedEmail);
  };

  deleteAccount = () => {
    this.setState({
      modalIsOpen: false,
      deleted: false,
    });
    // Send Delete Request
    this.props.deleteCurrentUser();
  };

  verify() {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <h2
          ref={(subtitle) => (this.subtitle = subtitle)}
          className={Styles.h3}
          style={{ fontSize: '24px' }}
        >
          Verification
        </h2>
        <div style={{ width: '80%' }}>
          <ReactQuill value={this.state.email} onChange={this.handleChange} theme="snow" />
        </div>
        <div
          style={{
            display: 'flex',
            width: '60%',
            justifyContent: 'space-between',
            marginTop: '20px',
          }}
        >
          <button
            className={Styles.btn_selected}
            style={{
              backgroundColor: 'red',
              width: '45%',
              height: '34px',
              margin: '0',
            }}
            onClick={this.closeModal}
            type="button"
          >
            Cancel
          </button>
          <button
            className={Styles.btn_selected}
            style={{
              backgroundColor: '#00DD00',
              width: '45%',
              height: '34px',
              margin: '0',
            }}
            type="submit"
            onClick={this.sendEmailAndNotification}
          >
            Send
          </button>
        </div>
      </div>
    );
  }

  showAccountStandingOptions() {
    return (
      <select
        className="custom-select custom-select-lg mb-3"
        style={{ marginTop: '5px', position: 'relative' }}
        value={this.props.userProfile?.account_standing}
        onChange={(e) => this.props.updateAccountStanding({ account_standing: e.target.value })}
      >
        <option className="dropdown-item" value="verified">
          Verified
        </option>
        <option className="dropdown-item" value="restricted">
          Restricted
        </option>
        <option className="dropdown-item" value="paused">
          Paused
        </option>
        <option className="dropdown-item" value="in-review">
          In Review
        </option>
        <option className="dropdown-item" value="blocked">
          Blocked
        </option>
      </select>
    );
  }

  showRamVerifiedOptions() {
    return (
      <select
        className="custom-select custom-select-lg mb-3"
        style={{ marginTop: '5px', position: 'relative' }}
        value={this.props.userProfile?.office_info?.ramVerified}
        onChange={(e) =>
          this.props.updateRamVerifiedStatus({
            ramVerified: e.target.value,
          })
        }
        disabled={this.props.userProfile?.office_info?.ramVerified === 'verified'}
      >
        <option className="dropdown-item" value="verified">
          Verified
        </option>
        <option className="dropdown-item" value="unverified">
          Unverified
        </option>
        <option className="dropdown-item" value="fake">
          Fake
        </option>
      </select>
    );
  }

  handleSubjectChange = (event) => {
    this.setState({ emailSubject: event.target.value });
  };

  handleChange = (value) => {
    this.setState({ selectedEmail: value });
  };

  openVerify = () => {
    this.setState({ verify: true, modalIsOpen: true });
  };

  openDelete = () => {
    this.setState({ deleted: true, modalIsOpen: true });
  };

  showVerify() {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <h2
          ref={(subtitle) => (this.subtitle = subtitle)}
          className={Styles.h3}
          style={{ fontSize: '24px' }}
        >
          Verification
        </h2>

        <div
          style={{
            width: '80%',
            padding: 2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <input
            className="form-control mb-1"
            style={{ width: '100%' }}
            value={this.state.emailSubject}
            type="text"
            onChange={(e) => this.handleSubjectChange(e)}
          />
        </div>

        <div style={{ width: '80%' }}>
          <ReactQuill
            intialValue=""
            value={this.state.selectedEmail}
            onChange={(content) => this.handleChange(content)}
            theme="snow"
          />
        </div>
        {this.emailTemplateSelection()}
        <div
          style={{
            display: 'flex',
            width: '60%',
            justifyContent: 'space-between',
            marginTop: '20px',
          }}
        >
          <button
            className="btn btn-info"
            style={{
              backgroundColor: 'red',
              width: '45%',
              height: '34px',
              margin: '0',
            }}
            type="button"
            onClick={this.closeModal}
          >
            Cancel
          </button>
          <button
            className="btn btn-info"
            style={{
              backgroundColor: '#00DD00',
              width: '45%',
              height: '34px',
              margin: '0',
            }}
            type="submit"
            onClick={this.sendEmailAndNotification}
          >
            Send
          </button>
        </div>
      </div>
    );
  }

  emailTemplateSelection() {
    return (
      <div style={{ display: 'flex' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <input
            style={{ margin: 0 }}
            type="radio"
            id="huey"
            name="drone"
            value="0"
            onChange={(e) => this.changeTemplate(e.target.value)}
          />
          <label style={{ margin: 0, paddingLeft: 5 }}>Email 1</label>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginLeft: 5,
          }}
        >
          <input
            style={{ margin: 0 }}
            type="radio"
            id="black"
            name="drone"
            value="1"
            onChange={(e) => this.changeTemplate(e.target.value)}
          />
          <label style={{ margin: 0, paddingLeft: 5 }}>Email 2</label>
        </div>
      </div>
    );
  }

  changeTemplate(template) {
    const templateArr = [
      emailTemplate1.format(this.props.userProfile.first_name),
      emailTemplate2.format(this.props.userProfile.first_name),
    ];
    this.setState({ selectedEmail: templateArr[Number(template)] });
  }

  showDeleted() {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <h2
          ref={(subtitle) => (this.subtitle = subtitle)}
          className={Styles.h3}
          style={{ fontSize: '24px' }}
        >
          Delete Account?
        </h2>
        <div style={{ width: '80%' }}>
          <h3 className={Styles.h3} style={{ fontSize: '20px', textAlign: 'center' }}>
            Are you sure you want to delete this account?
          </h3>
        </div>
        <div
          style={{
            display: 'flex',
            width: '60%',
            justifyContent: 'space-between',
            marginTop: '20px',
          }}
        >
          <button
            className="btn btn-info"
            style={{
              backgroundColor: 'gray',
              width: '45%',
              height: '34px',
              margin: '0',
            }}
            onClick={() => this.closeModal()}
          >
            Cancel
          </button>
          <button
            className="btn btn-info"
            style={{
              backgroundColor: 'red',
              width: '45%',
              height: '34px',
              margin: '0',
            }}
            onClick={() => this.deleteAccount()}
          >
            Delete
          </button>
        </div>
      </div>
    );
  }

  paymentDNT() {
    const {
      allPaymentMethods,
      userProfile,
      selectDNTPaymentMethod,
      updateCurrentUserPayment,
      isPreApproveUser,
    } = this.props;

    return (
      <ProfilePaymentDNT
        accounts={allPaymentMethods}
        isPaymentSelected={(id) => selectDNTPaymentMethod(id)}
        preApproved={userProfile.is_pre_approved}
        onPreApprovedChanged={(isPreApproved) => {
          updateCurrentUserPayment({ is_pre_approved: isPreApproved === 'true' });
        }}
        onReceiptEnabledChanged={(isReceiptEnabled) => {
          updateCurrentUserPayment({ receipt_enabled: isReceiptEnabled });
        }}
        receiptEnabled={userProfile.receipt_enabled}
        preApproveUser={isPreApproveUser}
      />
    );
  }

  paymentHYG() {
    const {
      allPaymentMethods,
      selectHygPaymentMethod,
      allPaymentMethodsForReferral,
      selectHygMarketingPaymentMethod,
    } = this.props;

    return (
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-evenly',
          alignItems: 'center',
          marginTop: '25px',
          marginBottom: '100px',
        }}
      >
        <div
          className="card"
          style={{
            margin: 25,
            width: '60%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <h3
            className="card-title"
            style={{
              fontFamily: 'Nunito',
              fontWeight: 700,
              textAlign: 'center',
              color: '#435963',
            }}
          >
            Payment Methods
          </h3>
          <CustomPaymentMethodsList
            accounts={allPaymentMethods || []}
            isPaymentSelected={(id) => selectHygPaymentMethod(id)}
          />
        </div>
        <div
          className="card"
          style={{
            margin: 25,
            width: '60%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <h3
            className="card-title"
            style={{
              fontFamily: 'Nunito',
              fontWeight: 700,
              textAlign: 'center',
              color: '#435963',
            }}
          >
            Marketing Payment Methods
          </h3>
          <CustomPaymentMethodsList
            accounts={allPaymentMethodsForReferral || []}
            isPaymentSelected={(id) => selectHygMarketingPaymentMethod(id)}
          />
        </div>
      </div>
    );
  }

  blockedHygienist() {
    if (this.props.userProfile) {
      return <BlockedHygienist dentist={this.props.userProfile} history={this.props.history} />;
    }

    return <div />;
  }

  changeTab(tab) {
    const { userProfile, history, match } = this.props;
    const { profileType } = this.state;

    const newTab = tab === 'general' ? '' : tab;

    // if change to the same tab, reload data
    if (match.params.currentTab === newTab) {
      this.fetchData();
    } else {
      history.push(`/user/${profileType}/${userProfile?.id}/${newTab}`);
    }
  }

  goToJobProfile = (job) => this.props.history.push(`/job/${job.id}`, { job_id: job.id });

  updateStateId = async (image) => {
    if (!image) {
      return;
    }
    new Compressor(image, {
      quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
      maxWidth: 1024,
      maxHeight: 1024,
      success: (compressedResult) => {
        this.props.updateUserPicture(compressedResult);
      },
      error(err) {
        console.log(err.message);
      },
    });
  };

  tabSelection = () => {
    const {
      isLoading,
      userProfile,
      newStateId,
      addUserNote,
      updateCurrentUser,
    } = this.props;

    if (isLoading) {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spinner
            animation="border"
            role="status"
            variant="info"
            style={{
              height: 30,
              width: 30,
              marginLeft: 20,
              marginTop: 10,
              alignSelf: 'center',
            }}
          />
        </div>
      );
    }
    if (this.state.jobsTab) {
      return <UserJobList />;
    }
    if (this.state.offersTab) {
      return <UserOfferList />;
    }

    if (userProfile?.user_type === 'HYG') {
      if (this.state.generalTab) {
        return this.hygienistProfile();
      }

      if (this.state.licenses) {
        return (
          <LicenseCredentialTab
            user_profile={userProfile}
            allProfessions={this.props.allProfessions}
            onSaveClicked={({ stateIdStatus }) => {
              updateCurrentUser({
                hyg_ident_status: stateIdStatus,
              });
            }}
            stateCredentials={this.props.stateCredentials}
          />
        );
      }
      if (this.state.skills) {
        return <ProfileSkills />;
      }
      if (this.state.paymentTab) {
        return this.paymentHYG();
      }

      if (this.state.npsTab) {
        return <NPSInfo npsInfo={userProfile.nps_reviews} />;
      }

      if (this.state.editsTab) {
        return <EditTab editList={userProfile?.edits} isUserEdits />;
      }
      if (this.state.notesTab) {
        return (
          <NotesTab
            notes={userProfile?.notes || []}
            userId={userProfile?.id}
            submitNote={addUserNote}
            currentUser={userProfile}
            isProfessional
          />
        );
      }
      if (this.state.blockedTab) {
        return <BlockedByOffices />;
      }
      if (this.state.reviewTab) {
        return <UserReview goToJobProfile={this.goToJobProfile} />;
      }
      if (this.state.mapTab) {
        return <UserMap />;
      }
      if (this.state.notificationsTab) {
        return <NotificationSettingsHYG />;
      }
      if (this.state.medMalTab) {
        return <MedMalSurvey />;
      }
      if (this.state.badgesTab) {
        return <Badges />;
      }
      if (this.state.favoritesTab) {
        return <HYGFavoritesList />;
      }
    } else {
      if (this.state.generalTab) {
        return this.dentistProfile();
      }
      if (this.state.paymentTab) {
        return this.paymentDNT();
      }
      if (this.state.invoicesTab) {
        return <Invoices />;
      }
      if (this.state.npsTab) {
        return <NPSInfo npsInfo={userProfile.nps_reviews} />;
      }
      if (this.state.ppJobsTab) {
        return <UserPPJobList />;
      }
      if (this.state.notificationsTab) {
        return <NotificationSettings />;
      }
      if (this.state.favoritesTab) {
        return <FavoritesList />;
      }

      if (this.state.workerClassificationTab) {
        return <WorkerClassification />;
      }
      if (this.state.highlyRatedTab) {
        return <HighlyRatedList />;
      }
      if (this.state.blockedTab) {
        return this.blockedHygienist();
      }
      if (this.state.editsTab) {
        return <EditTab editList={userProfile?.edits} isUserEdits />;
      }
      if (this.state.notesTab) {
        return (
          <NotesTab
            notes={userProfile?.notes || []}
            userId={userProfile?.id}
            submitNote={addUserNote}
          />
        );
      }
      if (this.state.childOfficesTab) {
        return <ChildOffices />;
      }
      if (this.state.officeReciptsTab) {
        return <ReceiptList />;
      }
      if (this.state.reviewTab) {
        return <UserReview goToJobProfile={this.goToJobProfile} />;
      }
      if (this.state.mapTab) {
        return <UserMap />;
      }
    }
  };

  hygienistProfile() {
    return <ProfileHygienistGeneralInfo openVerify={this.openVerify} />;
  }

  dentistProfile() {
    return <ProfileDentistGeneralInfo openVerify={this.openVerify} />;
  }

  getUserInfo() {
    const { userProfile, cityOfUserAddress } = this.props;
    const { first_name: firstName, last_name: lastName } = userProfile || {};

    if (!userProfile) {
      return <div />;
    }

    if (userProfile?.user_type === 'HYG') {
      return (
        <h2
          className="card-title"
          style={{
            position: 'relative',
            margin: '0px',
            color: 'white',
          }}
        >
          {' '}
          {'DENTAL PROFESSIONAL | '}
          {`${firstName || ''} ${lastName || ''}`}
          {' | '}
          {`${cityOfUserAddress}`}
        </h2>
      );
    }
    return (
      <h2
        className="card-title"
        style={{
          position: 'relative',
          margin: '0px',
          color: 'white',
          fontWeight: 400,
        }}
      >
        {userProfile?.office_name || ''}
        {' | '}
        {`${cityOfUserAddress}`}{' '}
        <img
          src={StarIcon}
          className="card-img-left"
          width="30px"
          alt="star_icon"
          style={{ marginBottom: 10 }}
        />
        {userProfile?.average_rating ? userProfile?.average_rating.toFixed(1) : ''}
      </h2>
    );
  }

  renderProfessionalStats() {
    const {
      userProfile,
      totalCompletedCount,
      totalLateCancellationCount,
      totalNoShowCount,
      attendancePercentage,
      averageRating,
    } = this.props;

    const totalShifts = totalCompletedCount + totalNoShowCount + totalLateCancellationCount;

    if (userProfile?.user_type !== 'HYG') {
      return <></>;
    }

    const statsCard = ({ title, value }) => (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: 154,
          backgroundColor: 'white',
          border: '1px solid #DDDDDD',
        }}
      >
        <h2 style={{ fontSize: 24, marginTop: 10, marginBottom: 3 }}>{value}</h2>

        <p>{title}</p>
      </div>
    );

    return (
      <div
        style={{
          display: 'flex',
          columnGap: 5,
          marginTop: 20,
          marginBottom: 15,
        }}
      >
        {statsCard({ title: 'Total Shifts', value: totalShifts })}
        {statsCard({ title: 'Completed Shifts', value: totalCompletedCount })}
        {statsCard({ title: 'Late Cancels', value: totalLateCancellationCount })}
        {statsCard({ title: 'No Shows', value: totalNoShowCount })}
        {statsCard({
          title: 'Attendance Score',
          value: attendancePercentage ? `${attendancePercentage}%` : '-',
        })}
        {statsCard({
          title: 'AVG Rating',
          value: averageRating?.toFixed(1) ?? '-',
        })}
      </div>
    );
  }

  profileComplete() {
    const { userProfile, sendUserMFA, adminUser } = this.props;

    if (userProfile?.user_type === 'HYG') {
      return (
        <>
          {userProfile?.is_profile_complete &&
          userProfile?.professional_info?.government_id?.photo ? (
            <p
              style={{
                color: 'green',
                fontWeight: 'bold',
                marginRight: '30px',
                marginTop: '10px',
              }}
            >
              Profile Complete
            </p>
          ) : (
            <>
              {userProfile?.is_profile_complete ? null : (
                <button
                  disabled={!!userProfile?.is_profile_complete}
                  style={{ width: '150px', height: 44 }}
                  className={
                    userProfile?.is_profile_complete
                      ? 'btn btn-secondary mr-4'
                      : 'btn btn-success mr-4'
                  }
                  onClick={() => this.props.updateCurrentUser({ is_profile_complete: true })}
                >
                  Finish sign up
                </button>
              )}
            </>
          )}
        </>
      );
    }
    if (userProfile?.user_type === 'DNT') {
      return (
        <>
          {adminUser?.email_id === process.env.REACT_APP_ADMIN_EMAIL_RESET_PASSWORD ? (
            <button
              style={{ width: '130px', height: 44, marginRight: 25 }}
              className="btn btn-info"
              onClick={() => {
                sendUserMFA();
                this.setState({ showResetPasswordPopup: true });
              }}
            >
              Reset password
            </button>
          ) : (
            <div />
          )}
          {userProfile?.is_profile_complete ? (
            <p
              style={{
                color: 'green',
                fontWeight: 'bold',
                marginRight: '30px',
                marginTop: '10px',
              }}
            >
              Profile Complete
            </p>
          ) : (
            <button
              disabled={!!userProfile?.is_profile_complete}
              style={{ width: '150px', height: 44 }}
              className={
                userProfile?.is_profile_complete ? 'btn btn-secondary mr-4' : 'btn btn-success mr-4'
              }
              onClick={() => this.props.updateCurrentUser({ is_profile_complete: true })}
            >
              Finish sign up
            </button>
          )}
        </>
      );
    }
  }

  renderDefaultProfilePicture() {
    const { userProfile } = this.props;

    if (!userProfile) {
      return <div />;
    }

    if (userProfile?.user_type === 'DNT') {
      return <img src={OfficeAvatarIcon} width="100px" alt="default_user_pic" />;
    }

    return (
      <div className={Styles.profile_image_default_circle}>
        <img
          src={userProfile?.user_type === 'DNT' ? OfficeAvatarIcon : ToothIcon}
          width="100px"
          alt="default_user_pic"
          className={Styles.profile_image_default}
        />
      </div>
    );
  }

  render() {
    const { userProfile, isLoading, resetUserPassword, match } = this.props;

    this.state.verify ? (modalHeight = '60%') : (modalHeight = '20%');

    return (
      <div
        style={{
          width: '100vw',
          height: 'calc(100vh - 102px)',
          overflowY: 'scroll',
          overflowX: 'hidden',
        }}
        className="bg-light"
        id="profile-content"
      >
        <div
          className="card bg-light "
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            border: 0,
          }}
        >
          <div
            className="card-body"
            style={{
              backgroundColor: userProfile?.user_type === 'DNT' ? '#435963' : '#307F92',
              width: '100%',
              minHeight: '125px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                width: '90%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginRight: 20,
                  width: '20%',
                }}
                onClick={() => this.textInput.current.click()}
              >
                <input
                  ref={this.textInput}
                  style={{ display: 'none' }}
                  type="file"
                  onChange={(e) => this.handleAvatarChange(e)}
                />
                {userProfile?.profile_image ? (
                  <img
                    src={userProfile?.profile_image}
                    style={{
                      backgroundSize: 'contain',
                      objectFit: 'cover',
                    }}
                    alt="profile"
                    className={Styles.profile_image}
                  />
                ) : (
                  this.renderDefaultProfilePicture()
                )}
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {this.getUserInfo()}
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: '#FAFAFA',
            margin: 0,
            marginBottom: 30,
          }}
        >
          {!isLoading ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <div
                className="card border-0 bg-transparent mr-2"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                {this.renderProfessionalStats()}

                <div
                  className="card-body"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  {this.profileComplete()}
                  {userProfile?.user_type === 'DNT' && userProfile?.parent_office && (
                    <button
                      style={{ width: 200, height: 44 }}
                      className="btn btn-info mr-4"
                      onClick={() =>
                        this.props.history.push(`/user/dentist/${userProfile?.parent_office}`)
                      }
                    >
                      Open Parent Account
                    </button>
                  )}
                  <button
                    className="btn btn-danger mr-4"
                    style={{ width: '150px', height: 44 }}
                    onClick={() => this.openDelete()}
                  >
                    Permanently Delete
                  </button>
                  <div
                    className="mr-4"
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: 150,
                    }}
                  >
                    <h4 className="card-title">Account Status: </h4>
                    {this.showAccountStandingOptions()}
                  </div>
                  {userProfile?.user_type === 'DNT' && (
                    <div
                      className="mr-4"
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: 150,
                      }}
                    >
                      <h4 className="card-title">RAM Verified: </h4>
                      {this.showRamVerifiedOptions()}
                    </div>
                  )}
                </div>
              </div>
              {!userProfile?.professional_info?.government_id?.photo &&
              userProfile?.user_type === 'HYG' ? (
                <p style={{ color: 'red' }}>
                  To complete your profile registration, go to the State I.D. and add a photo
                </p>
              ) : null}
              {userProfile?.user_type === 'DNT' ? (
                <DNTTabs
                  value={match?.params?.currentTab || ''}
                  onValueChanged={(value) => this.changeTab(value)}
                />
              ) : (
                <div
                  style={{
                    marginTop: '10px',
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <button
                    type="button"
                    className="btn btn-info mr-1 ml-1"
                    style={{
                      backgroundColor: this.state.generalTab ? '#4FC0CF' : '#307F92',
                      border: 'none',
                    }}
                    onClick={() => this.changeTab('general')}
                  >
                    General
                  </button>

                  <button
                    type="button"
                    className="btn btn-info mr-1 ml-1"
                    style={{
                      backgroundColor: this.state.paymentTab ? '#4FC0CF' : '#307F92',
                      border: 'none',
                    }}
                    onClick={() => this.changeTab('payment')}
                  >
                    Payment
                  </button>

                  {userProfile?.user_type === 'DNT' ? (
                    <button
                      type="button"
                      className="btn btn-info mr-1 ml-1"
                      style={{
                        backgroundColor: this.state.invoicesTab ? '#4FC0CF' : '#307F92',
                        border: 'none',
                      }}
                      onClick={() => this.changeTab('invoices')}
                    >
                      Invoices
                    </button>
                  ) : null}

                  <button
                    type="button"
                    className="btn btn-info mr-1 ml-1"
                    style={{
                      backgroundColor: this.state.npsTab ? '#4FC0CF' : '#307F92',
                      border: 'none',
                    }}
                    onClick={() => this.changeTab('nps')}
                  >
                    NPS
                  </button>
                  <button
                    type="button"
                    className="btn btn-info mr-1 ml-1"
                    style={{
                      backgroundColor: this.state.editsTab ? '#4FC0CF' : '#307F92',
                      border: 'none',
                    }}
                    onClick={() => this.changeTab('edits')}
                  >
                    Edits
                  </button>
                  <button
                    type="button"
                    className="btn btn-info mr-1 ml-1"
                    style={{
                      backgroundColor: this.state.jobsTab ? '#4FC0CF' : '#307F92',
                      border: 'none',
                    }}
                    onClick={() => this.changeTab('jobs')}
                  >
                    Jobs
                  </button>
                  {userProfile?.user_type === 'HYG' && (
                    <button
                      type="button"
                      className="btn btn-info mr-1 ml-1"
                      style={{
                        backgroundColor: this.state.offersTab ? '#4FC0CF' : '#307F92',
                        border: 'none',
                      }}
                      onClick={() => this.changeTab('offers')}
                    >
                      Offers
                    </button>
                  )}
                  <button
                    type="button"
                    className="btn btn-info mr-1 ml-1"
                    style={{
                      backgroundColor: this.state.mapTab ? '#4FC0CF' : '#307F92',
                      border: 'none',
                    }}
                    onClick={() => this.changeTab('mapTab')}
                  >
                    Map
                  </button>
                  <button
                    type="button"
                    className="btn btn-info mr-1 ml-1"
                    style={{
                      backgroundColor: this.state.notesTab ? '#4FC0CF' : '#307F92',
                      border: 'none',
                    }}
                    onClick={() => this.changeTab('notes')}
                  >
                    Notes
                  </button>
                  {userProfile?.user_type === 'DNT' && (
                    <button
                      type="button"
                      className="btn btn-info mr-1 ml-1"
                      style={{
                        backgroundColor: this.state.childOfficesTab ? '#4FC0CF' : '#307F92',
                        border: 'none',
                      }}
                      onClick={() => this.changeTab('childOffices')}
                    >
                      Child Offices
                    </button>
                  )}
                  <button
                    type="button"
                    className="btn btn-info mr-1 ml-1"
                    style={{
                      backgroundColor: this.state.reviewTab ? '#4FC0CF' : '#307F92',
                      border: 'none',
                    }}
                    onClick={() => this.changeTab('reviewTab')}
                  >
                    Reviews
                  </button>
                  <button
                    type="button"
                    className="btn btn-info mr-1 ml-1"
                    style={{
                      backgroundColor: this.state.blockedTab ? '#4FC0CF' : '#307F92',
                      border: 'none',
                    }}
                    onClick={() => this.changeTab('blocked')}
                  >
                    Blocked
                  </button>
                  {userProfile?.user_type === 'DNT' ? (
                    <div>
                      <button
                        type="button"
                        className="btn btn-info mr-1 ml-1"
                        style={{
                          backgroundColor: this.state.officeReciptsTab ? '#4FC0CF' : '#307F92',
                          border: 'none',
                        }}
                        onClick={() => this.changeTab('officeReciptsTab')}
                      >
                        Receipts
                      </button>
                      <button
                        type="button"
                        className="btn btn-info mr-1 ml-1"
                        style={{
                          backgroundColor: this.state.ppJobsTab ? '#4FC0CF' : '#307F92',
                          border: 'none',
                        }}
                        onClick={() => this.changeTab('ppJobs')}
                      >
                        PP Jobs
                      </button>
                      <button
                        type="button"
                        className="btn btn-info mr-1 ml-1"
                        style={{
                          backgroundColor: this.state.notificationsTab ? '#4FC0CF' : '#307F92',
                          border: 'none',
                        }}
                        onClick={() => this.changeTab('notifications')}
                      >
                        Notifications
                      </button>
                      <button
                        type="button"
                        className="btn btn-info mr-1 ml-1"
                        style={{
                          backgroundColor: this.state.favoritesTab ? '#4FC0CF' : '#307F92',
                          border: 'none',
                        }}
                        onClick={() => this.changeTab('favorites')}
                      >
                        Favorites
                      </button>
                      <button
                        type="button"
                        className="btn btn-info mr-1 ml-1"
                        style={{
                          backgroundColor: this.state.workerClassificationTab
                            ? '#4FC0CF'
                            : '#307F92',
                          border: 'none',
                        }}
                        onClick={() => this.changeTab('workerClassification')}
                      >
                        Worker Type
                      </button>
                      <button
                        type="button"
                        className="btn btn-info mr-1 ml-1"
                        style={{
                          backgroundColor: this.state.highlyRatedTab ? '#4FC0CF' : '#307F92',
                          border: 'none',
                        }}
                        onClick={() => this.changeTab('highlyRated')}
                      >
                        Highly-Rated
                      </button>
                    </div>
                  ) : (
                    <div>
                      <button
                        type="button"
                        className="btn btn-info mr-1 ml-1"
                        style={{
                          backgroundColor: this.state.licenses ? '#4FC0CF' : '#307F92',
                          border: 'none',
                        }}
                        onClick={() => this.changeTab('licenses')}
                      >
                        Verification
                      </button>
                      <button
                        type="button"
                        className="btn btn-info mr-1 ml-1"
                        style={{
                          backgroundColor: this.state.skills ? '#4FC0CF' : '#307F92',
                          border: 'none',
                        }}
                        onClick={() => this.changeTab('skills')}
                      >
                        Skills
                      </button>
                      <button
                        type="button"
                        className="btn btn-info mr-1 ml-1"
                        style={{
                          backgroundColor: this.state.medMalTab ? '#4FC0CF' : '#307F92',
                          border: 'none',
                        }}
                        onClick={() => this.changeTab('medMalTab')}
                      >
                        MedMal
                      </button>
                      <button
                        type="button"
                        className="btn btn-info mr-1 ml-1"
                        style={{
                          backgroundColor: this.state.notificationsTab ? '#4FC0CF' : '#307F92',
                          border: 'none',
                        }}
                        onClick={() => this.changeTab('notifications')}
                      >
                        Notifications
                      </button>
                      {getShouldUseMetricsService() && (
                        <button
                          type="button"
                          className="btn btn-info mr-1 ml-1"
                          style={{
                            backgroundColor: this.state.badgesTab ? '#4FC0CF' : '#307F92',
                            border: 'none',
                          }}
                          onClick={() => this.changeTab('badges')}
                        >
                          Badges
                        </button>
                      )}
                      <button
                        type="button"
                        className="btn btn-info mr-1 ml-1"
                        style={{
                          backgroundColor: this.state.favoritesTab ? '#4FC0CF' : '#307F92',
                          border: 'none',
                        }}
                        onClick={() => this.changeTab('favorites')}
                      >
                        Favorites
                      </button>
                    </div>
                  )}
                </div>
              )}
              <div
                style={{
                  position: 'relative',
                  width: '100vw',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  marginBottom: 100,
                }}
              >
                {this.tabSelection()}
              </div>
            </div>
          ) : (
            <Spinner
              animation="border"
              role="status"
              variant="info"
              style={{
                height: 30,
                width: 30,
                marginLeft: 20,
                marginTop: 10,
                marginBottom: 10,
                alignSelf: 'center',
              }}
            />
          )}
        </div>

        <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          style={customStyles}
        >
          {this.state.verify ? this.showVerify() : <div />}
          {this.state.deleted ? this.showDeleted() : <div />}
        </Modal>

        <ResetPasswordPopup
          state={this.state}
          setState={(data) => this.setState(data)}
          resetUserPassword={resetUserPassword}
          customStyles={customStyles}
        />
      </div>
    );
  }
}

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    height: modalHeight,
  },
};

const actions = [
  'GET_ALL_PAYMENT',
  'HYG_GET_ALL_PAYMENT',
  'SELECT_CARD_PAYMENT',
  'FETCH_CURRENT_USER',
  'SEND_EMAIL',
  'UPDATE_USER_AVATAR',
  'GET_USER_RECEIPT_PDF',
  'UPDATE_USER_STATE',
  'FETCH_CHILD_OFFICES',
  'FETCH_USER_PP_JOBS',
  'FETCH_HYG_LOYALTY_LEVEL',
];
const loadingSelector = createLoadingSelector(actions);
const errorSelector = createErrorMessageSelector(actions);

const mapStateToProps = (state) => {
  const { users } = state || {};
  const city = users?.currentUser?.address?.[0]?.city || '';

  return {
    userProfile: users.currentUser,
    cityOfUserAddress: city,
    allPaymentMethods: state.payments.allPaymentMethods,
    allPaymentMethodsForReferral: state.payments.allPaymentMethodsForReferral,
    isLoading: loadingSelector(state),
    error: errorSelector(state),
    userJobs: users.userJobs,
    receiptsJobs: users.receiptsJobs,
    receiptsPdfJobs: users.receiptsPdfJobs,
    officeReceipts: users.officeReceipts,
    searchParams: users.searchParams,
    totalJobsCount: users.totalJobsCount,
    adminUser: users.adminUser,
    totalPPJobsCount: users.totalPPJobsCount,
    newStateId: users.newStateId,
    isPreApproveUser: users.isPreApproveUser,
    stateCredentials: state.license.stateCredentials,
    allProfessions: state.jobs.allProfessions,
    totalNoShowCount: users.totalNoShowCount,
    totalCompletedCount: users.totalCompletedCount,
    totalLateCancellationCount: users.totalLateCancellationCount,
    attendancePercentage: users.attendancePercentage,
    averageRating: users.averageRating,
  };
};

export default connect(mapStateToProps, {
  deleteCurrentUser,
  fetchSpecificUser,
  fetchCurrentUser,
  setCurrentUser,
  updateAccountStanding,
  updateCurrentUser,
  updateCurrentUserPayment,
  selectHygPaymentMethod,
  selectHygMarketingPaymentMethod,
  selectDNTPaymentMethod,
  getAllPayment,
  getReceivingAccounts,
  getUserOffers,
  sendEmail,
  sendUserMFA,
  resetUserPassword,
  updateUserAvatar,
  fetchUserNotes,
  fetchChildOffices,
  addUserNote,
  updateUserPicture,
  getUserReceipts,
  fetchUsersAround,
  fetchStateCredentials,
  fetchProfessionalStats,
  updateRamVerifiedStatus,

  // data user inside `LoyaltyCardGroup` component
  fetchProfessionalLoyaltyLevel,
})(Profile);
