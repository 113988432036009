import React from 'react';
import { useSelector } from 'react-redux';

import hygienistIcon from '../../images/hygienist.png';
import dntIcon from '../../images/tooth.png';
import greenCheckIcon from '../../images/green-check.png';
import welcomeImage from '../../images/welcomeImage.png';

export default () => {
  const {
    dentistCount,
    hygienistCount,
    completedJobsCount,
    associateDentistCount,
    dentalAssistantCount,
  } = useSelector((state) => state.userMap);
  const adminUser = useSelector((state) => state.users.adminUser);

  const adminInfo = () => (
    <div
      className="card mt-3 mr-3"
      style={{ alignSelf: 'flex-end', borderRadius: 25, backgroundColor: '#D8F8FF' }}
    >
      <div className="card-body">
        <h4>
          <strong>Current Admin Account:</strong>
        </h4>
        <h4 className="mt-1">{adminUser?.email_id}</h4>
      </div>
    </div>
  );
  return (
    <div
      className="card border-0"
      style={{
        display: 'flex',
        alighItems: 'center',
        width: '100%',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        overflowY: 'scroll',
      }}
    >
      {adminInfo()}

      <div className="card-body">
        <div className="card" style={{ marginTop: 10, maxWidth: 750, borderRadius: 25 }}>
          <div
            className="card-body"
            style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}
          >
            <h3 style={{ marginTop: 20, textAlign: 'center' }}>General Information:</h3>
            <div style={styles.infoLine}>
              <div style={styles.infoLinePic}>
                <img style={{ marginRight: 10 }} src={dntIcon} alt="tooth" />
                <h4 className="card-title">Dental Offices</h4>
              </div>
              <h4 className="card-title">{new Intl.NumberFormat().format(dentistCount)}</h4>
            </div>
            <div style={styles.infoLine}>
              <div style={styles.infoLinePic}>
                <img style={{ marginRight: 10 }} src={hygienistIcon} alt="hygienist" />
                <h4 className="card-title">Dental Hygienist</h4>
              </div>
              <h4 className="card-title">{new Intl.NumberFormat().format(hygienistCount)}</h4>
            </div>
            <div style={styles.infoLine}>
              <div style={styles.infoLinePic}>
                <img style={{ marginRight: 10 }} src={hygienistIcon} alt="hygienist" />
                <h4 className="card-title">Dental Assistants</h4>
              </div>
              <h4 className="card-title">{new Intl.NumberFormat().format(dentalAssistantCount)}</h4>
            </div>
            <div style={styles.infoLine}>
              <div style={styles.infoLinePic}>
                <img style={{ marginRight: 10 }} src={hygienistIcon} alt="hygienist" />
                <h4 className="card-title">Assosiate Dentist</h4>
              </div>
              <h4 className="card-title">
                {new Intl.NumberFormat().format(associateDentistCount)}
              </h4>
            </div>
            <div style={styles.infoLine}>
              <div style={styles.infoLinePic}>
                <img
                  style={{ marginRight: 10, width: 26, height: 26 }}
                  src={greenCheckIcon}
                  alt="jobs-complete"
                />
                <h4 className="card-title">Completed Jobs</h4>
              </div>
              <h4>{new Intl.NumberFormat().format(completedJobsCount)}</h4>
            </div>
          </div>
        </div>
        <div
          className="card border-0"
          style={{ maxWidth: 750, marginTop: 20, alignItems: 'center' }}
        >
          <img style={{ maxWidth: 400 }} src={welcomeImage} alt="welcome" />
        </div>
      </div>
    </div>
  );
};

const styles = {
  infoLine: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '10px 0px',
    width: 500,
  },
  infoLinePic: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 50,
  },
};
