import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getShouldEnableNCNSRepostStates } from '../growthbook';

export const useShouldEnableNCNSRepostStates = () => {
  const offerOwner = useSelector((state) => state.jobs?.currentJob?.offer_owner);

  const checkShouldEnableNCNSRepost = useCallback(() => {
    const ffStates = getShouldEnableNCNSRepostStates();
    const offerOwnerState = offerOwner?.address?.[0]?.state;

    return !!offerOwnerState && !!ffStates?.includes?.(offerOwnerState);
  }, [offerOwner?.address]);

  return {
    checkShouldEnableNCNSRepost,
  };
};
