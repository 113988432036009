import React from 'react';
import AdjustRenderData from './AdjustRenderData';

export default ({ adjustmentList, offerOwnerId, btnAdjClicked, showAdjustmentButton, showAdjustmentPopup }) => (
  <div
    className="card mt-5"
    style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      margin: '50px',
      width: '80%',
    }}
  >
    <div
      className="card-body"
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <h2 className="card-title" style={{ marginLeft: 10, fontFamily: 'Nunito', color: '#494963' }}>
        Adjustments
      </h2>
      <div
        style={{
          display: 'flex',
        }}
      >
        {showAdjustmentButton && (
          <button
            type="button"
            className="btn btn-info"
            onClick={showAdjustmentPopup}
            style={{
              width: 150,
              height: 30,
              borderRadius: 5,
              marginTop: 5,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              boxShadow: '1px 1px 4px',
            }}
          >
            Submit Adjusment
          </button>
        )}
        <div
          style={{
            backgroundColor: '#7e3737',
            height: 40,
            width: 40,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 20,
            marginLeft: 10,
            marginRight: 10,
          }}
        >
          <h3
            className="card-title"
            style={{
              color: '#fff',
              margin: 0,
              padding: 0,
            }}
          >
            {adjustmentList.length}
          </h3>
        </div>
      </div>
    </div>

    <div
      className='w-100'
      style={{
        padding: 10
      }}
    >
      <div
        className='d-flex'
        style={{
          borderBottom: '1px solid #ededed',
        }}
      >
        <div
          className='col-1'
          style={{
            fontFamily: 'Nunito',
            fontWeight: 700,
            textAlign: 'center',
          }}
        >
          Author
        </div>
        <div
          className='col-2'
          style={{
            fontFamily: 'Nunito',
            fontWeight: 700,
            textAlign: 'center',
          }}
        >
          Created At
        </div>
        <div
          className='col-1'
          style={{
            fontFamily: 'Nunito',
            fontWeight: 700,
            textAlign: 'center',
          }}
        >
          Start
        </div>
        <div
          className='col-1'
          style={{
            fontFamily: 'Nunito',
            fontWeight: 700,
            textAlign: 'center',
          }}
        >
          New Start
        </div>
        <div
          className='col-1'
          style={{
            fontFamily: 'Nunito',
            fontWeight: 700,
            textAlign: 'center',
          }}
        >
          End
        </div>
        <div
          className='col-1'
          style={{
            fontFamily: 'Nunito',
            fontWeight: 700,
            textAlign: 'center',
          }}
        >
          New End
        </div>
        <div
          className='col-1'
          style={{
            fontFamily: 'Nunito',
            fontWeight: 700,
            textAlign: 'center',
          }}
        >
          Meal Break
        </div>
        <div
          className='col-1'
          style={{
            fontFamily: 'Nunito',
            fontWeight: 700,
            textAlign: 'center',
          }}
        >
          New Meal Break
        </div>
        <div
          className='col-1'
          style={{
            fontFamily: 'Nunito',
            fontWeight: 700,
            textAlign: 'center',
          }}
        >
          Status
        </div>
        <div className='col-2' />
      </div>
    </div>
    <AdjustRenderData
      adjustmentList={adjustmentList}
      offerOwnerId={offerOwnerId}
      btnAdjClicked={btnAdjClicked}
    />
  </div>
);
