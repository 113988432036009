import React, { useEffect } from 'react';
import {
  GoogleMap,
  withScriptjs,
  withGoogleMap,
  Marker,
  Circle,
  InfoWindow,
} from 'react-google-maps';
import { useDispatch, useSelector } from 'react-redux';

import { Spinner } from 'react-bootstrap';
import { compose, withStateHandlers } from 'recompose';
import iconRDH from '../../images/rdh_icon.svg';
import iconRDA from '../../images/da_icon.svg';
import iconDN from '../../images/dn_icon.svg';
import iconOffice from '../../images/office_icon.svg';
import FrontDeskIcon from '../../images/fd_icon.svg';
import EfdaIcon from '../../images/efda_icon.svg';

import { createLoadingSelector } from '../../apis/selectors';
import { fetchUsersAround } from '../../actions/userActions';
import history from '../../history';

export default ({ filter, radius }) => {
  const loadingSelector = createLoadingSelector(['FETCH_USERS_AROUND', 'FETCH_CURRENT_USER']);
  const isLoading = useSelector((state) => loadingSelector(state));
  const currentUser = useSelector((state) => state.users.currentUser);
  const surroundingUserList = useSelector((state) => state.users.surroundingUserList);
  const dispatch = useDispatch();

  useEffect(() => {
    if (currentUser) {
      dispatch(
        fetchUsersAround({
          radius,
          state: filter.state,
          status: filter.status === 'all' ? undefined : filter.status
        })
      );
    }
  }, [currentUser, dispatch, filter.state, filter.status, radius]);

  const userProfile = (user) => (
    <div>
      {user.user_type === 'DNT' ? (
        <p
          style={{
            fontWeight: 600,
            cursor: 'pointer',
            color: '#3eb0ca',
          }}
        >
          {user.office_name}
        </p>
      ) : (
        <></>
      )}
      <p style={{ fontWeight: 600 }}>
        {user.first_name} {user.last_name}
      </p>
      <p>{user.address[0].formatted_address}</p>
      <p>{user.contact_number}</p>
      <p>{user.email_id}</p>

      <p
        style={{ cursor: 'pointer', color: '#3eb0ca', textDecorationLine: 'underline' }}
        onClick={() =>
          history.push(`/user/${user.user_type === 'HYG' ? 'hygienist' : 'dentist'}/${user.id}`)
        }
      >
        View Profile
      </p>
    </div>
  );

  const getWrappedMap = () => {
    const googleMap = (props) => {
      if (!currentUser || currentUser.address.length === 0) {
        return <GoogleMap />;
      }

      const { latitude, longitude } = currentUser.address[0];
      if (currentUser.user_type === 'HYG') {
        return (
          <GoogleMap
            defaultZoom={10}
            defaultCenter={{ lat: Number(latitude), lng: Number(longitude) }}
          >
            {currentUser.address.map((territory, index) => {
              const miles = territory.miles || radius;
              return (
                <div key={index}>
                  <Marker
                    defaultIcon={iconRDH}
                    position={{
                      lat: Number(territory.latitude),
                      lng: Number(territory.longitude),
                    }}
                  />
                  <Circle
                    radius={Number(miles) * 1610}
                    center={{
                      lat: Number(territory.latitude),
                      lng: Number(territory.longitude),
                    }}
                    options={{
                      strokeColor: 'rgba(52, 180, 235, 0.7)',
                      strokeOpacity: 0.8,
                      strokeWeight: 2,
                      fillColor: 'rgba(52, 180, 235, 0.7)',
                      fillOpacity: 0.35,
                    }}
                  />
                </div>
              );
            })}

            {surroundingUserList?.map((dnt, index) => {
              const { address } = dnt;
              const isArray = Array.isArray(address);
              return (
                <Marker
                  onClick={() => props.showInfo(dnt.id)}
                  key={index}
                  defaultIcon={iconOffice}
                  position={{
                    lat: isArray
                      ? address[0].location.coordinates[1]
                      : address.location.coordinates[1],
                    lng: isArray
                      ? address[0].location.coordinates[0]
                      : address.location.coordinates[0],
                  }}
                >
                  {' '}
                  {props.showInfoIndex === dnt.id ? (
                    <InfoWindow
                      position={{
                        lat: address[0].location.coordinates[1],
                        lng: address[0].location.coordinates[0],
                      }}
                    >
                      {userProfile(dnt)}
                    </InfoWindow>
                  ) : (
                    <div />
                  )}
                </Marker>
              );
            })}
          </GoogleMap>
        );
      }
      const { address } = currentUser;
      const isArray = Array.isArray(address);
      if (currentUser.user_type === 'DNT') {
        return (
          <GoogleMap
            defaultZoom={10}
            defaultCenter={{ lat: Number(latitude), lng: Number(longitude) }}
          >
            {currentUser && ['all', 'dnt'].includes(filter?.profession) ? (
              <Marker
                defaultIcon={iconOffice}
                position={{
                  lat: isArray
                    ? address[0].location.coordinates[1]
                    : address.location.coordinates[1],
                  lng: isArray
                    ? address[0].location.coordinates[0]
                    : address.location.coordinates[0],
                }}
              />
            ) : (
              ''
            )}
            {Object.keys(surroundingUserList)
              .filter((key) => key.toLowerCase() === filter?.profession || filter?.profession === 'all')
              .map((key) =>
                surroundingUserList[key]?.map((hyg, index) => {
                  const { address } = hyg;
                  const isArray = Array.isArray(address);
                  let defaultIcon = iconRDH;

                  if (key === 'DA') {
                    defaultIcon = iconRDA;
                  } else if (key === 'DN') {
                    defaultIcon = iconDN;
                  } else if (key === 'FD') {
                    defaultIcon = FrontDeskIcon;
                  } else if (key === 'EFDA') {
                    defaultIcon = EfdaIcon;
                  }

                  return (
                    <Marker
                      onClick={() => props.showInfo(hyg.id)}
                      key={index}
                      defaultIcon={defaultIcon}
                      position={{
                        lat: isArray
                          ? address[0]?.location?.coordinates[1]
                          : address?.location?.coordinates[1],
                        lng: isArray
                          ? address[0]?.location?.coordinates[0]
                          : address?.location?.coordinates[0],
                      }}
                    >
                      {props.showInfoIndex === hyg.id ? (
                        <InfoWindow
                          position={{
                            lat: address[0].location.coordinates[1],
                            lng: address[0].location.coordinates[0],
                          }}
                        >
                          {userProfile(hyg)}
                        </InfoWindow>
                      ) : (
                        <div />
                      )}
                    </Marker>
                  );
                }),
              )}
          </GoogleMap>
        );
      }
    };
    return compose(
      withStateHandlers(
        () => ({
          isOpen: false,
          showInfoIndex: '0',
        }),
        {
          onToggleOpen:
            ({ isOpen }) =>
              (a) => ({
                isOpen: !isOpen,
              }),
          showInfo:
            ({ showInfo, isOpen }) =>
              (a) => ({
                isOpen: !isOpen,
                showInfoIndex: a,
              }),
        },
      ),
      withScriptjs,
      withGoogleMap,
    )((props) => googleMap(props));
  };

  const CustomWrappedMap = getWrappedMap();
  const googleMapURL = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&v=3.exp&libraries=geometry,drawing,places`;
  if (isLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
        <Spinner
          animation="border"
          role="status"
          variant="info"
          style={{
            height: 30,
            width: 30,
            marginLeft: 20,
            marginTop: 10,
          }}
        />
      </div>
    );
  }
  return (
    <CustomWrappedMap
      googleMapURL={googleMapURL}
      loadingElement={
        <div
          style={{
            height: '100%',
            width: '100%',
            minWidth: '160px',
          }}
        />
      }
      containerElement={
        <div
          style={{
            height: '100%',
            width: '100%',
            minWidth: '160px',
          }}
        />
      }
      mapElement={
        <div
          style={{
            height: '100%',
            width: '100%',
            minWidth: '160px',
          }}
        />
      }
    />
  );
};
