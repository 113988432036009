import React from 'react';
import dateFormat from 'dateformat';
import { useSelector } from 'react-redux';

export default ({ adjustmentList, btnAdjClicked, offerOwnerId }) => {
  const adjustmentReasons = useSelector(
    (state) => state?.users?.allMetadata?.adjustmentReasons?.reduce((acc, curr) => {
      acc[curr.code] = curr.title
      return acc
    }, {}),
  );

  if (adjustmentList?.length === 0) {
    return (
      <div
        style={{
          minHeight: 200,
          maxHeight: 500,
          overflowY: 'auto',
          borderBottom: '1px solid #efefef',
        }}
      >
        <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
          <span
            style={{
              fontFamily: 'Nunito',
              fontSize: 18,
              alignText: 'center',
            }}
          >
            No Adjustments
          </span>
        </div>
      </div>
    );
  }

  const getColor = (status) => {
    if (status === 'declined') {
      return 'red';
    }
    if (status === 'applied') {
      return 'green';
    }
    return 'black';
  };

  return (
    <div
      style={{
        maxHeight: 500,
        overflowY: 'auto',
        borderBottom: '1px solid #efefef',
      }}
    >
      {adjustmentList?.map((adjustment) => {
        const isOfferOwner = offerOwnerId === adjustment?.author?.id;

        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
            key={adjustment.id}
          >
            <div
              className='w-100'
              style={{
                padding: "5px 10px",
              }}
            >
              <div
                className='border'
                style={{
                  borderRadius: 25,
                  padding: "12px 0px",
                }}
              >
                <div className='d-flex'>
                  <div className='col-1 text-center d-flex justify-content-center align-items-center'>
                    {`${adjustment.author.first_name} ${adjustment.author.last_name}`}
                  </div>
                  <div className='col-2 text-center d-flex justify-content-center align-items-center'>
                    {dateFormat(adjustment.createdAt, 'ddd, mmmm dS, yyyy h:MM:ss TT') || ''} 
                  </div>
                  <div className='col-1 text-center d-flex justify-content-center align-items-center' style={{ textDecoration: 'line-through' }}>
                    {adjustment.original_start}
                  </div>
                  <div className='col-1 text-center d-flex justify-content-center align-items-center'>
                    {adjustment.start}
                  </div>
                  <div className='col-1 text-center d-flex justify-content-center align-items-center' style={{ textDecoration: 'line-through' }}>
                    {adjustment.original_finish}
                  </div>
                  <div className='col-1 text-center d-flex justify-content-center align-items-center'>
                    {adjustment.finish}
                  </div>
                  <div className='col-1 text-center d-flex justify-content-center align-items-center' style={{ textDecoration: 'line-through' }}>
                    {adjustment.original_break}
                  </div>
                  <div className='col-1 text-center d-flex justify-content-center align-items-center'>
                    {adjustment.break}
                  </div>
                  <div
                    className='col-1 text-center d-flex justify-content-center align-items-center'
                    style={{
                      fontWeight: 'bold',
                      color: getColor(adjustment.status),
                    }}
                  >
                    {adjustment.status}
                  </div>
                  {adjustment.status === 'none' && (
                    <div
                      className='col-2 text-center d-flex justify-content-center align-items-center'
                    >
                      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                      <div
                        className="btn btn-success"
                        style={{
                          fontFamily: 'Nunito',
                        }}
                        onClick={() => btnAdjClicked(adjustment.id, 'applied')}
                      >
                        Apply
                      </div>
                      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                      <div
                        className="btn btn-danger ml-1"
                        style={{
                          fontFamily: 'Nunito',
                        }}
                        onClick={() => btnAdjClicked(adjustment.id, 'declined')}
                      >
                        Reject
                      </div>
                    </div>
                  )}
                </div>
  
                <div className='d-flex mt-5'>
                  <div className='col-md-3 col-sm-12 pl-5'>
                    {isOfferOwner && (
                      <>
                        <strong>Reason for Adjustment</strong>
                        <div>
                          {adjustmentReasons?.[adjustment.adjustmentReason]}
                        </div> 
                      </>
                    )}
                  </div>
                  <div className='col-md-5 col-sm-12 pl-5'>
                    <strong>
                      {isOfferOwner ? 'Provided Details' : 'Message'}
                    </strong>
                    <div>
                      {adjustment.message}
                    </div> 
                  </div>
                  <div className='col-md-4 col-sm-12 pl-5'>
                    <strong>Dispute Reason</strong>
                    <div>
                      {adjustment.disputeReason}
                    </div> 
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      })}
    </div>
  );
};
