import { useParams } from 'react-router-dom';
import { useOfficeList } from './hooks/useOfficeList';
import { Spinner } from '../../../../../commonComponents/Spinner';

export const OfficesTab = () => {
  const params = useParams();

  const { data, isInitialLoading, isLoading, handleScrollList, handleViewDetailClick } =
    useOfficeList();

  if (!params.id && isLoading) {
    return <Spinner />;
  }

  return (
    <div
      className="card mt-3 ml-4 mr-4 mb-3"
      style={{
        height: '100%',
        display: 'flex',
        width: '80%',
        paddingBottom: 40,
      }}
    >
      <span
        style={{
          fontSize: 24,
          marginTop: 20,
          textAlign: 'center',
        }}
      >
        DSO Offices
      </span>
      <div
        style={{
          margin: '0 80px',
        }}
      >
        {isInitialLoading ? (
          <Spinner />
        ) : (
          <div
            className="card"
            style={{
              width: '100%',
              height: 540,
              overflowY: 'scroll',
              overflowX: 'scroll',
              marginTop: 40,
            }}
            onScroll={handleScrollList}
          >
            <table>
              <thead
                style={{
                  backgroundColor: 'lightblue',
                  borderBottom: '1px solid #efefef',
                  position: 'sticky',
                  top: 0,
                  width: '100%',
                }}
              >
                <tr>
                  <th className="table-col-2 card-text table-title-text">#</th>
                  <th className="table-col-10 card-text table-title-text">Name</th>
                </tr>
              </thead>
              <tbody>
                {data.map((office) => (
                  <tr
                    key={office.id}
                    style={{
                      width: '100%',
                      borderBottom: '1px solid #efefef',
                      padding: '5px 10px',
                      alignItems: 'center',
                    }}
                  >
                    <td
                      className="card-text"
                      style={{
                        cursor: 'pointer',
                        textAlign: 'center',
                        textDecoration: 'underline',
                      }}
                      onClick={() => handleViewDetailClick(office.id)}
                    >
                      View Office
                    </td>
                    <td className="card-text table-value-text">{office.name}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            {isLoading && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: 10,
                  marginBottom: 20,
                }}
              >
                <Spinner
                  animation="border"
                  role="status"
                  variant="info"
                  style={{
                    height: 30,
                    width: 30,
                    marginLeft: 20,
                    marginTop: 10,
                  }}
                />
              </div>
            )}
            {!data.length && (
              <div
                style={{
                  height: 200,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center',
                }}
              >
                There are no Office Admins
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
