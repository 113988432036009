import { useState } from 'react';
import { UserProfession } from '../../../../../../../../../enums/UserProfession';
import { useRateLimit } from './useRateLimit';

export const useForm = (staffId) => {
  const { staffRateLimits, deleteLimit, saveLimits } = useRateLimit(staffId);
  const [isEditMode, setIsEditMode] = useState(false);
  const defaultValues = {
    [UserProfession.RDH]: staffRateLimits.rateLimitRDH,
    [UserProfession.DA]: staffRateLimits.rateLimitDA,
    [UserProfession.DN]: staffRateLimits.rateLimitDN,
    [UserProfession.FD]: staffRateLimits.rateLimitFD,
  };
  const [inputValues, setInputValues] = useState(defaultValues);
  const isDisabled = Object.values(inputValues).every((value) => value === null);

  const handleEditButton = () => {
    setIsEditMode(true);
  };

  const handleCancelButton = () => {
    setIsEditMode(false);
    setInputValues(defaultValues);
  };

  const handleSaveButton = () => {
    saveLimits(
      Object.entries(inputValues).reduce((acc, [key, value]) => {
        acc[`rateLimit${key}`] = value;
        return acc;
      }, {}),
    );
    setIsEditMode(false);
  };

  const handeDeleteButton = (key) => {
    setInputValues({
      ...inputValues,
      [key]: null,
    });
    deleteLimit(key);
  };

  return {
    isEditMode,
    inputValues,
    isDisabled,
    handleEditButton,
    handleCancelButton,
    handleSaveButton,
    handeDeleteButton,
    setInputValues,
  };
};
