// api/selectors.js
import _ from 'lodash';

export const createLoadingSelector = (actions) => (state) =>
  // returns true only when all actions is not loading
  _(actions).some((action) => _.get(state, `loading.${action}`));

export const createErrorMessageSelector = (actions) => (state) =>
  // returns the first error messages for actions
  // * We assume when any request fails on a page that
  //   requires multiple API calls, we shows the first error
  _(actions)
    .map((action) => _.get(state, `error.${action}`))
    .compact()
    .first() || '';

export const createErrorObjectSelector = (actions) => (state) =>
  // returns the first error messages for actions
  // * We assume when any request fails on a page that
  //   requires multiple API calls, we shows the first error
  _(actions)
    .map((action) => {
      const object = _.get(state, `error.${action}`);
      if (object?.message) {
        return {
          error: object,
          action,
        };
      }

      return null;
    })
    .compact()
    .first() || null;
