import { AddNewDso } from './AddNewDso';
import { Filters } from './Filters';

export const Header = ({ filters, handleFilterChange }) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      flex: 1,
      gap: 20,
    }}
  >
    <Filters filters={filters} handleFilterChange={handleFilterChange} />
    <AddNewDso />
  </div>
);
