import BootstrapSpinner from 'react-bootstrap/Spinner';

export const Spinner = () => (
  <div
    style={{
      alignItems: 'center',
      display: 'flex',
      height: 400,
      justifyContent: 'center',
    }}
  >
    <BootstrapSpinner
      animation="border"
      role="status"
      variant="info"
      style={{
        height: 40,
        width: 40,
      }}
    />
  </div>
);
