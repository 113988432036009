import { useState } from 'react';
import { InfiniteStateScrollSelect } from '../../../../../../../commonComponents/InfiniteStateScrollSelect/InfiniteStateScrollSelect';
import { useDsoList } from './hooks/useDsoList';

export const AssignDso = ({value, onSave}) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedOption, setSelectedOption] = useState(value);

  const { getDsoList, isLoading } = useDsoList();

  const handleOnCancel = () => {
    setIsEditMode(false);
    setSelectedOption(null);
  };

  const handleOnEdit = () => {
    setIsEditMode(true);
  };

  const handleOnSave = () => {
    onSave(selectedOption);
    setSelectedOption(null);
    setIsEditMode(false);
  };

  const handleOnSelect = (item) => {
    setSelectedOption(item);
  };

  return (
    <div className="card mb-2">
      <div
        className="card-body"
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignContent: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <h3 className="card-title" style={{ textAlign: 'center' }}>
            DSO Name (new)
          </h3>
          <br />
          {isEditMode ? (
            <InfiniteStateScrollSelect
              fetchOptions={getDsoList}
              stateSelector={(state) => state.dsos.dsosList}
              isLoading={isLoading}
              labelKey="name"
              customStyle={{ height: 100 }}
              onSelect={handleOnSelect}
            />
          ) : (
            <h4 style={{ textAlign: 'center', width: '100%' }}>
              {value?.label || 'Not selected'}
            </h4>
          )}
        </div>
        <div
          style={{
            marginTop: 12,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              gap: 20,
            }}
          >
            {isEditMode ? (
              <>
                <button
                  type="button"
                  className="btn btn-secondary"
                  style={{
                    width: '100px',
                  }}
                  onClick={handleOnCancel}
                >
                  Cancel
                </button>

                <button
                  type="button"
                  className="btn btn-success"
                  style={{
                    width: '100px',
                  }}
                  onClick={handleOnSave}
                >
                  Save
                </button>
              </>
            ) : (
              <>
                <button
                  type="button"
                  className="btn btn-info"
                  style={{ width: '100px' }}
                  onClick={handleOnEdit}
                >
                  Edit
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
