import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import MultiSelect from '../../../commonComponents/MultiSelect';
import { getDsoNames } from '../../../../actions/userActions';

export const DsoNameFilter = ({ filter, handleFilterChange, customStyles = {} }) => {
  const dispatch = useDispatch();
  const dsoNames = useSelector((state) => state.users.dsoNames);

  const dsoNamesOptions = [
    { label: 'Unassigned', value: 'unassigned' },
    ...dsoNames.map((el) => ({
      label: el.name,
      value: el.code,
    })),
  ];

  useEffect(() => {
    if (!dsoNames) {
      dispatch(getDsoNames());
    }
  }, [dispatch, dsoNames]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        minWidth: 200,
        ...customStyles,
      }}
    >
      <p>DSO Name:</p>
      <MultiSelect
        className="custom-select custom-select-lg"
        style={{ position: 'relative' }}
        optionsStyle={{ width: 250 }}
        value={filter?.dsoName ? filter?.dsoName.split(',') : dsoNamesOptions.map((el) => el.value)}
        options={dsoNamesOptions}
        onChange={(values) => {
          // if all options are selected, then we don't need to send the filter to backend
          handleFilterChange(
            'dsoName',
            values.length === dsoNamesOptions.length ? null : values.join(','),
          );
        }}
        placeholder="All"
        showAllButton
        autoApply={false}
      />
    </div>
  );
};
