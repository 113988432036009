import { useEffect } from "react";

export const useComboboxClickAwayListener = (handleOutsideClick) => {
  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);
};
