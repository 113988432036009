import { style } from "../../../commonComponents/user-column";

export const dsosListColumn = () => [
  {
    id: 'name',
    selector: (row) => row.name,
    name: 'Name',
    style: style.tableText,
  },
  {
    id: 'numOfOffices',
    selector: (row) => row.numOfOffices,
    format: (row) => <span style={{ paddingLeft: 20 }}>{row.numOfOffices}</span>,
    name: '# Offices',
    style: style.tableText,
  }
];