export const SelectRow = ({
  title,
  value,
  onChange,
  ResultComponent,
  options = [],
  isEdit = false,
}) => (
  <div
    className="container"
    style={{
      width: '100%',
      borderBottom: '1px solid #efefef',
      padding: 5,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    }}
  >
    <p className="col-6" style={{ fontWeight: '600' }}>
      {title}
    </p>
    {isEdit ? (
      <select
        className="custom-select custom-select-lg mb-3"
        style={{ marginTop: '5px', position: 'relative' }}
        value={value}
        onChange={(event) => onChange(event.target.value)}
      >
        <option className="dropdown-item" value="" disabled={!!value}>
          Select
        </option>
        {options.map((option) => (
          <option key={option.value} className="dropdown-item" value={option.value}>
            {option.title}
          </option>
        ))}
      </select>
    ) : (
      <p className="col-6" style={{ textAlign: 'right' }}>
        {ResultComponent ? <ResultComponent value={value} /> : value || '-'}
      </p>
    )}
  </div>
);
