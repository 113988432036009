import React from 'react';
import CustomPortal from '../../../../../commonComponents/CustomPortal';
import { SearchableDropdown } from '../../../../../commonComponents/SearchableDropdown/SearchableDropdown';
import { useAddOfficeToRegion } from './hooks/useAddOfficeToRegion';

export const AddOfficeToRegionButton = ({ item }) => {
  const {
    selectedOffice,
    data,
    isLoading,
    handleSearch,
    handleSelect,
    handleSubmit,
    showModal,
    setShowModal,
  } = useAddOfficeToRegion(item);

  if (!item) {
    return null;
  }

  const closeModal = () => {
    setShowModal(false);
    handleSearch('');
  };

  return (
    <>
      <button className="btn btn-info" onClick={() => setShowModal(true)} type="button">
        Add Office
      </button>

      {showModal && (
        <CustomPortal
          title="Add Office"
          textStyle={{ textAlign: 'center' }}
          cancelText="Close"
          submitText="Add Office"
          submitPressed={handleSubmit}
          cancelPressed={closeModal}
          rightButtonDisabled={!selectedOffice}
        >
          <div
            style={{
              maxWidth: 400,
              display: 'flex',
              flexDirection: 'column',
              marginTop: 20,
              marginBottom: 40,
              gap: 12,
            }}
          >
            <span style={{ fontSize: 16, textAlign: 'center', marginBottom: 20 }}>
              Select an office to add to <b>{item.name}</b>
            </span>
            <span style={{ fontSize: 16 }}>Region Name: {item.name}</span>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <span style={{ fontSize: 16, width: 110 }}>Select Office:</span>
              <SearchableDropdown
                options={data}
                onSelect={handleSelect}
                onSearch={handleSearch}
                placeholder="Search offices..."
                labelKey="name"
                isLoading={isLoading}
              />
            </div>
          </div>
        </CustomPortal>
      )}
    </>
  );
};
