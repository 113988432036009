import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { fetchRateLimitHistories } from '../../../../../../../actions/staffActions';
import { createLoadingSelector } from '../../../../../../../apis/selectors';

const loading = createLoadingSelector(['FETCH_RATE_LIMIT_HISTORY']);

export const useRateLimitHistory = () => {
  const dispatch = useDispatch();

  const { id: staffId } = useParams();

  const isLoading = useSelector(loading);
  const { data, filters, total } = useSelector((state) => state.staff.rateLimits);

  const handlePageChanged = useCallback(() => {
    dispatch(fetchRateLimitHistories(staffId, { page: filters.page + 1 }));
  }, [dispatch, filters.page, staffId])

  useEffect(() => {
    if (!staffId) return;

    dispatch(fetchRateLimitHistories(staffId, { page: 1 }));
  }, [dispatch, staffId]);

  return {
    data,
    filters,
    isInitialLoading: !data.length && isLoading,
    isLoading,
    total,
    totalPages: Math.ceil(total / filters.limit),
    handlePageChanged,
  }
};
