import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addAccessToStaffEntity,
  deleteAccessOfStaffEntity,
} from '../../../../../../../actions/staffActions';

export const useDsoExecutive = () => {
  const dispatch = useDispatch();

  const dsoAccessId = useSelector(
    (state) =>
      (state.staff.officeAccesses.data || []).find(({ entityType }) => entityType === 'dso')?.id,
  );
  const staffDetails = useSelector((state) => state.staff.staffDetails);

  const isDsoExecutive = dsoAccessId !== undefined;

  const toggleDsoExecutive = useCallback(() => {
    if (isDsoExecutive) {
      dispatch(deleteAccessOfStaffEntity(staffDetails.id, dsoAccessId));
      return;
    }

    dispatch(
      addAccessToStaffEntity(staffDetails.id, {
        id: staffDetails.dsoID,
        entityType: 'dso',
      }),
    );

  }, [dispatch, dsoAccessId, isDsoExecutive, staffDetails.dsoID, staffDetails.id]);

  return {
    isDsoExecutive,
    toggleDsoExecutive,
  };
};
