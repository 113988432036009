import { useEffect, useState } from 'react';
import debounceHook from '../../../../commonComponents/debounceHook';

export const Filters = ({ filters = {}, handleFilterChange = () => {} }) => {
  const [searchText, setSearchText] = useState(filters?.dsoName || '');
  const debouncedSearchText = debounceHook(searchText, 1000);

  useEffect(() => {
    if (filters?.dsoName === debouncedSearchText) return
    handleFilterChange('dsoName', debouncedSearchText);
  }, [debouncedSearchText, handleFilterChange, filters.dsoName]);

  return (
    <div>
      <input
        style={{
          paddingLeft: '8px',
          fontFamily: 'Nunito-Regular',
          width: 200,
          height: 32,
        }}
        placeholder="Search"
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
      />
    </div>
  );
};