import { useCallback, useEffect, useRef, useState } from 'react';
import '../../../themes/MultiSelect.css';
import { OptionItem, Value } from './components';
import { useComboboxValues } from './hooks/useValues';
import { ComboboxContext, useComboboxContext } from './context';
import { useComboboxClickAwayListener } from './hooks/useClickAwayListener';

export const Combobox = ({
  hasReachMax = false,
  isAutoApply = false,
  isMultiple = false,
  isPaged = false,
  label,
  options = [],
  placeholder = 'Select',
  values = [],
  onChange,
  onNextPage = () => {},
}) => (
  <ComboboxContext.Provider
    value={{
      isAutoApply,
      isMultiple,
      options,
    }}
  >
    <Component
      hasReachMax={hasReachMax}
      isPaged={isPaged}
      label={label}
      placeholder={placeholder}
      values={values}
      onChange={onChange}
      onNextPage={onNextPage}
    />
  </ComboboxContext.Provider>
);

const Component = ({
  hasReachMax,
  isPaged,
  label,
  placeholder,
  values: defaultValues,
  onChange,
  onNextPage,
}) => {
  const [isOptionsVisible, setIsOptionsVisible] = useState(false);
  const optionsRef = useRef(null);
  const ulRef = useRef(null);

  useComboboxClickAwayListener((event) => {
    if (optionsRef.current && !optionsRef.current.contains(event.target)) {
      setIsOptionsVisible(false);
    }
  });

  const { isAutoApply, isMultiple, options } = useComboboxContext();
  const { isEmpty, handleValueChange, hasValue, values } = useComboboxValues(defaultValues);

  const handleSubmit = useCallback(
    (values) => {
      if (isMultiple) {
        onChange(values);
      } else {
        onChange(values[0]);
      }
    },
    [isMultiple, onChange],
  );

  const handleToggleOptions = useCallback((event) => {
    if (Array.from(event.target.classList).includes('multiselect__apply')) {
      setIsOptionsVisible(false);
    } else {
      setIsOptionsVisible(true);
    }
  }, []);

  const handleScroll = useCallback(
    (e) => {
      const { scrollTop, scrollHeight, clientHeight } = e.target;

      const isAtBottom = scrollTop + clientHeight >= scrollHeight;

      if (hasReachMax || !isPaged || !isAtBottom) {
        return;
      }

      onNextPage();
    },
    [hasReachMax, isPaged, onNextPage],
  );

  useEffect(() => {
    if (!isOptionsVisible || !isPaged || hasReachMax) return;

    const { clientHeight, scrollHeight, scrollTop } = ulRef.current;

    const isAtBottom = scrollTop + clientHeight >= scrollHeight;

    if (!isAtBottom) {
      return;
    }

    onNextPage();
  }, [hasReachMax, isOptionsVisible, isPaged, onNextPage]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        minWidth: 200,
      }}
    >
      <p>{label}</p>
      <button
        className="multiselect custom-select custom-select-lg"
        type="button"
        onClick={handleToggleOptions}
      >
        <Value placeholder={placeholder} values={values} />

        {isOptionsVisible && (
          <div ref={optionsRef} className="multiselect__options_wrapper">
            <ul className="multiselect__options" ref={ulRef} onScroll={handleScroll}>
              {options.map((it) => (
                <OptionItem
                  isSelected={hasValue(it.value)}
                  key={it.value}
                  label={it.label}
                  value={it.value}
                  onClick={() => handleValueChange(it, isAutoApply ? handleSubmit : undefined)}
                />
              ))}
            </ul>
            {!isAutoApply && (
              <div className="multiselect__bottom_buttons">
                <button
                  disabled={isEmpty}
                  className="btn btn-info multiselect__apply"
                  type="button"
                  onClick={() => {
                    handleSubmit(values);
                    setIsOptionsVisible(false);
                  }}
                >
                  Apply
                </button>
              </div>
            )}
          </div>
        )}
      </button>
    </div>
  );
};
