export const FormControl = ({ label, value, onChange, style, labelStyle }) => (
  <div
    style={{
      maxWidth: 400,
      display: 'flex',
      alignItems: 'center',
      marginTop: 20,
      marginBottom: 40,
      gap: 12,
      ...style,
    }}
  >
    <span
      style={{
        fontSize: 16,
        textWrap: 'nowrap',
        ...labelStyle,
      }}
    >
      {label}
    </span>
    <input
      type="text"
      className="form-control"
      value={value}
      onChange={onChange}
    />
  </div>
);