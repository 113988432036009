import moment from 'moment';


export function generateHourlyOptions(startAt, endAt) {
  const options = [];
  const start = moment(startAt);

  // Round up to the next hour if minutes, seconds, or milliseconds > 0
  if (start.minutes() > 0 || start.seconds() > 0 || start.milliseconds() > 0) {
    start.add(1, 'hour').startOf('hour');
  } else {
    start.startOf('hour');
  }

  // Set end to be 1 hour before the original endAt
  const end = moment(endAt).startOf('hour').subtract(1, 'hour');

  for (let current = start.clone(); current.isSameOrBefore(end); current.add(1, 'hour')) {
    const formattedTime = current.format('h:mm a'); // Example: '10:00 am'
    options.push({
      title: formattedTime,
      value: current.toISOString(),
    });
  }

  return options;
}
